import { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { history } from '../../helpers/history';
import { useDivDimensions } from '../Grades/Grade';
import useWindowDimensions from '../Charts/Elements/WindowSize';
import { Stars } from '../Charts/Elements/Stars';
import BarChart from './Elements/BarChart';
import './CardBK.scss';
import AlertMessage from '../AlertMessage/AlertMessage'; 

const Card = props => {
  
  const
    { i, data, distribution } = props,
    { name, weight, starRating ,alertMessage , alertType} = data,
    gridTemplateRows = alertMessage ? "25% 25% 25% 25%" : "30% 26% 44%",
    firstRow = alertMessage ? "2 / 3" : "1 / 2",
    secondRow = alertMessage ? "3 / 4" : "2 / 3",
    thirdRow = alertMessage ? "4 / 5" : "3 / 4",
    starRatingRef = useRef(),
    { windowWidth } = useWindowDimensions(),
    screenSmall = windowWidth < 550 ? true : false,
    ratingsDiv = useDivDimensions(starRatingRef),
    gridColumn = i => {
      if (distribution === 'columnar') return `${ i + 1 } / ${ i + 2 }` ;
      if (distribution === 'stacked' ) return '1 / 2';
      else return '1 / 2';
    },
    gridRow = i => {
      if (distribution === 'columnar') return '2 / 3' ;
      if (distribution === 'stacked' ) return `${ i + 1 } / ${ i + 2 }` ;
      else return '1 / 2'
    },
    id = name.toLowerCase()
      .replace(/[^0-9a-z-A-Z ]/g, '')
      .replace(/ +/g, ''),
    handleClick = () => {
      props.dispatch.metrics.onChange(name);
      console.log("NAME:::::::::::::::::::::::: ", name);
      if(name === 'Guest Satisfaction (ACR)') {
        history.push('/drilldown/gs')
      } else if(name === 'Brand Standards') {
        history.push('/drilldown/bs')
      } else if(name === 'Turnover Rate') {
        history.push('/drilldown/tr')
      } else if(name === 'Avg. Training Rate') {
        history.push('/drilldown/at')
      // } else if(name === 'Guest Satisfaction (ACR)') {
      //   history.push('/drilldown/cc')
      // } else if(name === 'Guest Satisfaction (ACR)') {
      //   history.push('/drilldown/smg')
      } else if(name === 'Window Time (SOS)') {
        history.push('/drilldown/wt')
      } else {
        history.push('/drilldown')
      }
    },
    [viewUpdated, setViewUpdated] = useState(false)
    ;
  useEffect(() => {
    setViewUpdated(true);
  },[useWindowDimensions()])
  ;
    
  return (
    <div key={ i }
         id={ id }
         className= "card-container"
         style={ screenSmall ? {
           gridColumn: gridColumn(i),
           gridRow: gridRow(i),
           gridTemplateRows: gridTemplateRows } : { gridTemplateRows: gridTemplateRows }
         }
         onClick={ () => handleClick() }
    >
      {
        alertMessage? 
        <div className = "card-alert-message-invisble-row" style ={{gridRow: "1 / 2" }}>
          <AlertMessage message ={alertMessage} type ={alertType}  />
        </div>: null

      }

      <div className= "card-title-first-row" style = {{gridRow: firstRow }} >
        <p className='text-detail-head'> { name } </p>
        <p className='text-weight-byline'> Weight: { weight * 100 } % </p>
        <div className={ `star-layout-first-row ${ distribution }` }>
          <p className='p-star-rating'>{ starRating }</p>
          <div className='div-star-rating' ref={ starRatingRef }>
            <Stars
                stars={ parseInt(starRating) }
                element={ id }
                width={ ratingsDiv.width }
                height={ ratingsDiv.height }
            />
          </div>
        </div>
      </div>
      <div className="card-second-row" style={{gridRow: secondRow }}>
        <BarChart
          id={ id }
          row='first'
          className='inner-bar-chart-line'
          xDomain={ data.xDomain }
          dataPoint={ data.value }
          tickValues={ data.tickValues }
          displayStars={ true }
          displayTicks={ true }
        />
      </div>
      <div className= "card-third-row" style={{ gridRow: thirdRow}}>
        <BarChart
          id={ id }
          row='second'
          title='National Average'
          className='second-bar-chart-line'
          xDomain={ data.xDomain }
          dataPoint={ data.nationalAverage }
          tickValues={ data.tickValues }
          displayStars={ false }
          displayTicks={ false }
        />
        <BarChart
          id={ id }
          row='third'
          title='Top Performer'
          className='second-bar-chart-line'
          xDomain={ data.xDomain }
          dataPoint={ data.topPerformer }
          tickValues={ data.tickValues }
          displayStars={ false }
          displayTicks={ false }
        />
      </div>
    </div>
  )
};

export default connect()(Card);

import './index.css'
// import StackBarChart from '../../components/stacked-bar-chart'
// import data from '../../data.json'
import BarChart from '../../Charts/D3/barChartNew'
import BarChartData from '../../../assets/json/barChartData.json'
import BarLineData from '../../../assets/json/barLineData.json'
import BarChartData1 from '../../../assets/json/barChartData-TC.json'
import RowChart from '../../Charts/D3/rowChart'
import Legends from '../../Legends/index'
import BreadCrumb from '../../BreadCrumb/BreadCrumb';

const WindowTimeDrillDowns = (props) => {
    const period = "" //props?.periodDispatch
    return (
        <div className="page-container">
            <div className='breadcrumb-trail-head'>
                <BreadCrumb
                className='two-cols-layout'
                home='/grading'
                path='/grading'
                trail={ [{ name: period, url: null }] }
                />
            </div>
            <div><h1>Window Time (SOS)</h1></div>
            <div className="chart-18  flex-row">
                <div className="bg-white flex-col padding-10 margin-20">
                    <Legends header={"Window Time & Car Count"} />
                    
                    <BarChart  w= { 1457} linedata= {BarLineData} bardata= {BarChartData} w1 ={1300} keyT={"Attainment"} BSB = {"GB"} id = {"GB"}/>
                    {/* <BarChart  w= { 1457} linedata= {BarLineData} bardata= {BarChartData} w1 ={1300} keyT={"Attainment"} BSB = {"GB"}/> */}
                </div>
            </div>
            <div className="chart-19 flex-row">
                <div className="bg-white flex-col padding-10 margin-30">
                    {/* <h1>Total Time & Car Count</h1> */}
                    <Legends header={"Total Time & Car Count"} />
                    <BarChart  w= { 1457} linedata= {BarLineData} bardata= {BarChartData1} w1 ={1300} keyT={"Total Time"} BSB = {"B"} id = {"B"}/>
                </div>
            </div>
            <div className="chart-20 flex-row">
                <div className="bg-white flex-col padding-10 margin-20"><h1>Day of the Week</h1>
                <RowChart id={"one"}  />
                </div>
                <div className="bg-white flex-col padding-10 margin-20">
                    <h1>Time of the Day</h1>
                    <RowChart id={"two"}  />
                </div>
            </div>
        </div>
    )
}

export default WindowTimeDrillDowns;
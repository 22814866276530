import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames"
import { List, ListItem, Theme, Box} from "@material-ui/core";
import RightLinks from "../RightLinks/index";
// import "./indexBK.scss"
import { connect } from "react-redux"
//import cssFile from "../../utils/conditionalcss";
//require(`${cssFile('index')}`)



const Header= (props) => {
  require(`${cssFile('index')}`)
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        // color="inherit"
        position="sticky"
       
      >
        <Toolbar className={"toolbar"}>
          <List className="navList">
           <ListItem className={"rightHeader"}>
              <Button className="brandLogo">
                {/* <img
                  alt="logo"
                   src={brandName=="PLK"?"/assets/PLK Logo.svg":"/assets/burger-king-new-2021-seeklogo.com-3.svg"}
               
                  className={"brandLogoOnScroll"}
                /> */}
              </Button>
              <Button className={"scoreCard"} >
                {/* Success Scorecard */}
                <img src="/assets/successIcon.svg"></img>
              </Button>
            </ListItem>
           <Box className="leftheader">
            <RightLinks />
          </Box>
          </List>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
const mapStateToProps = (
  dispatch => ({
    userDispatch: dispatch.user
  })
);

const cssFile=(name)=>{
  const brand = localStorage.getItem('BrandCode')
  console.log(brand)
  return `./${name}BK.scss`
  //return brand==="PLK" ? `./${name}PLK.scss` : `./${name}BK.scss`
}

export default connect(mapStateToProps)(Header);
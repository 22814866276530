const config = {
    oidc: {
      clientId: '0oazsejjvn8IyqtqW0h7',
      issuer: 'https://rbistage.oktapreview.com/oauth2/aussyrombhFtzM2la0h7',
      redirectUri: window.location.origin + '/implicit/callback',
      scopes: ['openid', 'profile', 'email'],
      pkce: true
    }
}


export default config; 
import * as d3 from 'd3'
import React, { useEffect, useRef } from 'react';
import './barChartNew.scss'
const BarChart = ({w,linedata ,bardata,w1,keyT,BSB, id}) => { 
    const d3Container = useRef(null);
    useEffect(() => {
        // var svg = selection,
        var svg = d3.select("svg#" + id),
        margin = { top: 20, right: 20, bottom: 30, left: 40 },
        width = w1,// - margin.left - margin.right,
        height = +svg.attr("height") - margin.top - margin.bottom,
        g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");
            console.log(width)
            var x0 = d3.scaleBand()
                .rangeRound([0, width])
                .paddingInner(0.70);

                var x2 = d3.scalePoint()
                .range([0, width])
                .padding(0.2);    

            var x1 = d3.scaleBand()
            // .rangeRound([10,16,100])
                .padding(1);
                console.log(x1);
            var y = d3.scaleLinear()
                .rangeRound([height, 0]);

            var z = d3.scaleOrdinal()
                .range(["#408441", "#444444", "#E67B66"]);

            // var vals ='[{"State":"Jul","Attainment":13,"National Average":10,"Top 10%":9},{"State":"Aug","Attainment":15,"National Average":10,"Top 10%":7},{"State":"Sep","Attainment":8,"National Average":10,"Top 10%":11},{"State":"Oct","Attainment":10,"National Average":15,"Top 10%":7},{"State":"Nov","Attainment":7,"National Average":8,"Top 10%":15},{"State":"Dec","Attainment":10,"National Average":8,"Top 10%":6}]';

            // const data = JSON.parse(vals);
            const data = bardata;
            var keys = Object.keys(data[0]).slice(1);
            x0.domain(data.map(function (d) { return d.State; }));
            
            x1.domain(keys).rangeRound([0, x0.bandwidth()]);
            y.domain([0, d3.max(data, function (d) { return d3.max(keys, function (key) { return d[key] ; }); })]).nice();

            // var data1 = [{Month:"Jul" , Value : 400 },{Month:"Aug" , Value : 250 },{Month:"Sep" , Value : 200 },
            // {Month:"Oct" , Value : 350 },{Month:"Nov" , Value : 150 },{Month:"Dec" , Value : 60 }]
            var data1 = linedata;
            // var a = alpha(data, v)
            x2.domain(data1.map(function (d) { return d.Month; }));

            
            var yAxis = d3.axisLeft(y).ticks(6).tickSize(-width, 0 ,0);


            g
            .append("g")
            .attr("class", "yaxis")
            .call(yAxis).call(g => g.selectAll(".tick:not(:first-of-type) line")
            .attr("class", "axis_tick")
            .attr("stroke", "rgb(235, 233, 233)"))
            // .select(".domain").remove()
            .append("text")
            .attr("y", 15)
            .attr("transform", "rotate(-90)")
            .attr("fill", "#000")

        


            var YLine = d3.scaleLinear().range([height, 0])
            .domain([0, d3.max(data1, function (d) { 
                return  Math.max(d.Value)
            })]);

        
            if (BSB === "GB"){
            g.append("g")
                .selectAll("g")
                .data(data)
                .enter().append("g")
                .attr("transform", function (d) { return "translate(" + x0(d.State) + ",0)"; })
                .selectAll("rect")
                .data(function (d) { return keys.map(function (key) { return { key: key, value: d[key] }; }); })
                .enter().append("rect")
                .attr("x", function (d,i) { 
                const K = [12,48,58]
                // return x1(d.key);
                return (K[i]); 
                })
                .attr("y", function (d) { return y(d.value); })
                .attr("class" , function (d) { return (d.key.replace(/ /g, "")); })
                .attr("width", function(d, i) {
                var K = [32,4,4]
                    return (K[i]);
                })
                .attr("height", function (d) { return height - y(d.value); })
                .attr("fill", function (d) { return z(d.key); });
            } else if (BSB === "B"){
                console.log("Hey")
                g.append("g")
                .selectAll("g")
                .data(data)
                .enter().append("g")
                .attr("transform", function (d) { return "translate(" + x0(d.State) + ",0)"; })
                .selectAll("rect")
                .data(function (d) { return keys.map(function (key) { return { key: key, value: d[key] }; }); })
                .enter().append("rect")
                .attr("x",x0.bandwidth()-50)
                .attr("y", function (d) { return y(d.value); })
                .attr("class" , function (d) { return (d.key.replace(/ /g, "")); })
                .attr("width", function(d, i) {
                var K = [32,4,4]
                    return (K[i]);
                })
                .attr("height", function (d) { return height - y(d.value); })
                .attr("fill", function (d) { return z(d.key); });
            }



            g.append("g")
                .attr("class", "axis")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x0).tickSize(0,0,0));

                var YLine = d3.scaleLinear().range([height, 0])
                .domain([0, d3.max(data1, function (d) { 
                    return  Math.max(d.Value)
                })]);
    
                var width1 = width + margin.right + 20
                svg.append("g")
                .attr("class", "axisRed")
                .attr("transform", "translate( " + width1  + ", 20 )")
                .call(d3.axisRight(YLine).ticks(8).tickSize(0,0,0));   

                var line = d3
                .line()
                .x(function (data1) {
                    return x2(data1.Month) ;
                })
                .y(function (data1) { return YLine(data1.Value) });

                
                var area = d3
                .area()
                .x(function (data1) {
                    return x2(data1.Month) ;
                })
                .y0(y(0))
                .y1(function (data1) { return YLine(data1.Value) });

                g.append("path")
                .datum(data1)
                .attr("fill", "#E68A4A1A")
                .attr("fill-opacity", .3)
                .attr("stroke", "none")
                .attr("d", area)

                g.append("path")
                .datum(data1)
                .attr("fill", "none")
                .attr("stroke", "#E68A4A")
                .attr("stroke-width", 4)
                .attr("d", line);

                g.selectAll("myCircles")
                .data(data1)
                .enter()
                .append("circle")
                  .attr("fill", "#E68A4A")
                  .attr("stroke", "#FFFFFF")
                  .attr("cx", function(data1) { return x2(data1.Month) })
                  .attr("cy",function(data1) { return YLine(data1.Value) })
                  .attr("r", 5)

                data.forEach(function(d) {
                    var y0 = 0;
                    d.values = keys.map(function(name) { return {name: name, y0: y0, y1: y0 += +d[name]}; });
                    d.total = d.values[d.values.length - 1].y1;
                });
                // function (d,i) { 
                //     const K = [20,140,265,390,515,640]
                //    return K[i]; }
                if (BSB === "GB"){
                g.append("g").selectAll("text")
                .data(data,d=> d)
                 .enter().append("text")
                 .attr("x", function(d) { 
                    return x0(d.State)+20; })
                 .attr("y", function (d,i) { 
                    return y(d[keyT]) - 5; })
                 .style('font-size', '1.25em')
             .text(function(d) {
                return d[keyT]; });
            } else if (BSB === "B"){
                g.append("g").selectAll("text")
                .data(data,d=> d)
                 .enter().append("text")
                 .attr("x", function(d) { 
                    return x0(d.State)+35; })
                 .attr("y", function (d,i) { 
                    return y(d[keyT]) - 5; })
                 .style('font-size', '1.25em')
             .text(function(d) {
                return d[keyT]; });
            } 


    }, [])         

    return (
        <svg
            className="d3-component"
            id = {id}
            width={w}
            height = {400}
           
            ref={d3Container}
        />)

};

export default BarChart;

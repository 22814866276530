import React from "react"
// import { data } from "../assets/legend.json"
import {data} from '../../assets/json/legend.json'
// import { data } from "../assets/legend.json"
import "./index.scss"
interface display {
    header: string,
    legends: {

        type: string,
        text: string,
        color: string

    }[]
}
interface legendProps{
    header:string
}

const Legends = (props:legendProps) => {
    //const dataDisplay = data.filter((data) => data.header === "SMG Complaints")
    //props.header 
    return (
        <div className="legends">
            <p className="legend-header">{props.header }</p>
            {
                data.map((data) =>
                    data.header === props.header && data.legends.map(legend => <div className="legend-text"><div className={`${legend.type}`} style={{backgroundColor:legend.color}}></div><p className="legend-value" >{legend.text}</p></div>))
            }
        </div>
    )
}

export default Legends;
import './index.css'
// import StackBarChart from '../../components/stacked-bar-chart'
// import data from '../../data.json'
import BreadCrumb from '../../BreadCrumb/BreadCrumb';

const SMGSurveysDrillDowns = (props) => {
    const period = "" //props?.periodDispatch

    return (
        <div className="page-container">
            <div className='breadcrumb-trail-head'>
                <BreadCrumb
                className='two-cols-layout'
                home='/grading'
                path='/grading'
                trail={ [{ name: period, url: null }] }
                />
            </div>
            <h1>SMG Survey Deep Dive</h1>
            <div className="chart-11  flex-row">
                <div className="bg-white flex-col padding-10 margin-20">
                    <h2>Primary Problems</h2>
                </div>
            </div>
            <div className="chart-12 flex-row">
                <div className="bg-white flex-col padding-10 margin-20"><h2>SOS Focus Area</h2></div>
                <div className="bg-white flex-col padding-10 margin-20"><h2>Cleanliness Focus Area</h2></div>
            </div>
            <div className="chart-13 flex-row">
                <div className="bg-white flex-col padding-10 margin-20"><h2>Food Quality Focus Area</h2></div>
                <div className="bg-white flex-col padding-10 margin-20"><h2>Friendliness Focus Area</h2></div>
            </div>
            <div className="chart-14 flex-row">
                <div className="bg-white flex-col padding-10 margin-20"><h2>Accuracy Focus Area</h2></div>
                <div className="flex-col padding-10 margin-20"></div>
            </div>
        </div>
    )
}

export default SMGSurveysDrillDowns;
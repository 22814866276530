import Header from '../Components/Header';
import Footer from '../Components/Footer';
import  ScoreCardComponent from '../Components/ScoreCard/ScoreCard'
import UserProvider from '../providers/user';
import GradesProvider from "../providers/grades";

const ScoreCard = () => {
  return (
      <UserProvider>
          <GradesProvider>
            <div>
              <Header />
                <ScoreCardComponent/>
              <Footer />
            </div>
          </GradesProvider>
      </UserProvider>
  );
};
export default ScoreCard;
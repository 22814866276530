import { getTableRows, getArlTableRows } from '../api';

const initialState = { rows: []
};

export const table = ({
  state: initialState,
  reducers: {
    setTableRows(state, table) {
      return {
        ...state,
        loading: false,
        rows: table
      }
    }
  },
  effects: {
    async loadTableRows(params, state) {
      if (!params) return;
      const
        { fzCode } = state.user,
        { period, brandCode } = params,
        table = await getTableRows(period, fzCode, brandCode);
      this.setTableRows(table);
    },
    async loadArlTableRows(period, state) {
      if (!period) return;
      const
        { fzCode } = state.user,
        table = await getArlTableRows(period, fzCode);
      this.setTableRows(table);
    }
  }
});

export default table;

import React, { useState } from 'react';
import { Button, Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import './Settings.scss';
import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

const Settings = (props) => {
  const
    { authService } = useOktaAuth(),
    [settingsClicked, setSettingClicked] = useState(false),
    [settingsApplied, setSettingsApply] = useState(false),
    [defaultSetting, setDefaultSetting] = useState(JSON.parse(localStorage.getItem('user')).preference || 'restaurants'),
    { dispatch } = props,
    handleSettingsChange = () => {
      setSettingsApply(true);
      setSettingClicked(false)
      authService.getUser().then(data => {
        console.log(data, "checkUser")
        const userWithPref = { ...data, defaultView: defaultSetting }
        console.log(userWithPref, "checkUserPref")
        dispatch.user.setUserState(userWithPref);
      })
    },
    handleRadio = (event) => {
      setDefaultSetting(event.target.value)

      //change user preference in localstorage
      let user = JSON.parse(localStorage.getItem('user'))
      user['preference'] = event.target.value
      localStorage.setItem('user', JSON.stringify(user))
    }
    ;
    console.log('default', defaultSetting)

  return (
    <div className='settings' >
      <img
        src="/assets/settings.svg" alt=""
        onClick={() => setSettingClicked(!settingsClicked)}
      ></img>
      <div id='sort-div' className={settingsClicked ? 'settingsHovered' : 'filteredHoveredCancel'}>
        <Typography className='defaultView"'>Set Default View</Typography>
        <RadioGroup name='defaultView' onMouseLeave={() => setSettingClicked(false)} value={defaultSetting} onChange={handleRadio}>
          <FormControlLabel
            className='defaultView'
            value='restaurants'
            control={<Radio className='radio' />}
            label='Restaurants'
          />
          <FormControlLabel
            className='defaultView'
            value='arl'
            control={<Radio />}
            label='ARL (Above Restaurant Leader)'
          />
          <FormControlLabel
            className='defaultView'
            value='do'
            control={<Radio />}
            label='DO (District Operator)'
          />
          <div className='filterButtons'>
            <Button
              variant='text'
              className={settingsApplied ? `applyButton appliedClick` : 'applyButton'}
              onClick={handleSettingsChange}
            >Apply
            </Button>
            <Button
              variant='text'
              className='cancelButton'
              onClick={() => setSettingClicked(false)}
            >Cancel
            </Button>
          </div>
        </RadioGroup>
      </div>
    </div>
  )
};
const mapStateToProps = () => (
  state => ({
    userState: state.user
  }),
  dispatch => ({
    userDispatch: dispatch.user
  })
);

export default connect(mapStateToProps)(Settings);

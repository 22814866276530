import React from 'react';

import {connect} from "react-redux"
//import cssFile from '../../utils/conditionalcss';
//require(`${cssFile('index')}`)

const Footer = (props) => {
  require(`${cssFile('index')}`)
  return (
    <div className="footerBottomSplit">
      <div className='leftFooter'>
        <div className='trademark'>
          <p className='pTrademark'>© 2021 Restaurant Brands International Inc. All Rights Reserved.</p>
        </div>
      </div>
      <div className='rightFooter'>
        {
          textBottom.map((o, i) => (
            <div className='textBottom'>
              <p className='textLi' key={`text-bottom-${i}`}>{o}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
};

const textBottom = ["rInsights", "•", "RBI Intranet", "•", "Terms and Conditions"];


const mapStateToProps = (
  dispatch => ({
    userDispatch: dispatch.user
  })
);

const cssFile=(name)=>{
  const brand = localStorage.getItem('BrandCode')
  console.log(brand)
  return `./${name}BK.scss`
  //return brand==="PLK" ? `./${name}PLK.scss` : `./${name}BK.scss`
}

export default connect(mapStateToProps)(Footer);

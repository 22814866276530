import { ResponsiveBar } from '@nivo/bar'

const VerticalStackedBarChart = ({ data, keys, colors, yAxisLegend, height }) => (
    <ResponsiveBar
        data={data}
        height={height}
        keys={keys}
        indexBy="month"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.6}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        valueFormat={{ format: '', enabled: false }}
        colors={colors}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32
        }}
        axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: yAxisLegend,
            legendPosition: 'middle',
            legendOffset: -40
        }}
        markers={[
            {
                axis: 'x',
                value: 0,
                lineStyle: {
                    stroke: 'black'
                }
            },
            {
                axis: 'y',
                value: 0,
                lineStyle: {
                    stroke: 'black'
                }
            }
        ]}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        labelFormat={2}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'top-right',
                direction: 'row',
                justify: false,
                translateX: 120,
                translateY: -40,
                itemsSpacing: 8,
                itemWidth: 100,
                itemHeight: 0,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        //tooltip={function(){ return 'Tooltip here!!' }}
    />
)

export default VerticalStackedBarChart
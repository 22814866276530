import { ResponsiveBar } from '@nivo/bar'

const HorizontalBarChart = ({ data, height }) => {
    return (

                <ResponsiveBar
                    data={data}
                    height={height}
                    keys={['P1']}
                    indexBy="problems"
                    colors={"#408441"}
                    margin={{ top: 50, right: 50, bottom: 50, left: 200 }}
                    padding={0.6}
                    layout="horizontal"
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    enableGridX={false}
                    enableGridY={true}
                    markers={[
                        {
                            axis: 'x',
                            value: 0,
                            lineStyle: {
                                stroke: 'black'
                            }
                        }
                    ]}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    legends={[]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                />

    )
}

export default HorizontalBarChart
import * as d3 from 'd3';
import drawStarPath from "./drawStarPath";

const gaugeValueUpdate = ({ className, stars, grading }) => {
    let boo = stars;
    let ratingsArray = Array.from({length: 5}, (_, i) => {
        if(boo >= 1) {
            boo -= Math.floor(boo) / Math.floor(boo);
            return 1;
        }
        else if (boo < 1) {
            try {
                return boo ;
            }
            finally {
                boo -= boo;
            }
        }
    });
  const chart = selection => {
    selection.each( () => {
      const
        scoreFull = 5,
        radius = Math.min(400, 210) - 10,
        pie = d3.pie().sort((a, b) => a[0] - b[0]).value(d => d[1] ),
        paths = d3.selectAll(`.${ className } svg g path.outerSlices.segment`),
        letterGrade = d3.selectAll(`.${ className } svg g text.big-gauge-grade`),
        starRatings = d3.selectAll(`.${ className } svg g.the-new`),
        score_ready = pie([["1", 3.5],["2", stars],["3", scoreFull - stars]]),
        arcOuter = d3.arc()
          .innerRadius(radius * 0.8)
          .outerRadius(radius * 0.70)
          .startAngle(d => d.startAngle + Math.PI / 1.7 )
          .endAngle(d => d.endAngle + Math.PI/ 1.7 )
        ;
      paths
        .data(score_ready)
        .transition().duration(2000)
        .attrTween('d', d => {
          var i = d3.interpolate(d.startAngle+0.1, d.endAngle);
          return function(t) {
            d.endAngle = i(t);
            return arcOuter(d);
          }
        });
      letterGrade
        .data([grading])
        .text(d => d)
        ;
      starRatings.selectAll('path')
        .data(ratingsArray)
        .each((d,i) => {
            const
                o = +d,
                starSize = 400,
                index = i,
                id = `stars-${index}`,
                element = drawStarPath({ o, i, id, starSize })
            ;
            d3.select(`#stars-${ index }.b`)
                .call(element)
        })

    });
  };
  return chart;
};

export default gaugeValueUpdate;

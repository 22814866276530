import { Button, createStyles, FormControl, IconButton, InputAdornment, makeStyles, OutlinedInput, Theme, Tooltip, Typography } from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Pagination from '@material-ui/lab/Pagination';
import React, { useState } from "react";
import { data } from "./reviews.json"
import { dataAll } from "./reviewsAll.json"
import { connect } from "react-redux"
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
//import cssFile from "../../utils/conditionalcss"
import { useParams } from "react-router-dom";
import { useEffect } from "react";
//require(`${cssFile('index')}`)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customWidth: {

      backgroundColor: theme.palette.common.white,
      color: 'black',
    }
  }),
);
interface ReviewTypes {
  date: string,
  restaurantName?: string,
  stars: number,
  review: string
}
interface Params {
  name: string
}
const GoogleReviews = (props) => {
  require(`${cssFile('index')}`)
  console.log(props.userDispatch, 'datareview')

  const params: Params = useParams();
  const [cancelClicked, setCancelClicked] = useState(true)
  const [utilClicked, setUtilClicked] = useState("")
  const [dataVariant, setDataVariant] = useState<ReviewTypes[]>(data)
  const classes = useStyles()
  const [page, setPage] = React.useState(1);


  const handleSort = (sort: string) => {
    var sortArray = []
    if (sort === "low") sortArray = dataVariant.sort((a, b) => a.stars - b.stars)
    else if (sort === "high") sortArray = dataVariant.sort((a, b) => b.stars - a.stars)
    setPageItems(sortArray.slice(0, 8))
    setCancelClicked(true)
  }

  const [pageItems, setPageItems] = useState<ReviewTypes[]>(dataVariant.slice(0, 8))
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setPageItems(dataVariant.slice((value - 1) * 8, value * 8))
  };
  const handleSearch = (event: any) => {
    const searchTerm = event.target.value
    const searchArray = dataVariant.filter(rev => rev.review.indexOf(searchTerm) > -1)
    setPageItems(searchArray.slice(0, 8))
  }



  useEffect(() => {
    console.log(params, "checkParamsrev")
    if (params.name === "all") setDataVariant(dataAll)
    else setDataVariant(data)

  }, [params])

  useEffect(() => {
    setPageItems(dataVariant.slice(0, 8))
  }, [dataVariant])
  console.log(pageItems, "checkParamsrevdata")
  const toolTip = () => {
    return (
      <div className="tooltip-content" >
        <p className="tooltip-content-head">The average star rating is a sum of each metric based on its weight</p>
        <p className="tooltip-content-body">5 star:Exceeding target</p>
        <p className="tooltip-content-body">4 star:Meeting target</p>
        <p className="tooltip-content-body">3 star:Slightly below target</p>
        <p className="tooltip-content-body">2 star:Significantly below target</p>
        <p className="tooltip-content-body">1 star:Requires immediate action</p>
      </div>
    )
  }
  return (

    <div className="rootReview">
      <BreadCrumb
        className='two-cols-layout'
        home='/'
        trail={[{ name: "Jan 2021- Jun 2021", url: null }, { name: "Resturant #123", url: null }, { name: "Guest Satisfaction (ACR)", url: null }, { name: "Google Reviews", url: null }]}
      />
      {/* <div className="breadcrumb">Home &gt; May 2021-Aug 2021 &gt; Restaurant 123 &gt; Guest Satisfaction(ACR) &gt; <span className="bread-active"> Google reviews</span></div> */}
      <div className="rest-details">
        <div className="rest-details-head">
          <div className="rest-detail">
            <p className="rest-name">{params?.name === "all" ? "Total 26 Restaurants" : "Restaurant #123"}</p>
            <p className="rest-address">31st & 5th Ave,NY 0000</p>
          </div>
          <div className="rating">
            <p className="rating-title">Average Star Rating:2.1/5 stars</p>
            <div className="rating-stars">
              {Array(Math.floor(3))
                .fill(0)
                .map((_, index) => (
                  <img
                    key={index}
                    src={"/assets/Star - medium.svg"}
                    className="rating-star"
                    alt=""
                  ></img>
                ))}
              {Array(Math.floor(2))
                .fill(0)
                .map((_, index) => (
                  <img
                    key={index}
                    src={"/assets/Polygon 1.svg"}
                    className="rating-star"
                    alt=""
                  ></img>
                ))}
              <Tooltip title={toolTip()} placement="right-start" classes={{ tooltip: classes.customWidth }}><img src="/assets/infoIcon.svg" alt="" className="infoIcon" /></Tooltip>
            </div>
          </div>
        </div>

        <div className="timePeriodDropDown">
          {/* <FormControl className="formControl" variant="outlined">
            <InputLabel shrink id='demo-simple-select-placeholder-label-label'>
              Time Period:
            </InputLabel>
            <Select
              labelId='demo-simple-select-placeholder-label-label'
              id='demo-simple-select-placeholder-label'
              value={timePeriod}
              onChange={(event) => setTimePeriod(String(event.target?.value))}
              displayEmpty
              className="selectEmpty"
            >
              {dropDown.map(({ value, name }, index) => (
                <MenuItem key={index} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </div>
      </div>

      <div className="headers">
        <div className="headers-left">
          <p className="headers-title">Google Reviews Deep Dive</p>
          <div className="headers-stars-container">
            <p className="headers-stars">
              <div className="stars-color one"></div>1-2 Stars
            </p>
            <p className="headers-stars">
              <div className="stars-color three"></div>3 Stars
            </p>
            <p className="headers-stars">
              <div className="stars-color five"></div>4-5 Stars
            </p>
          </div>
        </div>
        <div className="headers-right">

          <FormControl variant="outlined">
            {/* <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel> */}
            <OutlinedInput
              id="outlined-adornment-search"
              placeholder="Search"
              className="search-input"
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"

                    edge="start"
                  >
                    <img src="../../assets/GoogleReviews/icons8-search.svg" alt=""></img>
                  </IconButton>
                </InputAdornment>
              }

            />
          </FormControl>

          <div className="filtered">
            <div className="filterHeadings" onClick={() => { setCancelClicked(!cancelClicked); setUtilClicked("sort") }}><Typography>Sort By</Typography>
              {!cancelClicked && utilClicked === "sort" ? <ExpandLess onClick={() => setCancelClicked(true)} /> : <ExpandMoreIcon id="sort-button" onClick={() => { setCancelClicked(false); setUtilClicked("sort") }} />}

            </div>
            <div id="sort-div" className={!cancelClicked && utilClicked === "sort" ? "filteredHovered" : "filteredHoveredCancel"}>
              <FormControl variant="outlined" className="headers-sort">
                <Button onClick={() => handleSort("low")}>Low to High</Button>
                <Button onClick={() => handleSort("high")}>High to Low</Button>
              </FormControl></div>
          </div>
        </div>
      </div>
      <div className="reviews">
        {pageItems.map((review, index) => (
          <div
            className={
              review.stars <= 2
                ? "angry review-container"
                : review.stars >= 4
                  ? "success review-container"
                  : "neutral review-container"
            }
            key={index}
          >
            <div className="review-head">
              <p className='review-head-name'>{review.date}</p>
              <p className='review-head-name'>{review.restaurantName}</p>
              {Array(Math.floor(review.stars))
                .fill(0)
                .map((_, index) => (
                  <img
                    key={index}
                    src={"/assets/Star - medium.svg"}
                    className="review-star"
                    alt=""
                  ></img>
                ))}
              {Array(Math.floor(5 - review.stars))
                .fill(0)
                .map((_, index) => (
                  <img
                    key={index}
                    src={"/assets/Polygon 1.svg"}
                    className="review-star"
                    alt=""
                  ></img>
                ))}
            </div>
            <div className="review-content">
              <img
                src={
                  review.stars <= 2
                    ? "/assets/GoogleReviews/icons8-angry.svg"
                    : "/assets/GoogleReviews/icons8-neutral.svg"
                }
                alt=""
              ></img>
              <p className="review-desc">{review.review}</p>
            </div>
          </div>
        ))}
      </div>
      <Pagination variant="text" count={Math.ceil(data.length / 6)} page={page} onChange={handlePage} />
    </div>

  );
}
const mapStateToProps = (
  dispatch => ({
    userDispatch: dispatch.user
  })
);
const cssFile=(name)=>{
  const brand = localStorage.getItem('BrandCode')
  console.log(brand)
  return `./${name}BK.scss`
  //return brand==="PLK" ? `./${name}PLK.scss` : `./${name}BK.scss`
}
export default connect(mapStateToProps)(GoogleReviews);

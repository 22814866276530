import './index.css'
import HorizontalBarChart from '../../Charts/horizontal-bar-chart'
import { barChartData } from '../../Charts/json/horizontal-bar-chart.json'
import Comments from '../../Comments'
import BreadCrumb from '../../BreadCrumb/BreadCrumb';

const ContactCenterDrillDowns = (props) => {
    const period = "" //props?.periodDispatch

    return (
        <div className="page-container">
            <div className='breadcrumb-trail-head'>
                <BreadCrumb
                className='two-cols-layout'
                home='/grading'
                path='/grading'
                trail={ [{ name: period, url: null }] }
                />
            </div>
            <h1>Contact Center Deep Dive</h1>
            <div className="flex-row">
                <div className="chart-06 bg-white flex-col padding-10 margin-20">
                    <h2>Primary Problems</h2>
                    <HorizontalBarChart data={barChartData} height={550}/>
                </div>
            </div>
            {/* <h1>Comments</h1>
            <div className="chart-07 flex-row">
                <div className="bg-white flex-col padding-10 margin-20"></div>
                <div className="bg-white flex-col padding-10 margin-20"></div>
            </div> */}

            <Comments/>
        </div>
    )
}

export default ContactCenterDrillDowns;
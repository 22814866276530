import UserProvider from '../providers/user';
import GradesProvider from "../providers/grades";
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import WelcomePage from './Welcome/Welcome';

const Welcome = () => {
        return (
            <UserProvider>
                <GradesProvider>
                    <div>
                        <Header />
                        <WelcomePage/>
                        <Footer />
                    </div>
                </GradesProvider>
            </UserProvider>
        );
    }
    ;
export default Welcome;
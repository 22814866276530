import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import H1 from '../../Pages/Welcome/H1'
import useWindowDimensions from '../Charts/Elements/WindowSize';
import { connect } from "react-redux"
//import cssFile from '../../utils/conditionalcss';
//require(`${cssFile('index')}`)

const Layout = ({
  className,
  content,
  topChild,
  bottomChild,
  marginOffset,
  fiftyScreen,
  layoutClassName,
  userDispatch,
  tableDispatch
  }) => {
  console.log("Landing::::::::::::::::::::: ", cssFile('index'))
  const
    { rows } = tableDispatch,
    { windowHeight, windowWidth } = useWindowDimensions(),
    containerHeight = windowHeight / 2,
    heightOffset = 66, //TODO: make this variable dynamic
    gridTemplateRows = fiftyScreen ? `repeat(2,${ containerHeight - heightOffset }px)` : `737px ${ 128 * rows.length }px`
  ;
  useEffect(() => {
    require(`${cssFile('index')}`)
  },[userDispatch.isLoading])
  ;

  return (
    <div className='container' style={{ gridTemplateRows: gridTemplateRows }} >
      <div className={ className } style={{
        backgroundPositionY: (containerHeight  - heightOffset ) - 439,
        backgroundPositionX: windowWidth * .585
      }}>
        <H1 className='header-greeting'
            containerHeight={ containerHeight }
            offset={ 90 }
            marginOffset={ marginOffset }
            content={ content[0] }
        />
        <H1 className='header-greeting'
            containerHeight={ containerHeight }
            offset={ 200 }
            marginOffset={ marginOffset }
            content={ content[1] }
        />
        { topChild }
      </div>
      <div className={ layoutClassName }></div>
      { bottomChild }
    </div>
  );
};

const mapStateToProps = (
  dispatch => ({
    userDispatch: dispatch.user,
    tableDispatch: dispatch.table
  })
);

const cssFile=(name)=>{
  const brand = localStorage.getItem('BrandCode')
  console.log("Landing Page Brand Value::::::::::::::::::::::::::::::::::", brand)
  return `./${name}BK.scss`
  //return brand==="PLK" ? `./${name}PLK.scss` : `./${name}BK.scss`
}

export default connect(mapStateToProps)(Layout);

import { connect } from 'react-redux';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Landing from '../Components/Landing/Landing';
import Grades from '../Components/Grades/Grades';
import UserProvider from '../providers/user';
import GradesProvider from "../providers/grades";

const Grading = props => {
    const
        { dispatch } = props,
        { givenName, familyName } = props.userDispatch
    ;
    console.log("Grading....")
      return (
          <UserProvider>
              <GradesProvider>
                 <div>
                    <Header />
                    <Landing
                        content= { [`Hi, ${ givenName } ${ familyName }!`,"Welcome to your Scorecard timeline."] }
                        className='splash'
                        marginOffset={ 3 }
                        fiftyScreen={ true }
                        topChild={ null }
                        layoutClassName='white-space'
                        bottomChild={ <Grades/> }
                    />
                    <Footer />
                </div>
          </GradesProvider>
</UserProvider>
      );
};

const mapStateToProps = () => (
    dispatch => ({
        userDispatch: dispatch.user
    })
);

export default connect(mapStateToProps)(Grading);
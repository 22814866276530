import './index.css'
import VerticalGroupedBarChart from '../../Charts/vertical-grouped-bar-chart'
import {stackedBarChartData} from '../../Charts/json/vertical-grouped-bar-chart.json'
import PieChart from '../../Charts/pie-chart'
import { data } from '../../Charts/json/pie-chart.json'
import EmployeeTable from '../../EmployeeTable'
import BreadCrumb from '../../BreadCrumb/BreadCrumb';

const AverageTrainingDrillDowns = (props) => {
    const period = "" //props?.periodDispatch
    console.log('Rending ---------- Average Training')
    return (
        <div className="page-container">
            <div className='breadcrumb-trail-head'>
                <BreadCrumb
                className='two-cols-layout'
                home='/grading'
                path='/grading'
                trail={ [{ name: period, url: null }] }
                />
            </div>
            <div><h1>Avg. Training Certification Rate</h1></div>
            <div className=" flex-row">
                <div className="chart-01 bg-white flex-col padding-10 margin-20">
                    <h2>Overall Training Trend</h2>
                    <VerticalGroupedBarChart data={stackedBarChartData} height={640}/>
                </div>
            </div>
            <div className="flex-row">
                <div className="chart-02 bg-white flex-col padding-10 margin-20">
                    <h2>Certification Completion</h2>
                    <PieChart height={500} data={data}/>
                </div>
                <div className="chart-02 bg-white flex-col padding-10 margin-20">
                    <h2>New Hires Certification Completion</h2>
                    <PieChart height={500} data={data}/>
                </div>
            </div>
            <div className="chart-03 flex-row">
                <div className="bg-white flex-col padding-10 margin-20">
                  
                    <EmployeeTable/>
                </div>
            </div>
        </div>
    )
}

export default AverageTrainingDrillDowns;
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useDispatch } from '../../rematch';
import Card from '../Cards/Card';
import Odometer from '../Odometer/Odometer';
import LineChart from '../Charts/LineChart';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import RestaurantDisplay from './RestaurantDisplay';
import './ScoreCard.scss';
import useWindowDimensions from '../Charts/Elements/WindowSize';
import { useDivDimensions } from '../Grades/Grade';
import { branding } from '../../brandConfig.json';
import MonthDropdown from "../BreadCrumb/Dropdown/MonthDropdown";

const ScoreCard = props => {
  const
    dispatch = useDispatch(),
    { periodDispatch, restaurantDispatch, lineChartDispatch, userDispatch } = props,
    { period } = periodDispatch,
    { statusCode, line1, weekly, monthly, isLoading } = lineChartDispatch,
    { restNo } = restaurantDispatch,
    { brandCode } = userDispatch,
    brand = branding[brandCode || 'GENERIC'].brand,
    {
      gmname,
      restadress,
      restno,
      acr,
      acrtopperformer,
      acrfranchiseaverage,
      acrstarrating,
      sos,
      sostopperformer,
      sosfranchiseaverage,
      sosstarrating,
      trainingtopperformer,
      trainingfranchiseaverage,
      trainingstarrating,
      turnoverrate,
      turnovertopperformer,
      turnoverfranchiseaverage,
      turnoverstarrating,
      brandstandards,
      brandstandardstopperformer,
      brandstandardsfranchiseaverage,
      brandstarrating,
      overallstarrating,
      weights
    } = restaurantDispatch,
    // brandCode = (props.userDispatch?.brandCode==="BKC")?"bk":"plk",
    [dataUpdated, setDataUpdated] = useState(false),
    [viewUpdated, setViewUpdated] = useState(false)
    ;
    useEffect(() => {
      dispatch.restaurant.loadRestaurantView({ period, brandCode: brand, restNo });
      dispatch.linechart.loadLineChart({period, brandCode: brand, restNo});
      setDataUpdated(true);
    },[restNo, period])
  ;
  useEffect(() => {
     setViewUpdated(true);
  },[useWindowDimensions()])
  ;
  useEffect(() => {
    return () => {
      dispatch.restaurant.onInitialState()
    }
  },[])
  const
    toCamelCase = str => str.substr(0,1).toUpperCase() + str.substr(1),
    { windowHeight, windowWidth } = useWindowDimensions(),
    minWidth = windowWidth < 550 ? '8% 18% 74%' : '5% 25% 60%',
    screenHeight = windowWidth < 550 ? '3506' : '2225',
    gridColumnTitle = windowWidth < 550 ? '1 / 5' : '1 / 3',
    desktopScreen = windowWidth > 550 ? true : false,
    componentRef = useRef(),
    lineGraphDiv = useDivDimensions(componentRef),
    onChange = period => {
        dispatch.restaurant.loadRestaurantView({ period, brandCode: brand, restNo });
        dispatch.linechart.loadLineChart({period, brandCode: brand, restNo});
    }
  ;
  return (
    <div>
      <div className='grid-layout' style={{ gridTemplateRows: `${ screenHeight }px`}}>
        <div className='container-layout' style={{gridTemplateRows: minWidth }}>
          <div className='header-breadcrumb'>
            <BreadCrumb
              className='three-cols-layout'
              home='/grading'
              path='/dashboard'
              gridColumnTitle={ gridColumnTitle }
              trail={ [ { name: period, url: '/dashboard' }, { name: `Restaurant # ${ restNo }`, url: null } ] }
              mobileChildren={ <RestaurantDisplay
                gmname={ gmname }
                restno={ restno }
                restadress={ restadress }
              /> }
              dropDownChildren={
                 <MonthDropdown
                   className='three-cols-layout'
                   onChange={ pd => onChange(pd) }
                 />
              }
            />
          </div>
          <div className='d3-charts-container'>
            <div className='gauge-chart-container'>
              <div className='gauge-chart-header'>
                <div className='header-right'>
                  { desktopScreen ?
                    <RestaurantDisplay
                      gmname={ gmname }
                      restno={ restno }
                      restadress={ restadress }
                    /> : null
                  }
                </div>
              </div>
              <div className='chart-two-thirds'>
                <Odometer
                  data={{
                    starRating: overallstarrating,
                    grading: overallstarrating,
                    weights: weights,
                    odometerTitle: 'My Rating'
                  }}/>
              </div>
            </div>
            <div className='star-trend-container' ref={ componentRef }>
                { dataUpdated ?
                    <LineChart
                      className='d3-graph-line-chart-container'
                      id='star-trend-chart'
                      data={{ monthly, weekly, line1, statusCode }}
                      w={ lineGraphDiv.width }
                      h={ lineGraphDiv.height }
                      windowWidth={ windowWidth }
                    /> : null
                }
            </div>
          </div>
          <div className='restaurant-cards-container'>
            { desktopScreen ?
              <div className='restaurant-cards-header'>{
                ['Guest', 'Team', 'Standards'].map((o, i) => (
                  <div
                    className={ `cards-layout header-${ o }` }
                    style={{gridColumn: `${ i + 1 } / ${ i + 2 }`}}
                  >{ o }</div>
                ))
              }
              </div> : null
            }
            <div className='card-stacked-layout'>
            { dataUpdated ?
              [{
                name: 'Guest Satisfaction (ACR)',
                nationalAverage: acrfranchiseaverage,
                alertMessage: 'ACR decreased by 12pts this round, driven by the poor food quality and staff friendliness.',
                alertType : 'danger',
                starRating: acrstarrating,
                tickValues: [65, 75, 85, 95],
                topPerformer: acrtopperformer,
                value: acr,
                weight: null,
                xDomain: [40, 105]
              },{
                name: 'Window time (SOS)',
                nationalAverage: sosfranchiseaverage,
                alertMessage: 'SOS increased by 5 seconds this month, driven predominantly by slower late night and weekend performance.',
                alertType: 'warning',
                starRating: sosstarrating,
                tickValues: [65, 75, 85, 95],
                topPerformer: sostopperformer,
                value: sos,
                weight: null,
                xDomain: [40, 105] }].map((o,i) => {
                 return <Card i={ i } data={ o } distribution='stacked' />
              }) : null
            }
            </div>
            <div className='card-stacked-layout-second-col'>
              { dataUpdated ?
                [{
                  name: 'Avg. Training rate',
                  nationalAverage: trainingfranchiseaverage,
                  alertMessage: null,
                  starRating: trainingstarrating,
                  tickValues: [65, 75, 85, 95],
                  topPerformer: trainingtopperformer,
                  value: acr,
                  weight: null,
                  xDomain: [40, 105]
                },{
                  name: 'Turnover rate',
                  nationalAverage: turnoverfranchiseaverage,
                  alertMessage: null,
                  starRating: turnoverstarrating,
                  tickValues: [65, 75, 85, 95],
                  topPerformer: turnovertopperformer,
                  value: turnoverrate,
                  weight: null,
                  xDomain: [40, 105] }].map((o,i) => {
                  return <Card i={ i } data={ o } distribution='stacked'/>
                }) : null
              }
            </div>
            <div className='card-stacked-layout-third-col'>
              { dataUpdated ?
                [{
                  name: 'Brand standards',
                  nationalAverage: brandstandardsfranchiseaverage,
                  alertMessage: 'Brand standards increased 3% this month, driven predominantly by clean environment and food quality.',
                  alertType: 'success',
                  starRating: brandstarrating,
                  tickValues: [65, 75, 85, 95],
                  topPerformer: brandstandardstopperformer,
                  value: brandstandards,
                  weight: null,
                  xDomain: [40, 105]
                }].map((o,i) => {
                  return <Card i={ i } data={ o } distribution='stacked'/>
                }) : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  ;
};

const mapStateToProps = (
  dispatch => ({
    restaurantDispatch: dispatch.restaurant,
    periodDispatch: dispatch.grades,
    lineChartDispatch: dispatch.linechart,
    userDispatch: dispatch.user
  })
);

export default connect(mapStateToProps)(ScoreCard);

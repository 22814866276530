import React, { useEffect } from 'react';
//import { connect } from 'react-redux';
import { StarSmall } from '../../Charts/Elements/Stars';
import { drawHorizontalBar, updateHorizontalBar } from '../../Charts/D3/helpers';

const BarChart = ({
  i,
  id,
  row,
  className,
  xDomain,
  dataPoint,
  tickValues,
  displayStars,
  displayTicks,
  title
  }) => {

  useEffect(() => {
    drawHorizontalBar({ id, row, xDomain, dataPoint, tickValues, displayTicks, title });
  },[])
  ;
  useEffect(() => {
    updateHorizontalBar({ id, row, dataPoint, xDomain, i });
  },[dataPoint])
  ;
  return <><div
    id={ `${ id }-${ row }` }
    className={ className }
  ></div>
  {
    displayStars ? renderStarsRow(id) : null
  }
  </>

};

const renderStarsRow = id => {
  const array = [1,2,3,4,5];
  return <div className='star-rating-row-series'>
    <svg
      id={ `star-layout-${ id }` }
      xmls='http://www.w3.org/2000/svg'
      width={ 0 }
      height={ 0 }
    >
      <g className='a' id={ `star-${ id }` } fill='#ffc800'>
        {
          array.map((o,i) => (
           <>
            <StarSmall
              id={ id }
              i={ o - 1 }
              width={ 20 }
              height={ 20 }
            />
           {
             id === 'guestsatisfactionacr' || id === 'windowtimesos' ?
             <text id={ `text-${ o - 1}` }>{ array.length - i }</text> :
               <text id={ `text-${ o - 1 }` }>{ o }</text>
           }
           </>
          ))
        }
      </g>
    </svg>
  </div>
};

export default BarChart;

import {getLineChart, getLineChartStatic } from '../api/';

const initialState = {
  line1: [{
    "granularity":"Monthly",
    "restno":9829,
    "period":"Jan 2020",
    "weekperiod":"",
    "overallstarrating":"",
    "prevoverallstarrating":"",
    "tooltip":"0.0",
    "avgrating":"",
    "xaxis":"Jan 2020|Feb 2020|Mar 2020|Apr 2020|May 2020|Jun 2020"
  },{
    "granularity":"Monthly",
    "restno":9829,
    "period":"Feb 2020",
    "weekperiod":"",
    "overallstarrating":"",
    "prevoverallstarrating":"",
    "tooltip":"0.0",
    "avgrating":"",
    "xaxis":"Jan 2020|Feb 2020|Mar 2020|Apr 2020|May 2020|Jun 2020"
  }, {
    "granularity":"Monthly",
    "restno":9829,"period":"Mar 2020",
    "weekperiod":"",
    "overallstarrating":"",
    "prevoverallstarrating":"",
    "tooltip":"0.0",
    "avgrating":"",
    "xaxis":"Jan 2020|Feb 2020|Mar 2020|Apr 2020|May 2020|Jun 2020"
  },{
    "granularity":"Monthly",
    "restno":9829,
    "period":"Apr 2020",
    "weekperiod":"",
    "overallstarrating":"",
    "prevoverallstarrating":"",
    "tooltip":"0.0",
    "avgrating":"",
    "xaxis":"Jan 2020|Feb 2020|Mar 2020|Apr 2020|May 2020|Jun 2020"
  },{
    "granularity":"Monthly",
    "restno":9829,
    "period":"May 2020",
    "weekperiod":"",
    "overallstarrating":"",
    "prevoverallstarrating":"",
    "tooltip":"0.0",
    "avgrating":"",
    "xaxis":"Jan 2020|Feb 2020|Mar 2020|Apr 2020|May 2020|Jun 2020"
  }],
  weekly:[ { "name": "July", "value": 2.5 },
    { "name": "August", "value": 3.5 },
    { "name": "September", "value": 2.5 },
    { "name": "October", "value": 3.5 },
    { "name": "November", "value": 3.4 },
    { "name": "Dec", "value": 2.5 }],
  monthly:[
    { "name": "July", "value": 2.9 },
    { "name": "August", "value": 2.9 },
    { "name": "September", "value": 2.9 },
    { "name": "October", "value": 2.9 },
    { "name": "November", "value": 2.9 },
    { "name": "Dec", "value": 2.9 }],
  isLoading: true
};

export const linechart = ({
  state: initialState,
  reducers: {
    setLineChart(state, { lineChartData, restaurantStatic }) {
      const
          { line1, statusCode } = lineChartData,
          { monthly, weekly } = restaurantStatic
      ;
      return {
        ...state,
        isLoading: false,
        line1: line1,
        statusCode: statusCode,
        monthly: monthly,
        weekly: weekly
      }
    }
  },
  selectors: {
    definePeriods() {
      return periods => {
        return periods?.map(o => ({
          value: o.period
        }))
      }
    }
  },
  effects: {
    async loadLineChart(params, state) {
      if (!params) return;
      const
        { fzCode } = state.user,
        { period, brandCode, restNo } = params,
        month = period?.includes('-') ? period.split(' - ')[1] : period,
        lineChartData = await getLineChart(month, fzCode, brandCode, restNo),
        restaurantStatic = await getLineChartStatic()
      ;
      this.setLineChart({ lineChartData, restaurantStatic});
    }
  }
});

export default linechart;
import axios from 'axios';
import { URL, STAGE, ENDPOINTS } from '../wsconfig.json';
const {
  GET_GRADES,
  GET_TABLES,
  GET_METRICS,
  GET_LINECHART,
  GET_RESTVIEW
} = ENDPOINTS
;

const
  requestAPI = (fzCode, brandCode) => ({
    method: 'GET',
    // url: '../apiProxy.json'
    url: `${ URL }/${ STAGE }/api/${ GET_GRADES }?fzCode=${ fzCode }&countryCode=US&brand=${ brandCode }`
  }),
  requestFile = {
    method: 'GET',
    url: '../assets/dashboard2.json'
  },
  requestStars = fzCode => ({
    method: 'GET',
    url: '../assets/stars.json'
  }),
  metricsAPI = (period, fzCode, brandCode) => ({
    method: 'GET',
    url: `${ URL }/${ STAGE }/api/${ GET_METRICS }?period=${ period }&fzCode=${ fzCode }&countryCode=US&brand=${ brandCode }`
  }),
  tableAPI = (period, fzCode, brandCode) => ({
    method: 'GET',
    url: `${ URL }/${ STAGE }/api/${ GET_TABLES }?period=${ period }&fzCode=${ fzCode }&countryCode=US&restNo=9829&brand=${ brandCode }`
  }),
  arlTableFile = () => ({
    method: 'GET',
    url: '../assets/arlTableMessages.json'
  }),
  lineChartAPI = (period, fzCode, brandCode, restNo) => ({
    method: 'GET',
    url: `${ URL }/${ STAGE }/api/${ GET_LINECHART }?fzCode=${ fzCode } &countryCode=US&restNo=${ restNo }&period=${ period }&brand=${ brandCode }`
  }),
  lineChartStatic = () => ({
    method: 'GET',
    url: '../assets/lineGraphData.json'
  }),
  restaurantViewAPI = (month, brandCode, restNo) => ({
    method: 'GET',
    url: `${ URL }/${ STAGE }/api/${ GET_RESTVIEW }?restNo=${ restNo }&period=${ month }&countryCode=US&brand=${ brandCode }`
  })
  ;
export const getGrades = (fzCode, brandCode) => {
  const url = requestAPI(fzCode, brandCode)
  return axios(url)
    .then( res => res.data)
    .then( res => {
      const
        { data } = res,
        details = data
      ;
      return details
      ;
    })
};

export const getStarsRating = fzCode => {
  return axios(requestStars(fzCode))
    .then( res => res.data )
    .then( stars => stars.data )
};

export const getMetricsStatic = () => {
  return axios(requestFile)
    .then( res => res.data )
    .then( data => data.metrics )
};

export const getMetricsAPI = (period, fzCode, brandCode) => {
  return axios(metricsAPI(period, fzCode, brandCode))
    .then( res => res.data )
    .then( metrics => {
      const { data, grading, starRating } = metrics.data;
      return { data, grading, starRating };
    });
};

export const getRestaurantAPI = (month, brandCode, restNo) => {
  return axios(restaurantViewAPI(month, brandCode, restNo))
    .then( res => res.data )
    .then( restaurant => restaurant.data[0] )
};

export const getLineChartStatic = () => {
  return axios(lineChartStatic())
    .then( res => res.data )
};

export const getTableRows = (period, fzCode, brandCode) => {
  return axios(tableAPI(period, fzCode, brandCode))
    .then(res => res.data )
    .then(table => table.data )
};

export const getArlTableRows = (period, fzCode) => {
  return axios(arlTableFile())
    .then(res => res.data )
    .then(arlTable => arlTable.data )
};

export const getLineChart = (month, fzCode, brandCode, restNo) => {
  return axios(lineChartAPI(month, fzCode, brandCode, restNo))
    .then(res => ({ lineData: res.data, statusCode: res.status }))
    .then(lineChart => ({ line1: lineChart.lineData.data, statusCode: lineChart.statusCode }))
};

import Modal from 'react-modal';
import { connect } from 'react-redux';
import './ModalDisplay.scss'
import { history } from '../../helpers/history';

const customStyles = { 
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0,0,0,0.5)'
  },
  content: {
    top                   : '50%',
    left                  : '50%',
    right                 : '70%',
    width                 : '834px'  ,
    height                : '367px',
    border                : 'none' ,
    borderRadius          : '10px 10px 10px 10px',
    // backGround            : '#FFFFFF 0% 0% no-repeat padding-box',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    overflow              : 'hidden',
    fontfamily            : 'FlameSans-Bold',
    borderColor           : '#693F34',
    // backgroundColor       : "rgba(0,0,0,0.5)"
  }
};

const ModalDisplay = props => {
  const
    { modalIsOpen, closeModal, periodDispatch, dispatch } = props,
    { period } = periodDispatch,
    onClick = () => {
      history.push(`/scorecard`);
    },
    afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    }
    ;
  return (
    <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Open Scorecard Modal"  >
      <p className="p1">Switching to Monthly view</p>
      <p className ="p2">You were viewing the data in a 4 months time period. You will now see the data by month starting with the latest month as default.</p>
      <button onClick={closeModal} className="CancelButton" >Cancel</button>
      <button onClick={() => onClick(period) } className="okbutton" >OK</button>
    </Modal>
  );

}

const mapStateToProps = (
  dispatch => ({
    periodDispatch: dispatch.grades
  })
);

export default connect(mapStateToProps)(ModalDisplay);
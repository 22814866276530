/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { history } from '../../helpers/history';
import useWindowDimensions from '../Charts/Elements/WindowSize';
import Arrow from '../Charts/Elements/Arrow';
import './BreadCrumb.scss';


const BreadCrumb = props => {
  const {
    className,
    home,
    mobileChildren,
    dropDownChildren,
    children,
    currentPeriod,
    content,
    gridColumnTitle,
    trail,
   
    } = props,
    [timePeriod, setTimePeriod] = useState(null),
    { windowWidth } = useWindowDimensions(),
    screenSmall = windowWidth < 550 ? true : false
  ;
  useEffect(() => {
    setTimePeriod(currentPeriod);
  },[currentPeriod, props])
  ;
  return (
    <>
      <div className={ `site-navigation-arrow ${ className }` }>
        <a onClick={() => history.push(home) }
        >Home
        </a> {
          trail.map(o => {
            return <a
              onClick={() => o.url ? history.push(o.url) : null }
            > <Arrow/> { ` ${ o.name }` }  </a>
          })
        }
      </div>
        { dropDownChildren }
      <div className={ `site-navigation-header-dropdown ${ className }` }>
        <div className='user-name-title' style={{ gridColumn : gridColumnTitle }}>
          { screenSmall ? mobileChildren : <p>{ content }</p> }
        </div>
        { children }
        {/* { timePeriod ?
          <div className='dropdown-right-box'>
            <InputLabel
              className='time-period-label-dropdown'
              id='demo-simple-select-placeholder-label-label'>
              Time Period
            </InputLabel>
            <Select
              labelId='demo-simple-select-placeholder-label-label'
              id='demo-simple-select-placeholder-label'
              value={ timePeriod }
              onChange={ evt => setTimePeriod(String(evt.target?.value)) }
              displayEmpty
              defaultValue={ timePeriod }
              className='filtered selectEmpty'
            >
              {periods.map(({value}, index) => (
                <MenuItem
                  key={ index }
                  value={ value }
                  className='dropdown-options-customized'
                >
                  { value }
                </MenuItem>
              ))}
            </Select>
          </div> : null
        } */}

      </div>
    </>
  )
};
const mapStateToProps = (state, prevProps) => ({
  currentPeriod: state.grades.period,
  givenName: state.user.givenName,
  familyName: state.user.familyName,
  line1: state.linechart.line1,
  grades: state.grades,
  ...prevProps
});

const mapDispatchToProps = (
    dispatch => ({
      tableDispatch: dispatch.table,
      periodDispatch: dispatch.grades,
      lineChartDispatch: dispatch.linechart
    })
);

// const mapStateToProps = (
//   dispatch => ({
//     tableDispatch: dispatch.table,
//     periodDispatch: dispatch.grades
//   }),
//   state => ({
//     currentPeriod: state.grades.period,
//     periods: store.select.grades.definePeriods(state.grades),
//     givenName: state.user.givenName,
//     familyName: state.user.familyName,
//   }),
//   prevProps => ({
//     ...prevProps
//   })
// );

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumb);
import React, { useEffect } from 'react'
import UserProvider from './user'
import { connect } from 'react-redux';
import { branding } from '../brandConfig.json';

const GradesProvider = props => {
    console.log("GradesProvider BrandCode: ", localStorage.getItem('BrandCode'))
    const
        { children, dispatch, userDispatch } = props,
        { brandCode } = userDispatch,
        brand = branding[brandCode || 'GENERIC'].brand,
        { franOrgRoles, fzCode } = userDispatch
    ;
    useEffect(() => {
        if (franOrgRoles === 'Multi-Unit Manager') {
            return dispatch.grades.loadStarsRatingList(fzCode);
        }
        if (franOrgRoles === 'Reporting Administrator') {
            return dispatch.grades.loadGradesList({fzCode, brand});
        }
        if (franOrgRoles === 'Restaurant Manager') {
            return dispatch.grades.loadStarsRatingList(fzCode);
        }
    },[userDispatch.isLoading])
    ;
    return (
        <>
            { children }
        </>
    )
};

const mapStateToProps = () => (
    dispatch => ({
        userDispatch: dispatch.user,
        gradesDispatch: dispatch.grades
    })
);

export default connect(mapStateToProps)(GradesProvider);
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import HowitWorks from "../Pages/HowitWorks/index";
import UserProvider from '../providers/user';

const HowItWorks = () => {
  return (
      <UserProvider>
        <div>
          <Header />
          <HowitWorks/>
          <Footer />
        </div>
      </UserProvider>

  );
};
export default HowItWorks;
import React from 'react';
// import './Danger.scss';
const Error = () => {

    return (
            <svg id="alert" xmlns="http://www.w3.org/2000/svg" width="64.056" height="54" viewBox="0 0 64.056 54">
            <path id="Path_14608" data-name="Path 14608" d="M19.13,59.434,4.769,35.61a3.112,3.112,0,0,1,0-3.221L19.13,8.566A3.27,3.27,0,0,1,21.935,7H50.76a3.27,3.27,0,0,1,2.806,1.566L67.926,32.39a3.112,3.112,0,0,1,0,3.221L53.566,59.434A3.27,3.27,0,0,1,50.76,61H21.935A3.27,3.27,0,0,1,19.13,59.434Z" transform="translate(-4.32 -7)" fill="#cdcdcd"/>
            <path id="Path_14609" data-name="Path 14609" d="M26.226,47.471a5.87,5.87,0,0,1-4.059-1.465,4.775,4.775,0,0,1-1.68-3.673,4.666,4.666,0,0,1,1.73-3.718,5.711,5.711,0,0,1,2.555-1.233h-.387a2.7,2.7,0,0,1-2.754-2.554l-.74-19.076a2.573,2.573,0,0,1,.77-1.941A2.79,2.79,0,0,1,23.645,13h5.071a2.784,2.784,0,0,1,1.987.816,2.565,2.565,0,0,1,.765,1.946l-.783,19.072a2.7,2.7,0,0,1-2.754,2.549h-.249a5.533,5.533,0,0,1,2.566,1.264,4.726,4.726,0,0,1,1.67,3.687,4.781,4.781,0,0,1-1.647,3.7A5.859,5.859,0,0,1,26.226,47.471Z" transform="translate(5.824 -3.235)" opacity="0.05"/>
            <path id="Path_14610" data-name="Path 14610" d="M25.913,46.343a5.079,5.079,0,0,1-3.5-1.251A4.021,4.021,0,0,1,20.987,42a3.915,3.915,0,0,1,1.452-3.123,5.233,5.233,0,0,1,3.473-1.179,5.1,5.1,0,0,1,3.475,1.2,3.966,3.966,0,0,1,1.4,3.1A4.035,4.035,0,0,1,29.4,45.124,5.053,5.053,0,0,1,25.913,46.343ZM24.073,36.275a1.9,1.9,0,0,1-1.94-1.8l-.74-19.04a1.815,1.815,0,0,1,.542-1.365,1.962,1.962,0,0,1,1.4-.572H28.4a1.958,1.958,0,0,1,1.4.574,1.807,1.807,0,0,1,.539,1.368l-.783,19.04a1.9,1.9,0,0,1-1.94,1.792Z" transform="translate(6.137 -2.922)" opacity="0"/>
            <path id="Path_14611" data-name="Path 14611" d="M25.6,36.842a4.153,4.153,0,0,1-2.949-1.084,3.457,3.457,0,0,1-1.162-2.625A3.344,3.344,0,0,1,22.661,30.5,4.294,4.294,0,0,1,25.6,29.47a4.176,4.176,0,0,1,2.926,1.038,3.4,3.4,0,0,1,1.139,2.625,3.473,3.473,0,0,1-1.128,2.658A4.141,4.141,0,0,1,25.6,36.842Z" transform="translate(6.452 6.082)" fill="#fff"/>
            <path id="Path_14612" data-name="Path 14612" d="M29.06,15.173l-.783,19.814a1.129,1.129,0,0,1-1.128,1.084H23.6a1.129,1.129,0,0,1-1.128-1.087l-.74-19.812A1.129,1.129,0,0,1,22.863,14h5.071A1.127,1.127,0,0,1,29.06,15.173Z" transform="translate(6.606 -3.064)" fill="#fff"/>
            </svg>

    )
}
export default Error;
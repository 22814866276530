import React from "react";
import { Typography } from '@material-ui/core'
import './AlertMessage.scss';
import Warning from '../Charts/Elements/Warning';
import Danger from '../Charts/Elements/Danger';
import Success from '../Charts/Elements/Success';
import Error from '../Charts/Elements/Error'


const RenderToasterComp = (type, message) => {

    if(type==="danger") return <Danger className= "svg-container" />;
    else if(type==="warning") return <Warning className= "svg-container" />;
    else if(type==="success") return <Success className= "svg-container" />;
    else if(type==="error") return <Error className= "svg-container" />;

}
const AlertMessage = ({ message, type, style, className  }) => {
  const alertContainer = `alert-grid alert-container-${ type } ${ className }`;
    if (type !== "error") 
    return (
        <div className= {alertContainer} style={ style }>
            <div className='alert-inner-layout'>
              <Typography className="alertMessage">{RenderToasterComp(type, message)}<span className="alertType"> </span>  {message}</Typography>
            </div>
        </div>
    )
    else if(type === "error") {
        return (
            <div className= {alertContainer} >
                <div className='alert-inner-layout'>
                  <Typography className="alertMessage">{RenderToasterComp(type, message)}<span className="alertType"> </span> <p> {message} </p> </Typography>
                </div>
            </div>
        )
    }    
    
}
export default AlertMessage;
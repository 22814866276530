import React from 'react';

const Arrow = () => {
  const style = {
    fill: 'none',
    stroke: '#484848',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2px',
    strokeMiterLimit: 10
  }
  ;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7.414" height="12.828" viewBox="0 0 7.414 12.828">
      <defs>
      </defs>
      <path
        style={ style }
        className="a"
        d="M18.5,11.5l-5,5-5-5"
        transform="translate(-10.086 19.914) rotate(-90)"
      />
    </svg>
  )
};

export default Arrow;
import * as d3 from 'd3';

const drawStarPath = ({ o, i, id, starSize }) => {
  const chart = selection => {
    const
      symbolGenerator = d3.symbol()
        .type(d3.symbolStar)
        .size(starSize),
      pathData = symbolGenerator(),
      g = d3.select(selection.node().parentNode)
    ;
    let defs = g.append('defs')
    ;
    let a = defs
      .append('linearGradient')
      .attr('id', `gradiation-${id}-${i}`)
      .attr('x1', '0.63')
      .attr('y1', '0.33')
      .attr('x2', '0.5')
      .attr('y2', '0.746')
    ;
    a
      .append('stop')
      .attr('offset', '0')
      .attr('stop-color', '#ffe600')
    ;
    a
      .append('stop')
      .attr('offset', '1')
      .attr('stop-color', '#f3ad3d')
    ;
    let b = defs
      .append('linearGradient')
      .attr('id', `myGradient-${id}-${i}`)
    ;
    b
      .append('stop')
      .attr('offset', `${ o }`)
      .attr('stop-color', '#f3ad3d')
    ;
    b
      .append('stop')
      .attr('offset', `${ o }`)
      .attr('stop-color', 'white')
    ;
    selection
      .attr('fill', () => {
        return o === 1 ? `url(#gradiation-${id}-${i})` : o === 0 ? 'white' : `url(#myGradient-${id}-${i})`}
      )
      .attr('class', 'b')
      .attr('d', pathData)
    ;

  };

  return chart;

};

export default drawStarPath;
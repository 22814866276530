import './index.css'
// import StackBarChart from '../../components/stacked-bar-chart'
// import data from '../../data.json'
import '../../EmployeeTableOverview'
import EmployeeTableOverview from '../../EmployeeTableOverview'
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import Legends from '../../Legends/index'
import BarChart from '../../Charts/D3/barChartNew'
import BarChartData from '../../../assets/json/smg-complaints-data.json'

const TurnoverRateDrillDowns = (props) => {
    const period = "" //props?.periodDispatch

    return (
        <div className="page-container">
            <div className='breadcrumb-trail-head'>
                <BreadCrumb
                className='two-cols-layout'
                home='/grading'
                path='/grading'
                trail={ [{ name: period, url: null }] }
                />
            </div>
            <h1>Turnover Rate</h1>
            <div className="chart-15  flex-row">
                <div className="bg-white flex-col padding-10 margin-20">
                    <Legends header={"Overall Turnover Rate"} />
                    <BarChart  w= { 1457} linedata= {[]} bardata= {BarChartData} w1 ={1300} keyT={"Attainment"} BSB = {"GB"} id = {"GB"}/>
                </div>
            </div>
            <div className="chart-16 flex-row">
                <div className="bg-white flex-col padding-10 margin-20">
                    <Legends header={"Manager Turnover Rate"} />
                </div>
                <div className="bg-white flex-col padding-10 margin-20">
                    <Legends header={"Team Member Turnover Rate"} />
                </div>
            </div>
            {/* <div className="chart-17 flex-row">
                <div className="bg-white flex-col padding-10 margin-20"><h2>EmployeeTableOverview</h2></div>
            </div> */}
            <EmployeeTableOverview/>
        </div>
    )
}

export default TurnoverRateDrillDowns;
import React from "react";
import './Warning.scss';
const Warning = () => {
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="34.4" height="29.716" viewBox="0 0 34.4 29.716" className ="svg-warning" >
            <defs>
            </defs>
            <path class="a" d="M12.273,35.854,4.561,22.744a1.749,1.749,0,0,1,0-1.772l7.712-13.11A1.747,1.747,0,0,1,13.78,7H29.26a1.747,1.747,0,0,1,1.507.862l7.712,13.11a1.749,1.749,0,0,1,0,1.772l-7.712,13.11a1.747,1.747,0,0,1-1.507.862H13.78A1.747,1.747,0,0,1,12.273,35.854Z" transform="translate(-4.32 -7)"/>
            <path class="b" d="M23.57,32.228a3.084,3.084,0,0,1-2.18-.817,2.708,2.708,0,0,1-.9-2.049,2.644,2.644,0,0,1,.929-2.074,3.022,3.022,0,0,1,1.372-.688h-.208A1.476,1.476,0,0,1,21.1,25.176l-.4-10.641A1.478,1.478,0,0,1,22.184,13h2.723a1.48,1.48,0,0,1,1.478,1.541l-.42,10.638A1.475,1.475,0,0,1,24.486,26.6h-.134a2.93,2.93,0,0,1,1.378.705,2.681,2.681,0,0,1,.9,2.056,2.713,2.713,0,0,1-.884,2.066A3.077,3.077,0,0,1,23.57,32.228Z" transform="translate(-6.357 -7.756)"/>
            <path class="c" d="M23.633,31.854a2.664,2.664,0,0,1-1.882-.7,2.286,2.286,0,0,1-.764-1.73,2.224,2.224,0,0,1,.78-1.745,2.742,2.742,0,0,1,1.865-.659,2.675,2.675,0,0,1,1.866.671,2.256,2.256,0,0,1,.754,1.733,2.3,2.3,0,0,1-.746,1.747A2.65,2.65,0,0,1,23.633,31.854Zm-.988-5.627a1.04,1.04,0,0,1-1.042-1l-.4-10.64A1.044,1.044,0,0,1,22.246,13.5H24.97a1.042,1.042,0,0,1,1.041,1.085l-.42,10.64a1.039,1.039,0,0,1-1.042,1Z" transform="translate(-6.42 -7.819)"/>
            <path class="d" d="M23.7,33.429a2.23,2.23,0,0,1-1.584-.582,1.856,1.856,0,0,1-.624-1.41,1.8,1.8,0,0,1,.63-1.416A2.306,2.306,0,0,1,23.7,29.47a2.243,2.243,0,0,1,1.571.558,1.825,1.825,0,0,1,.612,1.41,1.865,1.865,0,0,1-.606,1.427A2.224,2.224,0,0,1,23.7,33.429Z" transform="translate(-6.483 -9.831)"/>
            <path class="d" d="M25.669,14.63l-.42,10.641a.606.606,0,0,1-.606.582h-1.9a.606.606,0,0,1-.606-.584l-.4-10.64A.606.606,0,0,1,22.341,14h2.723A.605.605,0,0,1,25.669,14.63Z" transform="translate(-6.514 -7.882)"/>
        </svg>
    )

    
}

export default Warning
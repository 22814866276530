import * as d3 from 'd3';

const axisTicksForBar = ({ xDomain, width, height, tickValues, circleRadius, id }) => {
  let
    margin = {top: 20, right: 20, bottom: 20, left: 20}
  ;
  const chart = selection => {
    let xScale = d3.scaleLinear()
        .domain(xDomain)
        .range([0, width])
    ;
    let ticksTop = selection.append('g')
        .attr('transform', `translate(0,${ 4 })`)
        .call(d3.axisTop(xScale).tickValues(tickValues).tickSize(4))
    ;
    ticksTop
      .selectAll('text')
      .attr('class','no-axis-color text-hide')
      .attr('transform', 'translate(6,0)')
      .style('text-anchor', 'end')
    ;
    ticksTop
      .selectAll('.tick line')
      .attr('stroke', '#FFFFFF')
    ;
    let ticksBottom = selection.append('g')
        .attr('transform', `translate(0,${ 20 })`)
        .call(d3.axisBottom(xScale).tickValues(tickValues).tickSize(4))
    ;
    ticksBottom
      .selectAll('text')
      .attr('class','no-axis-color text-hide')
      .attr('transform', 'translate(6,0)')
      .style('text-anchor', 'end')
    ;
    ticksBottom
      .selectAll('.tick line')
      .attr('stroke', '#FFFFFF')
    ;
    let axisTop =
    selection.append('g')
      .attr('transform', `translate(0,${ 0 })`)
      .call(d3.axisTop(xScale).tickValues(tickValues))
      ;
    axisTop
      .selectAll('.tick line')
      .attr('stroke', '#7D7D7D')
    ;
    axisTop
      .selectAll('text')
      .attr('class','no-axis-color')
      .attr('transform', 'translate(6,0)')
      .style('text-anchor', 'end')
    ;
    let axisBottom =
    selection.append('g')
      .attr('transform', `translate(0,${ circleRadius * 2 })`)
      .call(d3.axisBottom(xScale).tickValues(tickValues))
      ;
    axisBottom
      .selectAll('.tick line')
      .attr('stroke', '#7D7D7D')
    ;
    axisBottom
      .selectAll('text')
      .attr('class','no-axis-color text-bottom')
      .attr('transform', 'translate(6,0)')
      .style('text-anchor', 'end')
    ;
    let starSvg =
    d3.select(`svg#star-layout-${ id }`)
      .attr('width', width + margin.left + margin.right  )
      .attr('height', height )
    ;
    starSvg.select('g')
      .attr('transform', `translate(30,0)` )
    ;
    [xDomain[0], ...tickValues].forEach((o,i) => {
      starSvg.selectAll(`g path#b${ i }, g path#c${ i }`)
        .attr('transform', `translate(${ xScale(o)},0) scale(.4)` )
      ;
      starSvg.select(`text#text-${ i }`)
        .attr('x', xScale(o) - 8 )
        .attr('y', 14 )
    });
  };

  return chart;
};

export default axisTicksForBar;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { select } from '../../rematch/store';
import { useDispatch } from '../../rematch';
import { Grade } from './Grade'
import { history } from '../../helpers/history';
import { timeFormat, timeParse } from 'd3';

const Grades = props => {
  console.log("Grades BrandCode: ", localStorage.getItem('BrandCode'))
  const
    { dispatch } = props,
    // [data, setData] = useState({
    //   grades: []
    // }),
    { data } = props.gradesDispatch,
    { franOrgRoles, fzCode } = props.userDispatch,
    brandCode = "bk",
    //brandCode = (localStorage.getItem('BrandCode')==="BKC")?"bk":"plk",
    onClick = period => {
      dispatch.grades.onChange(period);
      history.push('/dashboard');
    }
  ;
  useEffect(() => {
    if (franOrgRoles === 'Multi-Unit Manager') {
      return dispatch.grades.loadStarsRatingList(fzCode);
    }
    if (franOrgRoles === 'Reporting Administrator') {
      return dispatch.grades.loadGradesList({fzCode, brandCode});
    }
    if (franOrgRoles === 'Restaurant Manager') {
      return dispatch.grades.loadStarsRatingList(fzCode);
    }
  },[props.userDispatch.isLoading])
  ;
  return (
    <>
    {
      data?.map((o, i) =>
        <Grade
          userRole={ franOrgRoles }
          className='grading-screen'
          key={ `${ o.period }-${ i }` }
          i={ i }
          { ...o }
          imageUrl='url(#image)'
          clickEvent={ () => onClick(o.period) }
          parentPage={ franOrgRoles ? franOrgRoles : 'not-created-yet' }
        />)
    }
    </>
  )
};

const mapStateToProps = () => (
  state => ({
    gradesState: state
  }),
  dispatch => ({
    gradesDispatch: dispatch.grades,
    userDispatch: dispatch.user
  })
);

export default connect(mapStateToProps)(Grades);

import { RematchRootState, RematchDispatch } from '@rematch/core';
import { useOktaAuth } from '@okta/okta-react';
import LogRocket from 'logrocket';
// import { RootModel } from '../rematch/models';

export const user = ({
  state: {
    isLoading: true,
    brand: '',
    brandCode: '',
    domain: '',
    franCountry: '',
    franOrgRoles: '',
    franOrganizationId: '',
    restNum: '',
    userId: '',
    userType: '',
    email: '',
    emailVerified: '',
    familyName: '',
    givenName: '',
    locale: '',
    defaultView:""
  },
  reducers: {
    setUserState(state, payload) {
      return {
        ...state,
        isLoading: false,
        brand: payload.Brand,
        brandCode: payload.BrandCode,
        domain: payload.Domain,
        franCountry: payload.FranCountry,
        franOrgRoles: payload.franOrgRoles[0],
        fzCode: payload.franOrganizationId,
        restNum: payload.RestNum,
        userId: payload.UserID,
        userType: payload.UserType,
        email: payload.email,
        emailVerified: payload.email_verified,
        familyName: payload.family_name,
        givenName: payload.given_name,
        locale: payload.locale,
        defaultView:payload.defaultView
        // email: payload.email,
        // locale: payload.locale,
        // name: payload.name,
        // givenName: payload.given_name,
        // familyName: payload.family_name,
        // sub: payload.sub,
        // // NOTE: restaurant numbers are coming back as type: `number` in qa/prod. They need to be strings
        // // Remove when qa/prod returns restaurant numbers as strings
        // stores: payload.RestNum.map(String),
        // updatedAt: payload.updated_at,
        // userId: payload.UserID,
        // userRole: payload.userRole,
      };
    }
  },
  effects: dispatch => ({
     async setUser(payload) {
        if (!payload) {
          return;
        }
        dispatch.user.setUserState(payload);
    }
  })
});

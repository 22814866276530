import * as d3 from 'd3';
import horizontalBarChart from './barChart';
import drawStarPath from './drawStarPath';
import horizontalBarUpdate from './barChartUpdate';
import gaugeDonutChart from './donutChart.js';
import gaugeValueUpdate from './donutChartUpdate.js';
import axisTicks from './axisTicks';
import lineChart from './lineChart';

export const drawHorizontalBar = ({ id, row, xDomain, dataPoint, tickValues, displayTicks, title }) => {
  const
    noop = () => () => ({}),
    axisTicksForBar = displayTicks ? axisTicks : noop ,
    chart = horizontalBarChart({ id, row, xDomain, tickValues, axisTicksForBar })
    ;
  d3.select(`#${ id }-${ row }`)
    .data([{
      "title": title,
      "value": dataPoint
    }])
    .call(chart);
};

export const updateHorizontalBar = ({ id, row, dataPoint, xDomain, i }) => {
  const updateChart = horizontalBarUpdate({ id, row, xDomain, dataPoint, i });
  d3.select(`#${ id }-${ row }`)
    .call(updateChart);
};

export const drawGaugeChart = ({ className, stars, data, odometerTitle }) => {
  const chart = gaugeDonutChart({ className, stars, data, odometerTitle });
  d3.select(`.${ className }`)
    .call(chart);
};

export const updateGaugeMetric = ({ className, stars, grading }) => {
  const updateChart = gaugeValueUpdate({ className, stars, grading});
  d3.select(`.${ className }`)
    .call(updateChart);
};

export const drawLineChart = ({ className, id, monthly, weekly, line1 }) => {
  const chart = lineChart({ id, monthly, weekly, line1 })
  ;
  d3.select(`.${ className }`)
    .call(chart);
};

export const drawStars = ({ id, o, i }) => {
  const
    starSize = 400,
    element = drawStarPath({ o, i, id, starSize })
    ;
  d3.select(`#${ id }`)
    .call(element);
};

import { connect } from "react-redux";
import RestaurantTable, { TableData } from "../../Components/Table";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
//import cssFile from "../../utils/conditionalcss";
import PeriodDropdown from "../../Components/BreadCrumb/Dropdown/PeriodDropdown"
//require(`${cssFile('index')}`)

interface Props{
    rows:TableData[],
    user:string,
    userDispatch?:{brandCode:string},
    currentPeriod?:string
}
const ViewRestaurants = (props:Props) => {
require(`${cssFile('index')}`)

return (
    <div>
       <div className='all-restaurant-container-breadcrumb'>
          <div className='breadcrumb-trail-head'>
             <BreadCrumb
                className='two-cols-layout'
                home='/'
                trail={ [ { name: props.currentPeriod, url: null},{ name: "All Restaurants", url: null }] }
                dropDownChildren = {
                  <PeriodDropdown
                    className='two-cols-layout'
                  //   onChange={ pd => onChange(pd) }
                  />
                }
             />
          </div>
       </div>
        {/* <div className="breadCrumb" >Home &gt; Jan 2021 - June 2021 &gt; <span style={{color:"#484848"}}>All restaurants</span></div> */}
        <p className="restStyle">All Restaurants of {props.user}
        ({props.rows.length})</p>
        <RestaurantTable rows={props.rows} viewRestaurants={true} />
    </div>
)
}
const mapDispatchToProps =(dispatch)=> ({
   
      userDispatch: dispatch.user
}
  );
  const mapStateToProps = (state, prevProps) => ({
  currentPeriod: state.grades.period,
  ...prevProps
});

const cssFile=(name)=>{
  const brand = localStorage.getItem('BrandCode')
  console.log(brand)
  return `./${name}BK.scss`
  //return brand==="PLK" ? `./${name}PLK.scss` : `./${name}BK.scss`
}

  export default connect(mapStateToProps,mapDispatchToProps)(ViewRestaurants);
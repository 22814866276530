import GuestSatisfactionDrillDowns from './guest-satisfaction'
import BusinessStandardDrillDowns from './business-standards'
import TurnoverRateDrillDowns from './turnover-rate'
import AverageTrainingDrillDowns from './average-training'
import ContactCenterDrillDowns from './contact-center'
import SMGSurveysDrillDowns from './smg-surveys'
import WindowTimeDrillDowns from './window-time'

const renderSwitch = (param) => {
  // gs|bs|tr|at|cc|smg|wt
  switch (param) {
    case 'gs':
      return <GuestSatisfactionDrillDowns />;
    case 'bs':
      return <BusinessStandardDrillDowns />;
    case 'tr':
      return <TurnoverRateDrillDowns />;
    case 'at':
      return <AverageTrainingDrillDowns />;
    case 'cc':
      return <ContactCenterDrillDowns />;
    case 'smg':
      return <SMGSurveysDrillDowns />;
    case 'wt':
      return <WindowTimeDrillDowns />;
    default:
      return <GuestSatisfactionDrillDowns />;
  }
}
const DrillDowns = (props) => {
  console.log('name  ------------ ' + props.name)
  return (
    <div className="">
      <div className="">
        {renderSwitch(props.name)}
      </div>
    </div>
  );
};

export default DrillDowns;
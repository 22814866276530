import React from 'react';
import { Route,Switch, BrowserRouter, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { store } from './rematch/store';
import { history } from './helpers/history';
import Welcome from './Pages/Welcome';
import Login from './Pages/Login';
import Grading from './Pages/Grading';
import {SetUserInfo} from './Pages/Preferences';

import HowItWorks from './Pages/HowItWorks';
import Dashboard from './Pages/Dashboard';
import GoogleReview from './Pages/GoogleReviews';
import ScoreCard from './Pages/ScoreCard';
import Drilldown from './Pages/Drilldown'
import PointsDetails from "./Components/points/index"
import Comments from "./Components/Comments/index"
import ViewAllRestaurants from "./Pages/ViewAllRestaurants"
import RowChart from "./Components/row-chart/index"
import './fontStyles.scss';
import EmployeeTable from './Components/EmployeeTable/index'
import Barchart from './Components/Charts/D3/barChartNew'

import config from './config';
import RowChart1 from './Components/Charts/D3/rowChart'

const AppWithRouterAccess = () => {

    const customAuthHandler = () => {
        history.push('/login');
    };

    return (

        <React.Fragment>
            <Security {...config.oidc} onAuthRequired={customAuthHandler}>
                <BrowserRouter>
                    <Provider store={ store }>
                        <Switch>
                            <Router history={history}>
                                <SecureRoute path='/' exact={true} component={Welcome} />
                                <SecureRoute path='/set-user' component={SetUserInfo} />
                                <SecureRoute path='/grading' component={Grading} />
                                <SecureRoute path='/dashboard' component={Dashboard} />
                                <SecureRoute path='/howitworks' component={HowItWorks} />
                                <SecureRoute path='/scorecard' component={ScoreCard} />
                                <Route path='/drilldown/:name(gs|bs|tr|at|cc|smg|wt)' component={Drilldown} />
                                <SecureRoute path='/pointdetails' component={PointsDetails} />
                                <SecureRoute path="/googlereview/:name" component={GoogleReview}/>
                                <SecureRoute path='/comments' component={Comments} />
                                <SecureRoute path="/viewrestaurants/:user" component={ViewAllRestaurants}/>
                                <SecureRoute path="/rowchart" component={RowChart}/>
                                <SecureRoute path="/employeetable" component={EmployeeTable}/>
                                <SecureRoute path="/Barchart" component={Barchart}/>
                                <SecureRoute path="/RowChart1" component = {RowChart1} />
                                <Route path='/login' render={() => <Login />} />
                                <Route path='/implicit/callback' component={LoginCallback} />
                            </Router>
                        </Switch>
                    </Provider>
                </BrowserRouter>
            </Security>
        </React.Fragment>
    );
};
export default AppWithRouterAccess;
import { getMetricsAPI, getMetricsStatic } from '../api';
import { metricsNull, staticMetrics, pieChart } from '../assets/json/dashboard2.json';

const initialState = {
  details: metricsNull,
  staticMetrics: metricsNull,
  loading: true,
  grading: '',
  starRating: '',
  weights: pieChart.weights
};

export const metrics = ({
  state: initialState,
  reducers: {
    setMetrics(state, { data, grading, starRating }) {
      const mergeData = details => {
        const result = Object.values([...details, ...staticMetrics].reduce((a,{header, ...props}) => {
            let { details } = props;
            if(!a[header])
              a[header]  = Object.assign({}, {header,details : [...details]});
            else
              a[header].details.push(...details);
            return a;
          },{}))
          ;
        return result.map(o => {
          return {
            header: o.header,
            details: o.details.reduce((a, {name, ...props}) => {
              const found = a.find(b => b.name === name);
              if(!found) {
                a.push({name: name, ...props});
              }
              else {
                Object.assign(found, {...props});
              }
              return a;
            }, [])
          }
        });
      };
      return {
        ...state,
        grading: grading,
        starRating: starRating,
        weights: pieChart.weights,
        loading: false,
        details: mergeData(data)
      }
    },
    setStaticMetrics(state, metricsStatic) {
      return {
        ...state,
        loading: false,
        staticMetrics: metricsStatic
      }
    },
    setMetricsNameInput(state, metricsName) {
      return {
        ...state,
        loading: false,
        metricsName: metricsName
      }
    }
  },
  effects: {
    async loadMetrics(params, state) {
      if (!params) return;
      const
        { fzCode } = state.user,
        { period, brandCode } = params,
        metricsObject = await getMetricsAPI(period, fzCode, brandCode),
        metricsStatic = await getMetricsStatic()
      ;
      this.setMetrics(metricsObject);
      this.setStaticMetrics(metricsStatic);
    },
    onChange(metricName) {
      this.setMetricsNameInput(metricName);
    }
  }
});

export default metrics;

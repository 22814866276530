import React, { useEffect } from "react"
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import  { TableData } from "../Components/Table";
import Header from '../Components/Header';
import Footer from '../Components/Footer/index';
import ViewRestaurants from "./ViewRestaurants/index";
import UserProvider from '../providers/user';

interface Params {
    user: string
}
const ViewAllRestaurants = (props) => {

    const location = useLocation()
    const rows=location.state as TableData[]
    const params: Params = useParams()
    const restStyle = {
        paddingLeft:"6%",
        font: "normal normal bold 42px/52px Chicken Sans",
        letterSpacing: "0px",
        color: "#222222",
        opacity: 1
    }
    const breadCrumb={
        font: "normal normal normal 21px/24px Chicken Sans",
        color: "#FF7D00",
        paddingLeft:"6%",
        paddingTop:"2%",
    }
return (
    <UserProvider>
        <div>
            <Header/>
            <ViewRestaurants rows={rows} user={params.user}/>
            <Footer/>
        </div>
    </UserProvider>
)
}
export default ViewAllRestaurants;
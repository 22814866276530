import * as d3 from 'd3';

const barChartValueUpdate = ({ id, row, dataPoint, xDomain, i }) => {
  const formatMetricText = d => id === 'windowtimesos' ? 's' : id === 'guestsatisfactionacr' ? '' : +d < 100 ? '%' : '';

  const chart = selection => {
    let
      margin = {top: 20, right: 20, bottom: 20, left: 20}
    ;
    selection.each( () => {
      const
        div = d3.select(`#${ id }-${ row }`),
        nodeDimensions = div.node().getBoundingClientRect(),
        width = nodeDimensions.width - (margin.right + margin.left),
        xScale = d3.scaleLinear()
        .domain(xDomain)
        .range([0, width]),
        circleRadius = 12,
        seconds = 1000 + 500 * i
      ;
      div.select(`rect#circle-clip-${ id }`)
        .transition()
        .duration(seconds)
        .attr('width', () => {
          if (+dataPoint > xDomain[1]) {
            return xScale(xDomain[1]);
          }
          if (+dataPoint < xDomain[0]) {
            return xScale(xDomain[0]);
          }
          return xScale(+dataPoint)
        } )
      ;
      div.select(`mask#circle-clip-${ id }-${ row } circle.mask-circle-datapoint`)
        .transition()
        .duration(seconds)
        .attr('cx', () => {
          if (+dataPoint > xDomain[1]) {
            return xScale(xDomain[1]);
          }
          if (+dataPoint < xDomain[0]) {
            return xScale(xDomain[0]);
          }
          return xScale(+dataPoint);
        })
      ;
      div.select(`mask#circle-clip-${ id }-${ row } rect.mask-rect-datapoint`)
        .transition()
        .duration(seconds)
        .attr('width', () => {
          if (+dataPoint > xDomain[1]) {
            return xScale(xDomain[1]) - circleRadius * 2;
          }
          if (+dataPoint < xDomain[0]) {
            return xScale(xDomain[0]) - circleRadius * 2;
          }
          return xScale(+dataPoint) - circleRadius * 2
        })
      ;
      div.select('text.innerText-value')
        .transition()
        .duration(1000 + 500 * i)
        .text(d => `${ Math.round(+dataPoint) }${ formatMetricText(+dataPoint)}` )
        .attr('x', () => {
          if (+dataPoint > xDomain[1]) {
            return xScale(xDomain[1]) - circleRadius * 2;
          }
          if (+dataPoint < xDomain[0]) {
            return xScale(xDomain[0]) - circleRadius * 2;
          }
          return xScale(+dataPoint) - circleRadius;
        });
    });
  };
  return chart;
};

export default barChartValueUpdate;


import React, { useState } from 'react'
// import "./index.scss"
import {data} from "./EmpTable.json"
import { Table,TableBody,TableCell,TableHead,TableRow } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination';
import { connect } from 'react-redux';


const EmployeeTable=(props)=>{
    if(props.userDispatch.brandCode == 'BKC') 
    {require("./indexBK.scss")}
          else {require("./indexPLK.scss")}
  const [page, setPage] = React.useState(1);  
  const [acsending, setAcsending] = React.useState(false);  
  const [pageItems,setPageItems]=useState(data.slice(0,10))
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setPageItems(data.slice((value-1)*10,value*10))
  };
    const handleSort=()=>{
        setAcsending(!acsending)
        var sortArray=[]
        if(acsending) sortArray=data.sort((a,b)=> {
            if (a.name < b.name)
              return -1;
            if (a.name > b.name)
              return 1;
            return 0;
          })
          else sortArray=data.sort((a,b)=> {
            if (a.name > b.name)
              return -1;
            if (a.name < b.name)
              return 1;
            return 0;
          })
          setPageItems(sortArray.slice(0,10))
    }
    return(
        <div className="emp-table">
            <p className="emp-title">Employees</p>
            <div id="chart"></div>
          
            <Table className="table">
                <TableHead>
                    <TableRow>
                        <TableCell className="emp-table-title left">Restaurant #</TableCell>
                        <TableCell className="emp-table-title left">Employee Name<img onClick={()=>handleSort()} className='sort-icon' src="/assets/sortIcon.svg"></img></TableCell>
                        <TableCell className="emp-table-title">Certification Completed</TableCell>
                        <TableCell className="emp-table-title">Certification In Progress</TableCell>
                        <TableCell className="emp-table-title">Certification Not Started</TableCell>
                        <TableCell className="emp-table-title">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
            {pageItems.map((emp,index)=>(
                <TableRow>
                     <TableCell className="emp-cell left">{emp.number}</TableCell>
                    <TableCell className="emp-cell left">{emp.name}</TableCell>
                    <TableCell className="emp-cell">{emp.certsCompleted}</TableCell>
                    <TableCell className="emp-cell">{emp.certsProgress}</TableCell>
                    <TableCell className="emp-cell">{emp.certsNotStarted}</TableCell>
                    <TableCell className="emp-cell">{emp.certsCompleted+emp.certsProgress+emp.certsNotStarted}</TableCell>
                </TableRow>
            ))}
            </TableBody>
            </Table>
            <Pagination variant="text" count={Math.ceil(data.length/10)} page={page}  onChange={handlePage}/>
        </div>
    )
}

const mapStateToProps = (
    dispatch => ({
      userDispatch: dispatch.user
    })
  );
  
  export default connect(mapStateToProps)(EmployeeTable);

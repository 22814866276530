import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import {user} from "../reducers/user";

const UserProvider = props => {
  const
    { dispatch, children, userDispatch } = props,
    { authService } = useOktaAuth()
    ;
  useEffect(() => {
    authService.getUser().then( data => {
      dispatch.user.setUserState(data);
    })
  },[ authService, dispatch.user ])
  ;
  return (
    <>
    { children }
    </>
  )
};

const mapStateToProps = () => (
  state => ({
    userState: state.user
  }),
  dispatch => ({
    userDispatch: dispatch.user
  })
);

export default connect(mapStateToProps)(UserProvider);
import React, { useState, useEffect } from 'react';
import { drawLineChart } from './D3/helpers';
import './LineChart.scss';
import AlertMessage from '../AlertMessage/AlertMessage';

const LineChart = ({ w, h, className, id, data}) => {
  const [period,setPeriod] = useState("monthly");
  const { weekly, monthly , line1 , statusCode } = data;
  useEffect(() => {
    const linedata1 = line1.length;
      if (statusCode === 200) {
        if (linedata1 > 0) {
          drawLineChart({ className, monthly, weekly, line1 });
        }
      }
  },[statusCode]);
  const linedata1 = line1.length;
  return <div id='trend' style={{ gridTemplateRows: `${ h * .25 }px ${ h * .75 }px`}}>
      <div className='d3-graph-integrated-text'>
        <div className='star-trend-header-first-line'>
          <div className='star-trend-rating-padding' >
          </div>
        </div>
        <div className='star-rating-header-graph'>
          <p>Star Trend</p>
        </div>
        <div className='star-rating-header-previous'>
        </div>
      </div>
      <div className='d3-graph-line-chart-container'>
        {
          linedata1 <= 0 ? <AlertMessage message ={"something went wrong.please try again."
           } type ={"error"} /> : null
        }
      </div>
    </div>;
};

export default LineChart;

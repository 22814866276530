import Card from './Card.jsx';
import { Tooltip,makeStyles,createStyles } from "@material-ui/core"
import { connect } from 'react-redux';

const tooltips=[
  {
      header:"Guest",
      tooltiptitle:'Average Complaints ratio thresholds:',
      tooltipOptions:["5 Star: <=6","4 Star: <=8","3 Star: <=9","2 Star: <=10","1 Star:10+"]
  },
  {
      header:"Guest",
      tooltiptitle:'Average window time thresholds:',
      tooltipOptions:["5 Star: <=65sec","4 Star: <=75sec","3 Star: <=85sec","2 Star: <=95sec","1 Star: 95+sec"]
  },
  {   
      header:"Team",
      tooltiptitle:'The Completion rate for all the role certifications assigned to employees',
      tooltipOptions:["5 Star: 90%","4 Star: 85%","3 Star: 70%","2 Star: 60%","1 Star: below 60%"]
  },
  {
      header:"Standards",
      tooltiptitle:'Brand Standards thresholds:',
      tooltipOptions:["5 Star: 90%","4 Star: 80%","3 Star: 70%","2 Star: 60%","1 Star: <60%"]
  }
]
const useStyles = makeStyles((theme) =>
  createStyles({
    customWidth: {
      width: "200px",
      backgroundColor: theme.palette.common.white,
      color: 'black',
    }
  }),
);

const Cards = ({ data, keyIdentifier,userDispatch}) => {
  const classes=useStyles()
  // if(userDispatch.brandCode==="BKC") require("./CardsBK.scss")
  // else require("./CardsPLK.scss")
  require(`${cssFile('Cards')}`)
  const
    reducedData = data.map(o => o[keyIdentifier] ).reduce((a,b) => a.concat(b), []),
    cardTitle = data.map(o => o.header ).reduce((a,b) => [...a,b,''], [] ),
    fillerTitle = cardTitle.splice(0,reducedData.length),
    toolTip = Header => {
       return(
          <div className="tooltip-card">
              {tooltips.map((tooltip)=> <>{tooltip.header===Header&&<><div className="tooltipHead">{tooltip.tooltiptitle}</div>
              {tooltip.tooltipOptions.map((option,index)=><>{<div key={index} className="tooltipOptions">{option}</div>}</>)}</>} </>)}
          </div>
       )
    }
    ;
  return <div className='cards-layout' style={{ gridTemplateColumns : `repeat(${ reducedData.length }, 1fr)` }}>
    {
      fillerTitle.map((o,i) => {
        return <div className='cards-title' style={{ gridColumn: `${ i + 1 } / ${ i + 2 }` , gridRow: '1 / 2' }}>
          <text className={ `h2-card-header ${ o }` }>{ o }</text>
          {o&&<Tooltip title={toolTip(o)} placement="right-start"  classes={{tooltip:classes.customWidth}}><img src="/assets/infoIcon.svg" alt="" className="infoIcon"/></Tooltip>}
        </div>
      })
    }
    {
      reducedData.map((o,i) => {
        return <Card i={ i } data={ o } distribution='columnar' />
      })
    }
  </div>
};

const mapStateToProps = (
  dispatch => ({
    userDispatch: dispatch.user
  })
);

const cssFile=(name)=>{
  const brand = localStorage.getItem('BrandCode')
  console.log(brand)
  return `./${name}BK.scss`
  //return brand==="PLK" ? `./${name}PLK.scss` : `./${name}BK.scss`
}

export default connect(mapStateToProps)(Cards);

import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, Checkbox, FormControl,FormControlLabel, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { Stars } from '../Charts/Elements/Stars';
import Delta from '../Charts/Elements/Delta';
import AddIcon from '@material-ui/icons/Add';
import { ExpandLess, RotateLeftTwoTone } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "./NestedTable.scss";
import RemoveIcon from '@material-ui/icons/Remove';
import axios from "axios";
import { useHistory } from "react-router";
import { CSVLink } from "react-csv"
import Workbook from "react-excel-workbook"
import {Link} from "react-router-dom"
import { connect } from "react-redux";
//import cssFile from "../../utils/conditionalcss";

const useStyles = makeStyles( theme =>({
  table: {
    width: "99%",
    height: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const row = [
  {
    granularity: "",
    fzcode: 0,
    fzname: "",
    period: "",
    countrycode: "",
    gmname: "",
    arlname: "",
    doname: "",
    restadress: "",
    acr: "",
    sos: "",
    avgtrainingrate: "",
    turnoverrate:"",
    brandstandards: "",
    overallstarrating: "",
    prevacr: "",
    prevsos: "",
    prevavgtrainingrate: "",
    prevturnoverrate: "",
    prevbrandstandards: "",
    prevoverallstarrating: "",
    restcnt: ""
}
]


const NestedTable = props => {
  const
    classes = useStyles(),
    [isVisible,setIsVisible]=useState(false),
    [expandUser,setExpandUser]=useState(""),
    [rows,setRows]=useState(row),
    [filteredRows,setFilteredRows]=useState(row),
    [sortValue,setSortValue]=useState(""),
    [filterValues, setFilterValues] = useState([]),
    [cancelClicked, setCancelClicked] = useState(true),
    [utilClicked, setUtilClicked] = useState("")
    const [exportClicked, setExportClicked]=useState(true)
  const [brandName,setBrandName]=useState("BK")
    // const { push } = useHistory();
  ;
  useEffect( () =>{
    console.log(props)
    const userRows = props.role.toLocaleUpperCase()==="ARL"?props.NestedTable.filter(userRow => userRow.granularity === props.role.toLocaleUpperCase()&&userRow.arlname!==""):props.NestedTable.filter(userRow => userRow.granularity === props.role.toLocaleUpperCase()&&userRow.doname!=="")
  //  if(localStorage.getItem('BrandCode')==="BKC") setBrandName("BK")
  //    else setBrandName("PLK")
  setBrandName("BK")
    setRows(userRows);
  },[props])
  ;
  useEffect( () => {
    setFilteredRows(rows);
  },[rows])
  useEffect(()=>{
      setExpandUser("")
      setIsVisible(false)
  },[props.role])
  ;
  useEffect(()=>{
    setExpandUser("")
    setIsVisible(false)
  },[props.role])
  ;
  const handleFilter = () => {
      var filtered = [];

      for (var arr in rows) {
        for (var filter in filterValues) {
          if (rows[arr].doname === filterValues[filter]) {
            filtered.push(rows[arr]);
          }
          else if (rows[arr].arlname === filterValues[filter]) {
            filtered.push(rows[arr]);
          }
        }
      }
      filtered.length > 0 ? setFilteredRows(filtered) : setFilteredRows(rows);

      setCancelClicked(true);
    }
  ;
  const handleSort = order =>{
    setSortValue(order);
    if(order==="low") setFilteredRows(rows.sort((a,b)=>a.overallstarrating-b.overallstarrating));
    else if(order==="high") setFilteredRows(rows.sort((a,b)=>b.overallstarrating-a.overallstarrating));
  }
  ;
  const handleCheck = event => {
    event.target.checked && filterValues.push(event.target.name);
    if (!event.target.checked) {
      const index = filterValues.indexOf(event.target.name);
      index > -1 && filterValues.splice(index, 1)
    }
  }
  ;
  const handlePush=()=>{
    // push("/scorecard")
  }
  ;
  const RowRender = (name, role,userDispatch)=>{
    var userRows = rows;
    if (role.toLocaleUpperCase() === "DO") userRows = props.NestedTable.filter(userRow => userRow.doname.toUpperCase() == name.toUpperCase()&&userRow.granularity==="Rest_data");
    else if (role.toLocaleUpperCase() == "ARL") userRows = props.NestedTable.filter(userRow => userRow.arlname.toLocaleUpperCase() === name.toLocaleUpperCase()&&userRow.granularity==="Rest_data");
    
    return (
      <div>
     { userRows.map((row, index) =>
        <div key={index} className='tablerow-subtable'>
          <TableCell id="expand-row" component="th" scope="row" className='first-column-restaurant'>
            <Typography className="restaurantName" onClick={handlePush}>{index + 1}.   {  row.gmname }</Typography>
            <Typography className="restaurantPlace">
              { row.restadress }
            </Typography>
            <div className="starsDiv">
              <div>
                <Stars stars={ row.overallstarrating } width={ 200 } height={ 100 }/>
              </div>
              <Typography className="starsRating">
                {row.overallstarrating}/5 stars
              </Typography>
            </div>
          </TableCell>
          <TableCell align="center">
            <Typography className="currentValue">
              {row.acr+" "}
              <Delta fill='#c23934'/>
            </Typography>
            <Typography className="previousValue">
              Previous: {row.prevacr}%
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography className="currentValue">
              {row.sos+"s "}{" "}
              <Delta fill='#198737'/>
            </Typography>
            <Typography className="previousValue">
              Previous: {row.prevsos}s
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography className="currentValue">
              {row.avgtrainingrate+"% "}{" "}
              <Delta fill='#198737'/>
            </Typography>
            <Typography className="previousValue">
              Previous: {row.prevavgtrainingrate}%
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography className="currentValue">
              {row.turnoverrate+"% "}{" "}
              <Delta fill='#c23934'/>
            </Typography>
            <Typography className="previousValue">
              Previous: {row.prevturnoverrate}%
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography className="currentValue">
              {row.brandstandards+"% "}{" "}
              <Delta fill='#198737'/>
            </Typography>
            <Typography className="previousValue">
              Previous: {row.prevbrandstandards}%
            </Typography>
          </TableCell>
          
        </div>)}
        <div className="rest-link-div"><Link  className={brandName==="BK"?"rest-link-bk":"rest-link"} to={{pathname:`/viewrestaurants/${name}`,state:userRows }}>View All Restaurants</Link>
        </div>
        </div>
    )
  };
  return (
    <div className='nestedTable'>
      <div className='tableUtils'>
        <div className='filtered filter-by-container'>
            <div className="filterHeadings" onClick={() => { setCancelClicked(!cancelClicked); setUtilClicked("filter") }}><Typography>Filter By</Typography>
              {
                !cancelClicked && utilClicked === "filter" ?
                <ExpandLess onClick={() => setCancelClicked(true)} /> :
                <ExpandMoreIcon id="filter-button" onClick={() => { setCancelClicked(false); setUtilClicked("filter") }} />
              }
            </div>
            <div id="filter-div" className={!cancelClicked && utilClicked === "filter" ? "filteredHoveredNested" : "filteredHoveredCancel"}>
              <FormControl variant="outlined" className={classes.formControl}>
                {
                  rows.map(filteredRow => {
                    return <FormControlLabel
                      control={
                        <Checkbox onChange={ handleCheck }
                                  className="checkBox"
                                  name={ props.role.toLocaleUpperCase() === 'ARL' ? filteredRow.arlname : filteredRow.doname }
                        ></Checkbox>}
                      label={ props.role.toLocaleUpperCase() === 'ARL' ? filteredRow.arlname : filteredRow.doname }
                    />
                  })
                }
                <div className="filterButtons">
                  <Button variant='text' onClick={() => setCancelClicked(true)}>Cancel</Button>
                  <Button variant="text" className="applyButton" onClick={handleFilter}>Apply</Button>
                </div>
              </FormControl>
            </div>
        </div>
        <div className="filtered sort-by-container">
          <div className="filterHeadings" onClick={() => { setCancelClicked(!cancelClicked); setUtilClicked("sort") }}><Typography>Sort By</Typography>
            {!cancelClicked&&utilClicked==="sort"?<ExpandLess onClick={()=>setCancelClicked(true)}/>:<ExpandMoreIcon id="sort-button" onClick={()=>{setCancelClicked(false);setUtilClicked("sort")}}/>}
          </div>
          <div id="sort-div" className={!cancelClicked&&utilClicked==="sort"?"filteredHoveredNested":"filteredHoveredCancel"}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Button onClick={()=>handleSort("low")} className="sort-buttons">Star Low to High</Button>
              <Button onClick={()=>handleSort("high")} className="sort-buttons">Star High to Low</Button>
            </FormControl></div>
        </div>
        <div className='export-box-layout'>
    <img src="/assets/export excel.svg" alt="" className='exportIcon' onClick={()=>setExportClicked(!exportClicked)}></img>
    <div id='export-div' className={!exportClicked?"filteredHoveredNested":"filteredHoveredCancel"}>
      <FormControl variant="outlined" className={classes.formControl}>
        <Button><CSVLink data={rows} filename="Restaurant Data.csv" className="csv-link">Export to CSV</CSVLink></Button>
        <Workbook filename="Restaurant Data.xlsx" element={<Button className="xls-link">Export to XLS</Button>}>
      <Workbook.Sheet data={rows} name="Sheet A">
        <Workbook.Column label="Granularity" value="granularity"/>
        <Workbook.Column label="FZ Code" value="fzcode"/>
        <Workbook.Column label="FZ Name" value="fzname"/>
        <Workbook.Column label="Period" value="period"/>
        <Workbook.Column label="GM Name" value="gmname"/>
        <Workbook.Column label="ARL Name" value="arlname"/>
        <Workbook.Column label="DO Name" value="doname"/>
        <Workbook.Column label="Address" value="restadress"/>
        <Workbook.Column label="Star Rating" value="overallstarrating"/>
        <Workbook.Column label="ACR" value="acr"/>
        <Workbook.Column label="SOS" value="sos"/>
        <Workbook.Column label="Turnover" value="turnoverrate"/>
        <Workbook.Column label="Brand Standards" value="brandstandards"/>
        <Workbook.Column label="Average Training rate" value="avgtrainingrate"/>
      </Workbook.Sheet>
     
    </Workbook>
      </FormControl>
    </div>
  </div>
      </div>
      <TableContainer component={Paper} className='root-table-container'>
        <div className='tabular-div-container-root' aria-label="simple table">
          <TableHead className='table-head-container'>
            <TableRow className='table-row-header'>
              <TableCell className={brandName==="BK"?"header restaurantBK":"header restaurant"}>
               
              </TableCell>
              <TableCell className={brandName==="BK"?"header guestHeadBK":"header guestHead"}>
              Guest Satisfaction (ACR)
              </TableCell>
              <TableCell className={brandName==="BK"?"header guestHeadBK":"header guestHead"}>
                Window Time (SOS)
              </TableCell>
              <TableCell className={brandName==="BK"?"header teamHeadBK":"header teamHead"}>
                Avg. Training Rate
              </TableCell>
              <TableCell className={brandName==="BK"?"header teamHeadBK":"header teamHead"}>
                 Turnover Rate
              </TableCell>
              <TableCell className={brandName==="BK"?"header standardHeadBK":"header standardHead"}>
                 Brand Standards
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={ `table-body-container ${ isVisible ? 'open' : 'closed' }` }>
            {filteredRows?.map((row) => (
              <>
                <div key={row.name} className={`tablerow ${ isVisible ? 'open' : 'closed' }` }>
                  <TableCell component="th" scope="row" className='first-column-restaurant-nested'>
                    <Typography className="restaurantName">{row.granularity==="DO"?row.doname:row.arlname}</Typography>
                    <Typography className="restaurantPlace">
                      {row.restadress}
                    </Typography>
                    <div className="starsDiv">
                      <div>
                        <Stars stars={ row.overallstarrating } width={ 200 } height={ 100 }/>
                      </div>
                      <Typography className="starsRating">
                        {row.overallstarrating}/5 stars
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="currentValue">
                      {row.acr+" "}
                      <Delta fill='#c23934'/>
                    </Typography>
                    <Typography className="previousValue">
                      Previous: {row.prevacr}%
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="currentValue">
                      {row.sos+"s "}{" "}
                      <Delta fill='#198737'/>
                    </Typography>
                    <Typography className="previousValue">
                      Previous: {row.prevsos}s
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="currentValue">
                      {row.avgtrainingrate+"% "}{" "}
                      <Delta fill='#c23934'/>
                    </Typography>
                    <Typography className="previousValue">
                      Previous: {row.prevavgtrainingrate}%
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="currentValue">
                      {row.turnoverrate+"% "}{" "}
                      <Delta fill='#198737'/>
                    </Typography>
                    <Typography className="previousValue">
                      Previous: {row.prevturnoverrate}%
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="currentValue">
                      {row.brandstandards+"% "}{" "}
                      <Delta fill='#c23934'/>
                    </Typography>
                    <Typography className="previousValue">
                      Previous: {row.prevbrandstandards}%
                    </Typography>
                  </TableCell>
                  <div className='expansion-cell'>
                  {
                    isVisible && (expandUser === row.doname|| expandUser===row.arlname) ?
                      <RemoveIcon
                        onClick={ ()=>{ setIsVisible(false); setExpandUser(""); } }
                        className="AddIcon"
                      /> :
                      <AddIcon
                      id="expand-button"
                        onClick={ () => { setIsVisible(true); row.granularity==="DO"?setExpandUser(row.doname):setExpandUser(row.arlname) } }
                        className="AddIcon"
                      />
                  }
                  </div>
                </div>
                {isVisible && expandUser === row.doname && row.granularity === 'DO' ? RowRender(row.doname, props.role) : null}
                {isVisible && expandUser === row.arlname && row.granularity === 'ARL' ? RowRender(row.arlname, props.role) : null}
              </>
            ))}
          </TableBody>
        </div>
      </TableContainer>
    </div>
  );
};
const mapStateToProps = (
  dispatch => ({
    userDispatch: dispatch.user
  })
);

export default connect(mapStateToProps)(NestedTable);

import React, { useState } from "react"
import {data} from "./comments.json"
import Pagination from '@material-ui/lab/Pagination';
import { connect } from "react-redux";

//import cssFile from "../../utils/conditionalcss";

const Comments = (props) => {
    console.log(props.userDispatch,"comments")
    require(`${cssFile('index')}`)
    const [page, setPage] = React.useState(1);
    const [pageItems,setPageItems]=useState(data.slice(0,6))
    const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        setPageItems(data.slice((value-1)*6,value*6))
      };
    return (
        <div className="comments">
           
            <div className="comment-header">
                <p className="header-title">Comments</p>
                <div className="header-legend">
                    <p className="header-legend-item">
                        <div className="legend-div escalation"></div>Escalation & Risks
                    </p>
                    <p className="header-legend-item">
                        <div className="legend-div problem"></div>Problems
                    </p>
                    <p className="header-legend-item">
                        <div className="legend-div suggestion"></div>Suggestions & Praises
                    </p>
                </div>
            </div>
            <div className="comments-container">
                {
                    pageItems.map((comment,index)=>(
                        <div className={`comment-card level${comment.level}`} key={index}>
                            <div className="comment-card-title">
                                <p className="comment-date">
                                    {comment.date}
                                </p>
                                <p className='comment-title'>
                                    {comment.title}
                                </p>
                            </div>   
                            <div>
                                <p className='comment-level'>Level{comment.level}-{comment.title}</p>
                            </div> 
                            <div className="comment-description">
                                {comment.description}
                            </div>       
                        </div>   
                    ))
                }
            </div>
            <Pagination variant="text" count={Math.ceil(data.length/6)} page={page}  onChange={handlePage}/>
        </div>
    )
}
const mapStateToProps = (
    dispatch => ({
      userDispatch: dispatch.user
    })
  );
  
const cssFile=(name)=>{
const brand = localStorage.getItem('BrandCode')
console.log(brand)
return `./${name}BK.scss`
//return brand==="PLK" ? `./${name}PLK.scss` : `./${name}BK.scss`
}
  
  export default connect(mapStateToProps)(Comments);
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch } from '../../rematch';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import Odometer from '../Odometer/Odometer';
import Cards from '../Cards/Cards';
import './DashboardNewBK.scss'
import PeriodDropdown from "../BreadCrumb/Dropdown/PeriodDropdown";

const DashboardNew = props => {
  const
    dispatch = useDispatch(),
    { period } = props.periodDispatch,
    { franOrgRoles } = props.userDispatch,
    brandCode = "bk",
    //brandCode = (localStorage.getItem('BrandCode')==="BKC")?"bk":"plk",
    data = {
      metrics: [],
      backendData: { starRating: 0 },
      pieChart: { weights : {"X": 3.5, "F": 2.5, "D": 0.5, "B": 0.8, "A": 1.2}, grading: '' }
    },
    onChange = period => {
      dispatch.metrics.loadMetrics({ period, brandCode });
    }
    ;
  useEffect(() => {
    dispatch.metrics.loadMetrics({ period, brandCode });
  },[period])
  ;
  const
    odometerTitle =  franOrgRoles === 'Multi-Unit Manager' ? 'My Rating' : 'My Score',
    metrics = props.metricsDispatch.details,
    { grading, starRating, weights } = props.metricsDispatch
  ;
  return (
    <>
      <div className='breadcrumb-trail-head'>
        <BreadCrumb
          className='two-cols-layout'
          home='/grading'
          path='/grading'
          trail={ [{ name: period, url: null }] }
          dropDownChildren = {
            <PeriodDropdown
              className='two-cols-layout'
              onChange={ pd => onChange(pd) }
            />
          }
        />
      </div>
      <div className='dashboard-first-line'>
      
        <Odometer
          data={ { starRating, grading, weights, odometerTitle } }
        />

        <Cards
          data={ metrics }
          keyIdentifier='details'
        />
      </div>
    </>
  )
};

const mapStateToProps = (
  state => ({
    metricsState: state.metrics,
    period: state.grades
  }),
  dispatch => ({
    metricsDispatch: dispatch.metrics,
    periodDispatch: dispatch.grades,
    userDispatch: dispatch.user
  })
);

export default connect(mapStateToProps)(DashboardNew);

import React from 'react';

const Circle = ({ className, id, isShown, width, height, fill, r }) => {
  const
      radius = r ? r : '100'
  ;
  return (
    <circle
      className={ className }
      id={ id }
      cx={ width / 2 }
      cy={ height / 2 }
      r={ isShown ? '120' : radius }
      style={{ transition: 'r .4s ease-in-out' }}
      fill={ fill }
    ></circle>
  )
};

export default Circle;
import React, { useState, useEffect } from 'react';
import { drawGaugeChart, updateGaugeMetric } from './D3/helpers';
import './DonutChartBK.scss';

const DonutChart = ({ w, h, data, stars, className, odometerTitle }) => {
  useEffect(() => {
    drawGaugeChart({ data, stars, className, odometerTitle });
  },[])
  ;
  useEffect(() => {
    const { grading } = data;
    updateGaugeMetric({ stars, className, grading });
  },[stars])
  ;
  return (
    <div id='stock' width={ w } height={ h }>
    </div>
  )

};

export default DonutChart;

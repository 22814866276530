import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useDispatch } from '../../rematch';
import { Button, Typography } from '@material-ui/core'
import NestedTable from '../NestedTable/NestedTable';
import RestaurantTable from './index';
import Settings from './Settings';
import {data} from "./tableresponse.json"
import './Table.scss';

const BottomTable = props => {
  const
    dispatch = useDispatch(),
    { period } = props.periodDispatch,
    { rows } = props.tableDispatch,
    brandCode = "bk",
    //brandCode = (localStorage.getItem('BrandCode')==="BKC")?"bk":"plk",
    // rows  = data,
    { franOrgRoles,defaultView } = props.userDispatch,
    [rowsUpdated, setRowsUpdated] = useState(false),
    [viewTable, setViewTable] = useState('restaurants'),
    [rowsLength, setRowsLength] = useState(0)
    ;
  useEffect(() => {
    if (franOrgRoles === 'Multi-Unit Manager') {
      return dispatch.table.loadArlTableRows(period);
    }
    if (franOrgRoles === 'Reporting Administrator') {
      return dispatch.table.loadTableRows({period, brandCode});
    }
  }, [rowsUpdated])
    ;
  useEffect(() => {
    setRowsUpdated(true);
  }, [])
  useEffect(() => {
   if(defaultView) defaultView==="restaurants"?setViewTable("restaurants"):(defaultView==="do"?setViewTable("do"):setViewTable("arl"))
  }, [props])
  ;
  useEffect(() => {
    if (viewTable === 'do') {
      const length = rows.filter(row => row.granularity === "DO").length;
      setRowsLength(length)
    }
    else if (viewTable === 'arl') {
      const length = rows.filter(row => row.granularity === "ARL").length;
      setRowsLength(length)
    }
    else setRowsLength(rows?.rows?.data?.length);
  }, [viewTable, rows])
  ;
  
  return (
    <div className='table-layout-grid'>
      <div className='TableHeadings'>
        <div className='table-header-layout'>
          <div className='text-layout-table-description'>
            <Typography className='restaurantsList'>
              {viewTable === 'restaurants' && rows?.length ? 'My Restaurants' + " (" + rows?.length + ")" : ''}
              {viewTable === 'arl' ? 'ARL ' + " (" + rowsLength + ")" : ''}
              {viewTable === 'do' ? 'DO ' + " (" + rowsLength + ")" : ''}
            </Typography>
            <Typography className='stats'>
              {viewTable === 'arl' ? 'ARL (Above Restaurant Leader)' : ''}
              {viewTable === 'do' ? 'DO (District Operator)' : ''}
           
            </Typography>
          </div>
          <div className='rightDivs'>
            { franOrgRoles === 'Reporting Administrator' ?
              <>
                <Typography className='viewBy'>View By</Typography>
                <Button
                className={viewTable === 'restaurants' ? 'viewButtonsClicked restaurants' : 'viewButtons restaurants'}
                onClick={() => setViewTable('restaurants')}
                >Restaurants
                </Button>
                <Button
                className={viewTable === 'arl' ? 'viewButtonsClicked arl' : 'viewButtons arl'}
                onClick={() => setViewTable('arl')}
                >ARL
                </Button>
                <Button
                className={viewTable === 'do' ? 'viewButtonsClicked do' : 'viewButtons do'}
                onClick={() => { setViewTable('do') }}
                >DO
                </Button>
              </> : <>
            <Typography className='viewBy'>View By</Typography>
              <Button
                className={viewTable === 'restaurants' ? 'viewButtonsClicked restaurants' : 'viewButtons restaurants'}
                onClick={() => setViewTable('restaurants')}
              >View Actions
              </Button>
              <Button
                className={viewTable === 'arl' ? 'viewButtonsClicked arl' : 'viewButtons arl'}
                onClick={() => setViewTable('arl')}
              >All Restaurants
              </Button>
            </>
            }
          </div>
          <Settings />
        </div>
        {viewTable === 'restaurants' && <RestaurantTable rows={rows} />}
        {rowsUpdated && viewTable !== 'restaurants' && <NestedTable NestedTable={rows} role={viewTable} />}
      </div>
    </div>
  )
};

const mapStateToProps = (
  state => ({
    userState: state.user
  }),
  dispatch => ({
    tableDispatch: dispatch.table,
    periodDispatch: dispatch.grades,
    userDispatch: dispatch.user
  })
);

export default connect(mapStateToProps)(BottomTable);

import React from 'react';
import './Danger.scss';
const Danger = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="34.4" height="29" viewBox="0 0 34.4 29" className = "svg-danger"><defs>
                <linearGradient id="warning-a" x1="0.137" y1="0.08" x2="0.828" y2="0.879" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#f44f5a"/><stop offset="0.443" stop-color="#ee3d4a"/><stop offset="1" stop-color="#e52030"/></linearGradient>
                </defs><path class="a" d="M12.273,35.159,4.561,22.365a1.671,1.671,0,0,1,0-1.73L12.273,7.841A1.756,1.756,0,0,1,13.78,7H29.26a1.756,1.756,0,0,1,1.507.841l7.712,12.794a1.671,1.671,0,0,1,0,1.73L30.767,35.159A1.756,1.756,0,0,1,29.26,36H13.78A1.756,1.756,0,0,1,12.273,35.159Z" transform="translate(-4.32 -7)"/>
                <path class="b" d="M23.57,31.512a3.152,3.152,0,0,1-2.18-.787,2.565,2.565,0,0,1-.9-1.972,2.506,2.506,0,0,1,.929-2,3.067,3.067,0,0,1,1.372-.662h-.208A1.452,1.452,0,0,1,21.1,24.722l-.4-10.245a1.382,1.382,0,0,1,.413-1.043A1.5,1.5,0,0,1,22.184,13h2.723a1.5,1.5,0,0,1,1.067.438,1.377,1.377,0,0,1,.411,1.045l-.42,10.242a1.451,1.451,0,0,1-1.479,1.369h-.134a2.972,2.972,0,0,1,1.378.679,2.538,2.538,0,0,1,.9,1.98,2.568,2.568,0,0,1-.884,1.989A3.147,3.147,0,0,1,23.57,31.512Z" transform="translate(-6.357 -7.756)"/>
                <path class="c" d="M23.633,31.138a2.727,2.727,0,0,1-1.882-.672,2.159,2.159,0,0,1-.764-1.662,2.1,2.1,0,0,1,.78-1.677,2.81,2.81,0,0,1,1.865-.633,2.74,2.74,0,0,1,1.866.645,2.13,2.13,0,0,1,.754,1.666,2.167,2.167,0,0,1-.746,1.679A2.714,2.714,0,0,1,23.633,31.138Zm-.988-5.407a1.022,1.022,0,0,1-1.042-.965l-.4-10.225a.975.975,0,0,1,.291-.733,1.054,1.054,0,0,1,.751-.307H24.97a1.051,1.051,0,0,1,.752.308.97.97,0,0,1,.289.735l-.42,10.225a1.022,1.022,0,0,1-1.042.963Z" transform="translate(-6.42 -7.819)"/>
                <path class="d" d="M23.7,33.429a2.23,2.23,0,0,1-1.584-.582,1.856,1.856,0,0,1-.624-1.41,1.8,1.8,0,0,1,.63-1.416A2.306,2.306,0,0,1,23.7,29.47a2.243,2.243,0,0,1,1.571.558,1.825,1.825,0,0,1,.612,1.41,1.865,1.865,0,0,1-.606,1.427A2.224,2.224,0,0,1,23.7,33.429Z" transform="translate(-6.483 -10.377)"/><path class="d" d="M25.669,14.63l-.42,10.641a.606.606,0,0,1-.606.582h-1.9a.606.606,0,0,1-.606-.584l-.4-10.64A.606.606,0,0,1,22.341,14h2.723A.605.605,0,0,1,25.669,14.63Z" transform="translate(-6.514 -8.127)"/>
        </svg>
    )
}
export default Danger;
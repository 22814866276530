import Header from '../Components/Header/index';
import Footer from '../Components/Footer/index';
import GoogleReviews from "./GoogleReview/index";
import UserProvider from '../providers/user'

const GoogleReview = () => {
  return (
    <UserProvider>
      <div>
        <Header />
        <GoogleReviews/>
        <Footer />
      </div>
    </UserProvider>
  );
};
export default GoogleReview;
import { getGrades, getStarsRating, getMetricsStatic } from '../api/';
import { timeParse } from 'd3';

const initialState = {
  data: [{
    grade: "",
    issuedate: "Jul 1st 2021",
    period: "Jan 2021 - Jun 2021",
    status: "Grading in Progress"
  }],
  isLoading: true,
  period: null
};

export const grades = ({
  state: initialState,
  reducers: {
    setGradesList(state, gradesList) {
      return {
        ...state,
        isLoading: false,
        data: gradesList?.sort((a,b) => {
          let monthDate = a.period.split('-')[1],
            monthDate2 = b.period.split('-')[1],
            date = new Date(timeParse(monthDate)),
            date2 = new Date(timeParse(monthDate2))
            ;
          return date - date2
        })
      }
    },
    setStarsRatingList(state, starsRatingList) {
      return {
        ...state,
        loading: false,
        data: starsRatingList?.sort((a,b) => {
          let
            date = new Date(timeParse(a.period)),
            date2 = new Date(timeParse(b.period))
            ;
          return date - date2
        })
      }
    },
    setPeriodInput(state, period) {
      return {
        ...state,
        loading: false,
        period: period
      }
    }
  },
  selectors: {
    definePeriods () {
      return periods => {
        const { data } = periods;
        return data?.map(o => ({
          value: o.period
        }))
      }
   }
  },
  effects: {
    async loadGradesList(state) {
      if (!state.brand) return;
      const { fzCode, brand } = state
      const gradesList = await getGrades(fzCode, brand);
      this.setGradesList(gradesList);
    },
    onChange(period) {
      this.setPeriodInput(period)
    },
    async loadStarsRatingList(fzCode) {
      const starsRatingList = await getStarsRating(fzCode);
      this.setStarsRatingList(starsRatingList);
    }
  }
});

export default grades;

import React from 'react';

const Delta = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20"  viewBox="0 0 23 20">
      <defs>
      </defs>
      <g transform="translate(23 20) rotate(180)" fill={ fill }>
      <path class="a" d="M11.5,0,23,20H0Z" transform="translate(23 20) rotate(180)"/>
    </g>
    </svg>
  )
};

export default Delta;

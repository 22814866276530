import React , { useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { history } from '../../helpers/history';
import { Svg, G } from '../Charts/Elements/SvgElements';
import { Stars } from '../Charts/Elements/Stars';
import { drawStars } from '../Charts/D3/helpers';
import Circle from '../Charts/Elements/Circle';
import Text from '../Charts/Elements/Text';
import useWindowDimensions from "../Charts/Elements/WindowSize";
import debounce from 'lodash/debounce';

export const useDivDimensions = (myRef, delay = 500) => {

  const
    getDimensions = () => ({
      width: myRef.current?.offsetWidth,
      height: myRef.current?.offsetHeight,
    }),
    [dimensions, setDimensions] = useState({ width: 0, height: 0 })
    ;
  useEffect( () => {
    const
        handleResize = () => setDimensions(getDimensions()),
        debounceHandleResize = debounce(handleResize, delay)
    ;
    if (myRef.current) setDimensions(getDimensions());
    window.addEventListener('resize', debounceHandleResize);
    return () => {
      window.removeEventListener('resize', debounceHandleResize);
    }
  }, [myRef, delay]);
  return dimensions;
};

export const Grade = ({
  i,
  grade,
  period,
  status,
  issuedate,
  imageUrl,
  clickEvent,
  className,
  parentPage,
  userRole
}) => {
  console.log("Grade - BrandCode: ", localStorage.getItem('BrandCode'))
  const
    componentRef = useRef(),
    [ resizedWidth, setResizedWidth] = useState(false),
    { width, height } = useDivDimensions(componentRef),
    { windowHeight, windowWidth } = useWindowDimensions(),
    [ isShown, setIsShown ] = useState(false),
    circleProps = { width, height, isShown },
    handleClick = () => history.push(`/dashboard/:${ period }`),
    radius = userRole === 'Reporting Administrator' ? '75' : '120'
  ;
  useEffect( () => {
    setResizedWidth(true);
  },[windowWidth, windowHeight])
  ;
  return (
    <div className='grade' ref= { componentRef } style={{gridColumn: `${i + 2} / ${ i + 3 }`}}>
      <Svg width={ width } height={ height }>
        <G
          className='g-scores'
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          onClick={ clickEvent }
        > {
          (status === 'Completed' ) ?
             userRole === 'Reporting Administrator' ?
              <LetterGrades
                grade={ grade }
                circleProps={ circleProps }
                width={ width }
                height={ height }
                period={ period }
                userRole={ userRole }
              /> :
              <StarsD3
              grade={ grade }
              circleProps={ circleProps }
              width={ width }
              height={ height }
              period={ period }
              userRole={ userRole }
              displayText={ true }
              />
            :
            <>
            <defs>
              <pattern id='image' x='0' y='0' patternUnits='userSpaceOnUse' height='100%' width='100%'>
                { userRole === 'Reporting Administrator' ?
                    <image
                        width={ 100 }
                        height={ 100 }
                        y={ isShown ? (height / 2) - 30  : (height / 2) - 10 }
                        x={ (width / 2) - 55 }
                        style={{ transition: 'y .4s ease-in-out' }}
                        xlinkHref='/assets/holding burger graphic.svg'
                    ></image> :
                    <StarsD3
                        grade={ "0.0" }
                        circleProps={ circleProps }
                        width={ width }
                        height={ height }
                        period={ period }
                        userRole={ userRole }
                        displayText={ false }
                    />
                }
              </pattern>
            </defs>
            <Circle className='circle' id='top' fill='#F3EBDE' {...circleProps} r={ radius }></Circle>
            <Circle className={`circle ${status}`} fill={imageUrl} {...circleProps} r={ radius }></Circle>
            <Text className='text-period text-pending month'
                  width={ width  }
                  height={ height }
                  offsetY={ 150 }
                  content={ period }
                  textAnchor={ 'middle' }
            />
            <Text className='text-period text-pending'
                  split={ resizedWidth }
                  width={ width }
                  height={ height }
                  offsetY={ 250 }
                  textAnchor='middle'
                  content= { `Issue date: ${ issuedate }` }
            />
            </>
        }
        </G>

        <G>

        </G>
        { ( status !== 'Completed') ?
          <Text
            className={ `text-grade-pending ${ className } ${ parentPage }` }
            split={ resizedWidth }
            width={ width }
            height={ height }
            offsetY={ 200 }
            textAnchor='middle'
            content='Grading in Progress...'
          /> : null
        }
      </Svg>

    </div>
  )
};

export const LetterGrades = ({ grade, circleProps, width, height, period }) => {

  return (
    <>
      <Circle className={`circle circle-${ grade }`} { ...circleProps } r='75' />
      <Text className='text-grade'
            width={ width }
            height={ height }
            offsetY={ 35 }
            content={ grade }
            textAnchor='middle'
      />
      <text className='text-period text-active'
            x={ width / 2  }
            y={ height / 2 + 150 }
            textAnchor={ 'middle' }
      >
        <tspan
          x={ width / 2 }
          dy='0'
          textAnchor={ 'middle' }
        >
          { period }
        </tspan>
        {/*<tspan*/}
        {/*x={ width / 2 - 10 }*/}
        {/*dy='1em'*/}
        {/*textAnchor={ 'middle' }*/}
        {/*>*/}
        {/*{ period.split(' - ')[1] }*/}
        {/*</tspan>*/}
      </text>
    </>
  )
};

export const StarsD3 = ({ grade, circleProps, width, height, period, displayText }) => {
    let boo = grade;
    let ratingsArray = Array.from({length: 5}, (_, i) => {
      if(boo >= 1) {
        boo -= Math.floor(boo) / Math.floor(boo);
        return 1;
      }
      else if (boo < 1) {
        try {
          return boo ;
        }
        finally {
          boo -= boo;
        }
      }
    });
  return (
    <>
    <Circle
      className={`circle arl circle-${ grade }`}
      { ...circleProps }
      fill='#F3EBDE'
      isShown={ true }
    />
      {
        ratingsArray.map((o,i) => {
         return <StarsDraw
           o={ o }
           i = { i }
           id={ `stars-${period.replaceAll(' ','')}-${i}`}
           width={ width }
           height={ height }
         />
        })
      }
      <text
        x={ (width / 2 - 50 ) }
        dy={ height / 2 + 45 }
        fill="#484848"
      >
        { displayText ? `${ grade }/5 stars` : '' }
      </text>
    <text className='text-period text-active'
          x={ width / 2  }
          y={ height / 2 + 150 }
          textAnchor={ 'middle' }
    >
      <tspan
        x={ width / 2 }
        dy='0'
        textAnchor={ 'middle' }
      >
        { period }
      </tspan>
      {/*<tspan*/}
      {/*x={ width / 2 - 10 }*/}
      {/*dy='1em'*/}
      {/*textAnchor={ 'middle' }*/}
      {/*>*/}
      {/*{ period.split(' - ')[1] }*/}
      {/*</tspan>*/}
    </text>
    </>
  )
}
;

export const StarsDraw = ({ width, height, id, o, i }) => {
  useEffect(() => {
    drawStars({ id , o , i });
  },[])
  ;
  return (
    <path
      transform={ `translate(${ (width / 2 - 70) + (i * 35) }, ${ height / 2 })`}
      id = { id }
      stroke='#FFC800'
      strokeLinejoin='round'
      strokeWidth='2'
    >
    </path>
  )
}
;

import React, { useRef } from 'react';
import { useDivDimensions } from '../Grades/Grade';
import DonutChart from '../Charts/DonutChart';
import { Stars } from '../Charts/Elements/Stars';
import './OdometerBK.scss';

import {Tooltip,createStyles,makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) =>
  createStyles({
    customWidth: {
      width: "200px",
      backgroundColor: theme.palette.common.white,
      color: 'black',
    }
  }),
);
const tooltips=[
  {
      header:"Letter",
      tooltiptitle:'Letter Grading thresholds:',
     tooltipOptions:["A:>=3.8 stars","B:3.0-3.7 stars","D:2.5-2.9 stars","F:<2.5 stars"]
  }
]
const toolTip=(Header)=>{
  return(
      <div className="tooltip-card">
         {tooltips.map((tooltip)=> <>{tooltip.header===Header&&<><div className="tooltipHead">{tooltip.tooltiptitle}</div>
              {tooltip.tooltipOptions.map((option,index)=><div key={index} className="tooltipOptions">{option}</div>)}</>} </>)}
      </div>
  )
}
const Odometer = ({ data }) => {
  const classes=useStyles();
  const
    { grading, starRating, weights, odometerTitle } = data,
    componentRef = useRef(),
    starRatingRef = useRef(),
    odometerDiv = useDivDimensions(componentRef),
    ratingsDiv = useDivDimensions(starRatingRef)
    ;
  return (
    <div className='odometer-layout'>
      <div className='odometer-title'><p>{ odometerTitle }</p><Tooltip title={toolTip("Letter")} placement="right-start" classes={{tooltip:classes.customWidth}} ><img src="/assets/infoIcon.svg" className="infoIcon"/></Tooltip></div>
      <div className='odometer-graphic'>
        <div className='odometer-svg' ref={ componentRef }>
          <DonutChart
            stars={ starRating }
            data={ { grading, weights } }
            w={ odometerDiv.width }
            h={ odometerDiv.height }
            className='odometer-svg'
            odometerTitle={ odometerTitle }
          />
        </div>
        <div className='odometer-star-rating'>
          { odometerTitle === 'My Score' ?
            <div className='odometer-start-rating-svg-div' ref={ starRatingRef }>
              <div>
                <text className='text-average-star-rating'> { `Average Star Rating: ${ starRating }/5 Stars` }</text>
              </div>
              <Stars stars={ starRating } width={ ratingsDiv.width } height={ ratingsDiv.height }/>
            </div> : null
          }
        </div>
      </div>
    </div>
  )
};

export default Odometer;

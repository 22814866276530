import React from 'react'
import { Modal, Table, TableHead, TableRow, TableCell, TableBody,TableContainer } from '@material-ui/core'
import { data } from "./ModalData.json"
import { useState } from 'react'
import "./indexBK.scss"
//import cssFile from '../../utils/conditionalcss'
import { connect } from 'react-redux'
import { useEffect } from 'react'
//require(`${cssFile('index')}`)

interface ModalProps {
    isOpen: boolean,
    userDispatch?:{brandCode:string},
    closeModal?:()=>void
}
const MaintenanceModal = (props:ModalProps) => {
    require(`${cssFile('index')}`)
    const [open,setOpen]=useState(props.isOpen)
    useEffect(()=>{
        setOpen(props.isOpen)
    },[props.isOpen])
    const handleClose=()=>{
        setOpen(false)
        props.closeModal()
    }
    return (

        <Modal className="Modal" open={open} onClose={handleClose}>
            <div className="Modal-Body">
                <div className="Modal-Head"><p className="Modal-Head-Title">Cleanliness & Maintenance</p>
                <img onClick={handleClose} className="close-icon"src="/assets/close icon.svg"></img>
                </div>
                <TableContainer>
                <Table className="table">
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell >Standard</TableCell>
                            <TableCell >Item (Report)</TableCell>
                            <TableCell >Points Deducted</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((row, index) => <TableRow key={index}>
                                <TableCell>{index+1}</TableCell>
                                <TableCell >{row.standard}</TableCell>
                                <TableCell >{row.standard}</TableCell>
                                <TableCell >{row.pointsDeducted} pt</TableCell>
                            </TableRow>)
                        }
                    </TableBody>
                </Table>
                </TableContainer>
            </div>
        </Modal>

    )
}
const mapStateToProps = (
    dispatch => ({
      userDispatch: dispatch.user
    })
  );

  const cssFile=(name)=>{
    const brand = localStorage.getItem('BrandCode')
    console.log(brand)
    return `./${name}BK.scss`
    //return brand==="PLK" ? `./${name}PLK.scss` : `./${name}BK.scss`
  }
  
export default connect(mapStateToProps)(MaintenanceModal);
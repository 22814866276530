import React, { useState } from 'react'
import "./index.scss"
import {data} from "./EmpOverview.json"
import { Table,TableBody,TableCell,TableHead,TableRow } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination';


const EmployeeTableOverview=()=>{

  const [page, setPage] = React.useState(1);  
  const [pageItems,setPageItems]=useState(data.slice(0,10))
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setPageItems(data.slice((value-1)*10,value*10))
  };
    
    return(
        <div className="emp-table">
            <p className="emp-title">Employees</p>
            <div id="chart"></div>
          
            <Table className="table">
                <TableHead>
                    <TableRow>
                        <TableCell className="emp-table-title left">Restaurant #</TableCell>
                        <TableCell className="emp-table-title left">Role</TableCell>
                        <TableCell className="emp-table-title">Name/ID</TableCell>
                        <TableCell className="emp-table-title">Hire Date</TableCell>
                        <TableCell className="emp-table-title">Exit Date</TableCell>
                        <TableCell className="emp-table-title">Active/Inactive</TableCell>
                        <TableCell className="emp-table-title">Tenure</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
            {pageItems.map((emp,index)=>(
                <TableRow>
                     <TableCell className="emp-cell left">{emp.number}</TableCell>
                    <TableCell className="emp-cell left">{emp.role}</TableCell>
                    <TableCell className="emp-cell">{emp.name}</TableCell>
                    <TableCell className="emp-cell">{emp.hireDate}</TableCell>
                    <TableCell className="emp-cell">{emp.exitDate}</TableCell>
                    <TableCell className="emp-cell">{emp.ActiveStatus}</TableCell>
                    <TableCell className="emp-cell">{emp.tenure}</TableCell>
               
                </TableRow>
            ))}
            </TableBody>
            </Table>
            <Pagination variant="text" count={Math.ceil(data.length/10)} page={page}  onChange={handlePage}/>
        </div>
    )
}

export default EmployeeTableOverview

import React from 'react';

const RestaurantDisplay = ({ gmname, restno, restadress }) => {
    return (
        <div className='restaurant-description'>
            <div className='name-and-restaurant'>
                <div className='gmname'><p> { gmname }</p></div>
                <div className='restno'><p>Restaurant: { restno }</p></div>
            </div>
            <p> Location: { restadress } </p>
        </div>
    )
};

export default RestaurantDisplay;
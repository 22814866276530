import MaintenanceModal from '../../MaintenanceModal'
import './index.scss'
// import StackBarChart from '../../components/stacked-bar-chart'
import {data} from '../../points/points.json'
import PointsDetails from "../../points/index"
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import StackBarChart from '../../Charts/vertical-stacked-bar-chart'
import {stackedBarChartData} from '../../Charts/json/vertical-stacked-bar-chart-for-bs.json'

const BusinessStandardsDrillDowns = (props) => {
    const period = "" //props?.periodDispatch

    const [showModal,setShowModal]=useState(false)
    
     const setModal=()=>{ 
        setShowModal(true)
         }
    const closeModal=()=>{
        setShowModal(false)
    }
         console.log(showModal,"check")
    return (
        <div className="page-container" >
            <div className='breadcrumb-trail-head'>
                <BreadCrumb
                className='two-cols-layout'
                home='/grading'
                path='/grading'
                trail={ [{ name: period, url: null }] }
                />
            </div>
            <h1>Brand Standards</h1>
            <div className="chart-04  flex-row">
                <div className="bg-white flex-col padding-10 margin-20">
                    <h2>Brand Standards Trend</h2>
                    <StackBarChart 
                        data={stackedBarChartData} 
                        keys={[ 'Cleanliness & Maintenance', 'Food quality', 'Guest Service & Experience', 'People' ]} 
                        colors={["#FFAA00", "#408441", "#C6371E", "#4A2518"]}
                        yAxisLegend="Brand Standards %"
                        height={620}/>
                </div>
            </div>
            {/* <h2>Point Details</h2>
            <div className="chart-05 flex-row">
                <div className="bg-white flex-col padding-10 margin-20"><PointsDetails point={data[0]}></PointsDetails></div>
                <div className="bg-white flex-col padding-10 margin-20"><PointsDetails point={data[1]}></PointsDetails></div>
            </div>
            <div className="chart-05 flex-row">
                <div className="bg-white flex-col padding-10 margin-20"><PointsDetails point={data[2]}></PointsDetails></div>
                <div className="bg-white flex-col padding-10 margin-20"><PointsDetails point={data[3]}></PointsDetails></div>
            </div> */}
             <div className="points" >
           <p className="points-title"  >Points Details</p>
            <div className="points-container">
            {data.map((point)=><PointsDetails point={point}  setModal={setModal} ></PointsDetails>)}
           {showModal&& <MaintenanceModal isOpen={showModal} closeModal={closeModal}/>}
            
            </div>
            </div>
        </div>
    )
}

export default BusinessStandardsDrillDowns;

import { TableRow, TableCell } from '@material-ui/core'
import { connect } from "react-redux"
//import cssFile from "../../utils/conditionalcss"
//require(`${cssFile('index')}`)

interface PointsProps{
    point:{
    detail:string,
    period:string,
    category:string,
    type:string,
    standards:
        {
            standard:string,
            points:number
        }[]
    },
    userDispatch?:{brandCode:string},
    setModal:()=>void
}

const PointsDetails=(props:PointsProps)=>{
    require(`${cssFile('index')}`)
    console.log(props.userDispatch?.brandCode,"pointsProps")
    return(
       
                    <div  className="points-card" onClick={props.setModal}>
                        <div className="points-card-div">
                            <img src={`/assets/${props.point.type}.svg`} className="alert-svg"></img>
                        <div className="points-card-container">
                        <p className="points-card-detail">{props.point.detail}</p>
                        <p className="points-card-period">{props.point.period}</p>
                        <p className="points-card-category">{props.point.category} Category</p>
                        <TableRow>
                            <TableCell className="table-cell">
                                Standard
                            </TableCell>
                            <TableCell className="table-cell">
                                Points Deducted
                            </TableCell>
                        </TableRow>
                        {props.point.standards.map((standard)=><TableRow>
                            <TableCell className="table-cell">
                               <li> {standard.standard}</li>
                            </TableCell>
                            <TableCell className="table-cell-points">
                                {standard?.points?`${standard.points} pts`:""}
                            </TableCell>
                        </TableRow>)}
                        </div>
                        </div>

                        <div className='points-card-categories' onClick={props.setModal}>

                        </div>
                    </div>
        //     {/* </div>
        // </div> */}
    )
}
const mapStateToProps = (
    dispatch => ({
      userDispatch: dispatch.user
    })
  );

  const cssFile=(name)=>{
    const brand = localStorage.getItem('BrandCode')
    console.log(brand)
    return `./${name}BK.scss`
    //return brand==="PLK" ? `./${name}PLK.scss` : `./${name}BK.scss`
  }
    
  export default connect(mapStateToProps)(PointsDetails);
import * as d3 from 'd3';
import drawStarPath from './drawStarPath';

const gaugeDonutChart = ({className, stars, data, odometerTitle }) => {
  let boo = stars;
  let ratingsArray = Array.from({length: 5}, (_, i) => {
    if(boo >= 1) {
      boo -= Math.floor(boo) / Math.floor(boo);
      return 1;
    }
    else if (boo < 1) {
      try {
        return boo ;
      }
      finally {
        boo -= boo;
      }
    }
  });

  const chart = selection => {

    selection.each( () => {
      const limits = {
        "X": {
          "value":0,
          "x1": "20%",
          "x2": "100%",
          "y1": "100%",
          "y2": "0%"
        },
        "F": {
          "value":0,
          "x1": "20%",
          "x2": "100%",
          "y1": "100%",
          "y2": "0%"
        },
        "D": {
          "value": 2.5,
          "x1": "52%",
          "x2": "66%",
          "y1": "20%",
          "y2": "30%"
        },
        "B": {
          "value": 3.0,
          "x1":"56%",
          "x2":"70%",
          "y1": "10%",
          "y2": "33%"
        },
        "A": {
          "value": 3.8,
          "x1":"71%",
          "x2":"80%",
          "y1": "33%",
          "y2": "100%"
        },
        "0": {
          "value":0,
          "x1": "10%",
          "x2": "100%",
          "y1": "100%",
          "y2": "0%"
        },
        "1.0": {
          "value": 1.0,
          "x1": "20%",
          "x2": "66%",
          "y1": "80%",
          "y2": "30%"
        },
        "2.0": {
          "value": 2.0,
          "x1":"40%",
          "x2":"70%",
          "y1": "10%",
          "y2": "33%"
        },
        "3.0": {
          "value": 3.0,
          "x1":"60%",
          "x2":"80%",
          "y1": "33%",
          "y2": "100%"
        },
        "4.0": {
          "value": 4.0,
          "x1":"71%",
          "x2":"80%",
          "y1": "33%",
          "y2": "100%"
        },
        "5.0": {
          "value": 5.0,
          "x1":"71%",
          "x2":"80%",
          "y1": "33%",
          "y2": "100%"
        }
      }
      ;
      const createLetterGrade = ({ outerText }) => {

        const chart = selection => {

          if (odometerTitle === 'My Score') {
            selection
              .selectAll('allLabels')
              .data(data_ticks)
              .enter()
              .append('text')
              .text(d =>  d.data[0] )
              .attr('transform', d => `translate(${ outerText.centroid(d) })`)
              .attr('class', d => `donut-label ${ d.data[0]}` )
              .attr('text-anchor', d => {
                let midangle = d.startAngle + (d.endAngle - d.startAngle) / 3.5;
                return (midangle < Math.PI ? 'end' : 'start')
              })
            ;
            svg
              .selectAll('.big-gauge-grade')
              .data([data.grading])
              .enter()
              .append('text')
              .text(d => d)
              .attr('x', 5)
              .attr('dy', 18)
              .attr('class', 'big-gauge-grade')
              .attr('text-anchor', 'middle');
          }

          if (odometerTitle === 'My Rating') {
            selection
              .selectAll('allLabels')
              .data(scorecard_ticks)
              .enter()
              .append('text')
              .text(d =>  d.data[0] )
              .attr('transform', d => `translate(${ outerText.centroid(d) })`)
              .attr('class', d => `donut-label ${ d.data[0]}` )
              .attr('text-anchor', d => {
                let midangle = d.startAngle + (d.endAngle - d.startAngle) / 3.5;
                return (midangle < Math.PI ? 'end' : 'start')
              })
            ;
            svg
              .selectAll('.scorecard-gauge-score')
              .data(['Avg Star Rating:', `${ data.grading }/5 stars`])
              .enter()
              .append('text')
              .text(d => d)
              .attr('x', 5)
              .attr('dy', (d,i) => 26 * (i + 1) - h * .33)
              .attr('text-anchor', 'middle')
            ;
            let gScores = svg
              .append('g')
              .attr('class', 'the-new')
            ;
            gScores
              .selectAll('.scorecard-star-rating')
              .data(ratingsArray)
              .enter()
              .append('path')
              .attr('id', (d,i) => `stars-${ i }`)
              .attr('transform', (d,i) => `translate(${ - (w * .20) + (i * 40) }, ${ 35 - h * .1 })` )
              .each((d,i) => {
                const
                  o = +d,
                  starSize = 400,
                  index = i,
                  id = `stars-${index}`,
                  element = drawStarPath({ o, i, id, starSize })
                ;
                d3.select(`#stars-${ index }`)
                  .call( element )
                  .attr('stroke', '#FFC800')
              })
          }
        };
        return chart;
      }
      ;
      // const eventObj =  {
      //     'mouseover': (event, d) => {
      //       d3.select(event.currentTarget)
      //         .transition()
      //         .duration(1000)
      //         .attr('transform', 'translate(' + (arcOuter.centroid(d)[0] / 5) + ',' + (arcOuter.centroid(d)[1] / 5) + ')');
      //     },
      //
      //     'mouseout': (event, d) => {
      //       d3.select(event.currentTarget)
      //         .transition()
      //         .duration(1000)
      //         .attr('transform', 'translate(0,0)')
      //     }
      //   }
      const
        div = d3.select(`.${ className }`),
        nodeDimensions = div.node().getBoundingClientRect(),
        w = nodeDimensions.width,
        h = nodeDimensions.height,
        scoreFull = 5,
        margin = 10,
        radius = Math.min(w, h) - margin,
        svg = div
          .append('svg')
          .attr('width', w )
          .attr('height', h )
          .append('g')
          .attr('transform', "translate(" + w / 2 + "," + w / 2.2 + ")"),
        color = d3.scaleOrdinal()
          .domain(["1", "2", "3"])
          .range(['black','red','grey']),
        pie = d3.pie()
          .sort((a, b) => a[0] - b[0])
          .value(d => d[1] ),
        data_ticks = pie([['X', .83 ],[0, 3.55],[2.5,0.02],[3.0, .7],[3.8, .8],[5.0, .87]]),
        scorecard_ticks = pie([['X',.70 ],[0,.25],[1,.25],[2,.25],[3.0,.25],[4,.25],[5.0,.25]]),
        // data_test = pie([['X', .75 ],[0, 3.0],[2.5,0.02],[3, .4],[3.8, .8],[5, .8]]),
        data_ready = pie(Object.entries(data.weights)),
        score_ready = pie([["1", 3.5],["2", stars],["3", scoreFull - stars]]),
        arcValues = d3.arc()
          .innerRadius(radius * 0.9)
          .outerRadius(radius * 0.8)
          .startAngle(d => d.startAngle + Math.PI / 1.7 )
          .endAngle(d => d.endAngle + Math.PI/ 1.7 ),
        arcOuter = d3.arc()
          .innerRadius(radius * 0.8)
          .outerRadius(radius * 0.70)
          .startAngle(d => d.startAngle + Math.PI / 1.7 )
          .endAngle(d => d.endAngle + Math.PI/ 1.7 ),
        arcInner = d3.arc()
          .innerRadius(radius * 0.70)
          .outerRadius(radius * 0.65)
          .startAngle(d => d.startAngle + Math.PI / 1.7 )
          .endAngle(d => d.endAngle + Math.PI/ 1.7 ),
        arcText = d3.arc()
          .innerRadius(radius * 0.60)
          .outerRadius(radius * 0.55)
          .startAngle(d => d.startAngle + Math.PI / 1.7 )
          .endAngle(d => d.endAngle + Math.PI/ 1.7 ),
        outerText = d3.arc()
          .innerRadius(radius * .9)
          .outerRadius(radius * .8)
          .startAngle(d => d.startAngle + Math.PI / 1.47 )
          .endAngle(d => d.endAngle + Math.PI / 1.47 )
      ;
      svg
        .selectAll('outerValues')
        .data(data_ready)
        .enter()
        .append('path')
        .attr('d', arcValues )
        .attr('id', (d,i) => `monthArc_${ d.data[0] }`)
        .attr('fill', 'none')
      ;
      svg
        .selectAll('outerSlices')
        .data(score_ready)
        .enter()
        .append('path')
        .attr('d', arcOuter )
        .attr('class', d => `outerSlices segment ${ d.data[0] }` )
      // .on('mouseover', mouseover )
      // .on('mouseout', mouseout )
      ;
      svg
        .selectAll('innerSlices')
        .data(data_ready)
        .enter()
        .append('path')
        .attr('d', arcInner )
        .attr('class', d => `innerSlices segment ${ d.data[0] }` )
        .attr('fill', d => color(d.data[0]) )
        // .on('mouseover', mouseover )
        // .on('mouseout', mouseout )
        .attr('stroke', 'white')
        .style('stroke-width', '2px' )
      ;
      svg
        .selectAll('allLabels')
        .data(data_ready)
        .enter()
        .append('text')
        .text(d =>  d.data[0] )
        .attr('transform', d => `translate(${ arcText.centroid(d) })`)
        .attr('class', d => `donut-label ${ d.data[0]}` )
        .attr('text-anchor', d => {
          let midangle = d.startAngle + (d.endAngle - d.startAngle) / 3.5;
          return (midangle < Math.PI ? 'end' : 'start')
        })
      ;
      svg
        .call(createLetterGrade({ outerText }))
      ;
      // svg
      //   .selectAll('numLabels')
      //   .data(data_ready)
      //   .enter()
      //   .append('text')
      //   .attr('x', 5)
      //   .attr('dy', 18)
      //   .append('textPath')
      //   .attr('href', d => `#monthArc_${ d.data[0] }`)
      //   .text(d => limits[d.data[0]]['value'] )
      // ;
      ;
      let gradient = svg
          .selectAll('defsGradient')
          .data(data_ready)
          .enter()
          .append('defs')
          .append('linearGradient')
          .attr('id', d => `gradient-${ d.data[0] }`)
          .attr('x1', d => limits[d.data[0]]['x1'] )
          .attr('y1', d => limits[d.data[0]]['y1'] )
          .attr('x2', d => limits[d.data[0]]['x2'] )
          .attr('y2', d => limits[d.data[0]]['y2'] )
        // .attr('gradientUnits', 'userSpaceOnUse')
        ;
      gradient
        .append('stop')
        .attr('class', d => `initialStop-${ d.data[0] }`)
        .attr('offset', 0 )
      ;
      gradient
        .append('stop')
        .attr('class', d => `endingStop-${ d.data[0] }`)
        .attr('offset', 1 )
      ;
      let gradientOuterRing = svg
          .selectAll('defsGradientOuterRing')
          .data(data_ready)
          .enter()
          .append('defs')
          .append('linearGradient')
          .attr('id', d => `gradient-outer-ring-rating-fill`)
          .attr('x1', '0%' )
          .attr('y1', '0%' )
          .attr('x2', '100%' )
          .attr('y2', '0%' )
        // .attr('gradientUnits', 'userSpaceOnUse')
        ;
      // gradient
      //   .append('stop')
      //   .attr('class', d => `initialStop-${ d.data[0] }`)
      //   .attr('offset', 0 )
      // ;
      // gradient
      //   .append('stop')
      //   .attr('class', d => `endingStop-${ d.data[0] }`)
      //   .attr('offset', 1 )
      gradientOuterRing.selectAll('stop')
        .data([
          {offset: '0%', color: '#C73820'},
          {offset: '12.5%', color: '#C6371E'},
          {offset: '25%', color: '#CB4425'},
          {offset: '37.5%', color: '#D04E2B'},
          {offset: '50%', color: '#D86234'},
          {offset: '62.5%', color: '#E17D44'},
          {offset: '75%', color: '#00B3A1'},
          {offset: '87.5%', color: '#00B383'},
          {offset: '100%', color: '#00B379'}
        ])
        .enter().append('stop')
        .attr('offset', d => { return d.offset; })
        .attr('stop-color', d => { return d.color; })
      ;
    })
  }
  ;
  return chart;
};

export default gaugeDonutChart;

import React from 'react';
import './Success.scss';
const Success = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="34" height="34" viewBox="0 0 34 34" className ="svg-success"><defs>
            <clipPath id="success-a"><path d="M0-47.5H34v34H0Z" transform="translate(0 47.5)"/></clipPath>
            </defs><g class="a"><g transform="translate(0.895 0.895)">
            <path class="b" d="M-12.789-11.368a3.579,3.579,0,0,1-3.579,3.579H-41.421A3.579,3.579,0,0,1-45-11.368V-36.421A3.579,3.579,0,0,1-41.421-40h25.053a3.579,3.579,0,0,1,3.579,3.579Z" transform="translate(45 40)"/>
            </g><g transform="translate(7.158 7.158)"><path class="c" d="M-5.329-7.816v-8.053H.934L-8.908-27.5-18.75-15.868h6.263v8.053Z" transform="translate(18.75 27.5)"/></g></g>
        </svg>
    )

}

export default Success
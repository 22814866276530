import * as d3 from 'd3';

const horizontalBarChart = ( { id, row, xDomain, tickValues, axisTicksForBar }) => {
  let
    margin = {top: 20, right: 20, bottom: 20, left: 20}
  ;
  const chart = selection => {

    selection.each( data => {
      let
        div = d3.select(`#${ id }-${ row }`),
        nodeDimensions = d3.select(`#${ id }-${ row }`).node().getBoundingClientRect(),
        width = nodeDimensions.width - (margin.right + margin.left),
        height = nodeDimensions.height - (margin.top + margin.bottom),
        circleRadius = 12
      ;
      [data].forEach(d => {
        d.textFormat = + d.value < 100 ? '%' : id === 'windowtimesos' ? 's' : '';
        d.value =  +d.value < 1 ? +d.value * 100 : +d.value;
      })
      ;
      let svg = div
        .append('svg')
        .attr('width', width + margin.left + margin.right )
        .attr('height', height + margin.top + margin.bottom )
        .append('g')
        .attr('transform', `translate(${ margin.left },${ margin.top })`)
      ;
      svg.selectAll('barTitle')
        .data([data])
        .enter()
        .append('text')
        .attr('class', 'barTitle')
        .text( d => d.title )
        .attr('transform', 'translate(0,-5)')
      ;
      let xScale = d3.scaleLinear()
        .domain(xDomain)
        .range([0, width])
      ;
      let yScale =  d3.scaleBand()
        .range([0, height])
        .domain([data].map(d => d.title ))
      ;
      let mask =
      svg.append('defs')
        .append('mask')
        .attr('id', `circle-clip-${ id }-${ row }`)
      ;
      mask
        .append('rect')
        .attr('class', 'mask-rect-datapoint')
        .attr('width', d => {
          if (+d.value > xDomain[1]) {
            return xScale(xDomain[1]) - circleRadius * 2;
          }
          if (+d.value < xDomain[0]) {
            return xScale(xDomain[0]) - circleRadius * 2;
          }
          return xScale(+d.value) - circleRadius * 2
        } )
        .attr('height', circleRadius * 2 )
        .attr('x', d => xScale(xDomain[0]) + circleRadius )
        .attr('y', d => yScale(d.title) )
        .attr('fill', 'white')
      ;
      mask
        .append('circle')
        .attr('class', 'mask-circle-datapoint')
        .attr('fill', 'white')
        .attr('cx', d => {
          if (+d.value > xDomain[1]) {
            return xScale(xDomain[1]);
          }
          if (+d.value < xDomain[0]) {
            return xScale(xDomain[0]);
          }
          return xScale(+d.value);
        } )
        .attr('cy', d => yScale(d.title) )
        .attr('r', circleRadius )
        .attr('transform', `translate(${ -circleRadius }, ${ circleRadius })`)
      ;
      mask
        .append('circle')
        .attr('fill', 'white')
        .attr('cx', d => xScale(xDomain[0]) )
        .attr('cy', d => yScale(d.title) )
        .attr('r', circleRadius )
        .attr('transform', `translate(${ circleRadius }, ${ circleRadius })`)
      ;
      let backgroundMask =
      svg.append('defs')
        .append('mask')
        .attr('id', `background-clip-${ id }-${ row }`)
      ;
      backgroundMask
        .append('rect')
        .attr('width', d => xScale(xDomain[1]) - circleRadius * 2 )
        .attr('height', circleRadius * 2 )
        .attr('x', d => xScale(xDomain[0]) + circleRadius )
        .attr('y', d => yScale(d.title) )
        .attr('fill', 'white')
      ;
      backgroundMask
        .append('circle')
        .attr('fill', 'white')
        .attr('cx', d => xScale(xDomain[1]) )
        .attr('cy', d => yScale(d.title) )
        .attr('r', circleRadius )
        .attr('transform', `translate(${ -circleRadius }, ${ circleRadius })`)
      ;
      backgroundMask
        .append('circle')
        .attr('fill', 'white')
        .attr('cx', d => xScale(xDomain[0]) )
        .attr('cy', d => yScale(d.title) )
        .attr('r', circleRadius )
        .attr('transform', `translate(${ circleRadius }, ${ circleRadius })`)
      ;
      svg.selectAll('backgroundRect')
        .data([data])
        .enter()
        .append('rect')
        .attr('id', `background-clip-${ id }-${ row }` )
        .attr('x', xScale(xDomain[0]) )
        .attr('y', d => yScale(d.title) )
        .attr('mask', `url(#background-clip-${ id }-${ row })` )
        .attr('width', d => xScale(xDomain[1]) )
        .attr('height', circleRadius * 2 )
      ;
      svg.selectAll('myRect')
        .data([data])
        .enter()
        .append('rect')
        .attr('id', `circle-clip-${ id }` )
        .attr('x', xScale(xDomain[0]) )
        .attr('y', d => yScale(d.title) )
        .attr('mask', `url(#circle-clip-${ id }-${ row })` )
        .attr('width', d => {
          if (+d.value > xDomain[1]) {
            return xScale(xDomain[1]);
          }
          if (+d.value < xDomain[0]) {
            return xScale(xDomain[0]);
          }
          return xScale(+d.value)
        } )
        .attr('height', circleRadius * 2 )
      ;
      svg.selectAll('myVal')
        .data([data])
        .enter()
        .append('text')
        .attr('x', d => {
          if (+d.value > xDomain[1]) {
            return xScale(xDomain[1]);
          }
          if (+d.value < xDomain[0]) {
            return xScale(xDomain[0]);
          }
          return xScale(d.value) - circleRadius;
        } )
        .attr('y', d => yScale(d.title) + circleRadius )
        .attr('text-anchor', 'end')
        .attr('class', 'innerText-value')
        .text(d => `${ d.value }${ d.textFormat }` )
        .attr('transform', `translate(3,5)` )
      ;
      svg
        .call(axisTicksForBar({ xDomain, width, height, tickValues, circleRadius, id }))
      ;
    });

  };

  return chart;
};

export default horizontalBarChart;

import { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Svg } from "../../Components/Charts/Elements/SvgElements"
import useWindowDimensions from '../../Components/Charts/Elements/WindowSize';
import { Grade, useDivDimensions } from '../../Components/Grades/Grade';
import Text from '../../Components/Charts/Elements/Text';
import { history } from '../../helpers/history';
//import cssFile from '../../utils/conditionalcss'
import { BK } from './textPrompts.json';
import { PLK } from "./textPrompts.json";

//console.log(cssFile('Welcome'), 'file name', localStorage.getItem('BrandCode'))
const Welcome = props => {
  //console.log("Welcome Page - ", props)
  console.log("Welcome BrandCode: ", localStorage.getItem('BrandCode'))
  //var brand = localStorage.getItem('BrandCode') === 'BKC' ? BK : PLK
  var brand = BK

  const
    { dispatch, gradesDispatch, userDispatch } = props,
    { brandCode } = userDispatch,
    { data } = gradesDispatch,
    { franOrgRoles, fzCode } = props.userDispatch,
    { animations } = brand,
    { givenName, familyName } = props.userDispatch,
    [display, setDisplay] = useState(0),
    { windowWidth, windowHeight } = useWindowDimensions(),
    containerHeight = windowHeight / 2,
    heightOffset = 66,//TODO: make this variable dynamic
    onClick = period => {
      dispatch.grades.onChange(period);
      history.push('/dashboard');
    }
    console.log("DATA::::::::::::::::::::::::: ", data)
    console.log("franOrgRoles::::::::::::: ", franOrgRoles)
  ;
  // require(`./ ${ branding[brandCode].styles.welcome }`)
  ;

  useEffect(() => {
    require(`${cssFile('Welcome')}`);
  }, [])

  useEffect(() => {
    console.log("data length:::::::::::: ", data.length)
    if( data.length <= 1 && gradesDispatch.isLoading === false) {
      for(let i=1; i <= animations.length; i++) {
        ((j) => {
          setTimeout(() => {
            if (j === animations.length) { setDisplay(0); history.push('/grading') }
            setDisplay(display => display + 1);
          }, j * 4000);
        })(i);
      }
    } else return;
  },[data, gradesDispatch.isLoading])
;
  const
    componentRef = useRef(),
    gridTemplateRows = `repeat(2,${ containerHeight  - heightOffset }px)`, //remove the header and footer heights
    { width, height } = useDivDimensions(componentRef)
  ;
  return ( <>
    <div className='extended-text-container'
         style={{ gridTemplateRows: gridTemplateRows }}
         ref={ componentRef }
    >
      <Svg
        className={`svg background ${props.userDispatch.brandCode}`}
        width={ windowWidth }
        height={ height } >
        <Text className={ 'text header-greeting' }
              height={ 0 }
              width={ windowWidth * .10 }
              offsetX={ 100 }
              offsetY={ 70 }
              textAnchor='left'
              content={ `Hi ${ givenName } ${ familyName }!` }
        />
         <rect x={0} y={ containerHeight - heightOffset } height={ height } width={ width } fill='#FFFFFF'></rect>
        {
          animations.map((o,i) => {
            return <Text className={ display === i ? "text header-greeting"  : "text header-greeting not-visible" }
               id={ o.image.className }
               height={ 0 }
               width={ windowWidth * .10 }
               offsetX={ 10 + (o.offset ? o.offset.x : 0) }
               offsetY={ 70 + (o.offset ? o.offset.y : 0) }
               textAnchor='left'
               content={ o.text }
            />
          })
        }
        <Text className={ display === 1 ? "text header-greeting"  : "text header-greeting not-visible" }
              height={ 0 }
              width={ windowWidth * .10 }
              offsetX={ 10 + 90 }
              offsetY={ 70 + 70 }
              textAnchor='left'
              content= "Welcome to your Analytics Dashboard."
              id="dashboard"
        />
        <Text className={ display === 3 ? "text header-greeting"  : "text header-greeting not-visible" }
              height={ 0 }
              width={ windowWidth * .10 }
              offsetX={ 10 + 500 }
              offsetY={ 70 + 310 }
              textAnchor='left'
              content= "This is your scorecard timeline."
              id="timeline"
        />
        {
          animations.map(o => {
            const
              { image } = o,
              { displayProps } = image,
              { offset } = displayProps
            ;
            return <image
              className={ image.className }
              width={ image.svg.width }
              height={ image.svg.height }
              y={ display > displayProps.min && display < displayProps.max ? image.y : offset.y }
              x={ display > displayProps.min && display < displayProps.max ? image.x : offset.x }
              style={{ transition: 'all 1s ease-in-out' }}
              xlinkHref={ `/assets/${ image.svg }.svg` }
            />
          })
        }
      </Svg>
      <div className='container' style={{ gridTemplateRows: gridTemplateRows }}>
        { data.map((o,i) => (
          <Grade
            userRole={ franOrgRoles }
            className='welcome-page'
            key={ i }
            i={ i } { ...o }
            imageUrl='F3EBDE'
            clickEvent={ () => onClick(o.period) }
            parentPage='no-data'
          />))
        }
      </div>
    </div>
    </>
  )
};

const mapStateToProps = () => (
  state => ({
    userState: state.user
  }),
  dispatch => ({
    userDispatch: dispatch.user,
    gradesDispatch: dispatch.grades
  })
);

const cssFile=(name)=>{
  const brand = localStorage.getItem('BrandCode')
  console.log(brand)
  return `./${name}BK.scss`
  //return brand==="PLK" ? `./${name}PLK.scss` : `./${name}BK.scss`
}


export default connect(mapStateToProps)(Welcome);

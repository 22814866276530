import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Landing from '../Components/Landing/Landing';
import DashboardNew from '../Components/Dashboard/DashboardNew';
import BottomTable from '../Components/Table/Table';
import UserProvider from '../providers/user';
import GradesProvider from "../providers/grades";

const Dashboard= () => {
  return (
      <UserProvider>
          <GradesProvider>
            <div>
              <Header />
              <Landing
                content=''
                className='dashboard-layout'
                marginOffset={ 4 }
                fiftyScreen={ false }
                layoutClassName='no-space'
                topChild={ <DashboardNew/> }
                bottomChild={ <BottomTable/> }
              />
              <Footer />
            </div>
      </GradesProvider>
      </UserProvider>
  );
};
export default Dashboard;
import React, { useEffect } from "react";
import * as OktaSignIn from "@okta/okta-signin-widget";
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";

import config from "../config";

const LoginPage = () => {
  const { authState, authService } = useOktaAuth();
  process.env['CLIENT_ID'] = 'clientid'
  console.log(process.env)
  const onSuccess = (data: any) => {
    if (authState.isPending) {
      // onSuccess(data)
      console.log(authState)
    } else {
      console.log('-------------- user info' + JSON.stringify(data))
    }
  }
  const onError = (err: any) => console.log('---------------- error while user sign in', err)

  // use effect to show login screen
  useEffect(() => {
    if (authState.isAuthenticated) return
    const { pkce, issuer, clientId, redirectUri, scopes } = config.oidc;
    const widget = new OktaSignIn({
      baseUrl: issuer.split('/oauth2')[0],
      clientId,
      redirectUri,
      logo: '/react.svg',
      i18n: {
        en: {
          'primaryauth.title': 'Sign in to Fz Success Dashboard',
        },
      },
      authParams: {
        pkce,
        issuer,
        display: 'page',
        responseMode: pkce ? 'query' : 'fragment',
        scopes,
      },
    });

    widget.renderEl({ el: '#sign-in-widget' }, onSuccess, onError);
  }, [authState, authService]);

  return authState.isAuthenticated ?
    <Redirect to={{ pathname: '/set-user' }} /> :
    <div>
      <div id="sign-in-widget" />
    </div>
};

export default LoginPage;
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, FormControl, Typography } from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { store } from "../../../rematch/store";

const PeriodDropdown = props => {
    const
        { className, onChange, gradesDispatch } = props,
        { period } = gradesDispatch,
        [ cancelClicked, setCancelClicked ] = useState(true),
        [ utilClicked, setUtilClicked ] = useState(""),
        [ timePeriod, setTimePeriod ] = useState(null),
        periods = store.select.grades.definePeriods(gradesDispatch),
        changeTimePeriod = value => {
            setTimePeriod(value);
            setCancelClicked(true);
            onChange(value);
        }
    ;
    useEffect(() => {
        setTimePeriod(period);
    },[period, props])
    ;
    return (
        <div className= { `site-navigation-time-period-text ${ className }` }>
            {timePeriod&&<p>Time Period</p>}
            {
                timePeriod&&<div className="filtered filter-by-container dropdown-right-box">
                    <div className="filteredHeadings"
                         onClick={ () => {
                             setCancelClicked(!cancelClicked);
                             setUtilClicked("filter")
                         }}>
                        <Typography>
                            { timePeriod }
                        </Typography>
                        { !cancelClicked && utilClicked === "filter" ?
                            <ExpandLess onClick={ () => setCancelClicked(true) }/> :
                            <ExpandMoreIcon
                                id="filter-button"
                                onClick={ () => {
                                    setCancelClicked(false);
                                    setUtilClicked("filter")
                                }}
                            />
                        }
                    </div>
                    <div id="filter-div"
                         onMouseLeave={ () => setCancelClicked(true) }
                         className={ !cancelClicked && utilClicked === "filter" ?
                             "filteredHovered":"filteredHoveredCancel"
                         }
                    >
                        <FormControl variant="outlined" >
                            { periods?.map(({value}, index) => (
                                <Button
                                    onClick={ ()=> changeTimePeriod(value) }
                                >
                                    { value }
                                </Button>
                            ))}
                        </FormControl>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (
    dispatch => ({
        gradesDispatch: dispatch.grades
    })
);

export default connect(mapStateToProps)(PeriodDropdown);
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import DrilldownComponent from '../Components/Drilldowns/'

const ScoreCard = (props) => {
    const drillDownName = props.match.params.name ? props.match.params.name : 'gs'
    console.log('Drilldown name ----------- '+ drillDownName)

    return (
        <div>
            <Header />
            <DrilldownComponent name={drillDownName}/>
            <Footer />
        </div>
    );
};
export default ScoreCard;
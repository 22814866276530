import './index.css'
import StackBarChart from '../../Charts/vertical-stacked-bar-chart'
import {stackedBarChartData} from '../../Charts/json/vertical-stacked-bar-chart.json'
import LineChart from '../../Charts/line-chart-with-scatter'
import {lineChartData} from '../../Charts/json/line-chart-with-scatter.json'
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import BarChart from '../../Charts/D3/barChartNew'
import BarChartData from '../../../assets/json/smg-complaints-data.json'

const GuestSatisfactionDrillDowns = (props) => {
    const period = "" //props?.periodDispatch

    return (
        <div className="page-container">
            <div className='breadcrumb-trail-head'>
                <BreadCrumb
                className='two-cols-layout'
                home='/grading'
                path='/grading'
                trail={ [{ name: period, url: null }] }
                />
            </div>

            <div><h1>Guest Satisfaction (ACR)</h1></div>
            <div className="  flex-row">
                <div className="chart-08 bg-white flex-col padding-10 margin-20">
                    <h2>ACR Overall Performance</h2>
                    <StackBarChart 
                        data={stackedBarChartData} 
                        keys={[ 'SMG Surveys', 'Contact Center', 'Google Reviews' ]} 
                        colors={["#247BA0", "#C6371E", "#408441"]}
                        yAxisLegend=""
                        height={620}/>
                </div>
            </div>
            <div className="chart-09 flex-row">
                <div className="bg-white flex-col padding-10 margin-20">
                    <a href="/drilldown/smg"><h2>SMG Complaints</h2></a>
                    <BarChart  w={400} linedata={[]} bardata= {BarChartData} w1={400} keyT={"Attainment"} BSB = {"GB"} id = {"GB"}/>
                </div>
                <div className="bg-white flex-col padding-10 margin-20">
                    <a href="/drilldown/cc"><h2>Contact Center Complaints</h2></a>
                    {/* <BarChart  w={400} linedata={[]} bardata= {BarChartData} w1={400} keyT={"Attainment"} BSB = {"GB"} id = {"GB"}/> */}
                </div>
                <div className="bg-white flex-col padding-10 margin-20">
                    <a href="/googlereview/all"><h2>Google Review Complaints</h2></a>
                    {/* <BarChart  w={400} linedata={[]} bardata= {BarChartData} w1={400} keyT={"Attainment"} BSB = {"GB"} id = {"GB"}/> */}
                </div>
            </div>
            <div className="flex-row">
                <div className="chart-10 bg-white flex-col padding-10 margin-20">
                    <h2>SMG Complaints Percentage</h2>
                    <LineChart data={lineChartData} height={420}/>
                </div>
                <div className="chart-10 bg-white flex-col padding-10 margin-20">
                    <h2>Contact Center Percentage</h2>
                    <LineChart data={lineChartData} height={420}/>
                </div>
                <div className="chart-10 bg-white flex-col padding-10 margin-20">
                    <h2>Google Review Percentage</h2>
                    <LineChart data={lineChartData} height={420}/>
                </div>
            </div>
            <br></br>
        </div>
    )
}

export default GuestSatisfactionDrillDowns;
import { getRestaurantAPI, getRestaurantStatic } from '../api';
const weights = {
  "X": .63,
  "0": .25,
  "1.0": .25,
  "2.0": .25,
  "3.0": .25,
  "4.0": .25,
  "5.0": .25
};

const initialState = {
  isLoading: true,
  grading: '0',
  starRating: '0',
  weights: weights,
  fzcode: 0,
  gmname: "",
  restno: 0,
  period: "Jan 2019",
  restadress: "",
  sos: "",
  acr: "",
  avgtrainingrate: "",
  turnoverrate: "",
  brandstandards: "",
  sosstarrating: "",
  acrstarrating: "",
  trainingstarrating: "",
  turnoverstarrating: "",
  brandstarrating: "",
  overallstarrating: "0",
  brandstandardsfranchiseaverage: "",
  brandstandardstopperformer: "",
  turnoverfranchiseaverage: "",
  turnovertopperformer: "",
  trainingfranchiseaverage: "",
  trainingtopperformer: "",
  sosfranchiseaverage: "",
  sostopperformer: "",
  acrfranchiseaverage: "",
  acrtopperformer: ""
};

export const restaurant = ({
  state: initialState,
  reducers: {
    setRestaurantView(state, payload) {
      return {
        ...state,
        isLoading: false,
        ...payload,
        weights
      }
    },
    setRestaurantNumberInput(state, { fzCode, restNo }) {
      return {
        ...state,
        loading: false,
        restNo: restNo,
        fzCode: fzCode
      }
    },
    setInitialState() {
      return {
        ...initialState,
        isLoading: true,
        weights
      }
    },
    setMonthInput(state, month) {
      return {
        ...state,
        loading: false,
        month: month
      }
    }
  },
  effects: {
    async loadRestaurantView(params) {
      const
        { brandCode, period, restNo } = params,
        month = period?.includes('-') ? period.split(' - ')[1] : period,
        restaurantObject = await getRestaurantAPI(month, brandCode, restNo)
      ;
      this.setRestaurantView(restaurantObject);
      this.setMonthInput(month);
    },
    onChange({ fzCode, restNo }) {
      this.setRestaurantNumberInput({ fzCode, restNo });
    },
    // onChangeOfMonth(period) {
    //   const month = period?.includes('-') ? period.split(' - ')[1] : period;
    //   this.setMonthInput(month);
    // },
    onInitialState() {
      this.setInitialState();
    }
  }
});

export default restaurant;
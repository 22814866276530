import { useEffect } from "react"
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from "react-router-dom";
const SetUserData = (authService) => {
  console.log("SetUserData>>> ");
  authService.getUser()
    .then(data => {
      console.log('data', data);
      localStorage.setItem('Region', data.FranCountry)
      localStorage.setItem('BrandCode', data.BrandCode)
      localStorage.setItem('FzCode', data.franOrganizationId)
      localStorage.setItem('Role', data.franOrgRoles)
      let user = JSON.parse(localStorage.getItem('user'))
      if (!user || user.userId !== data.UserID) {
        user = { userId: data.UserID, isFirstTimeUser: true, preference: 'restaurants' }
      } else {
        user = { userId: data.UserID, isFirstTimeUser: false, preference: user.preference }
      }
      localStorage.setItem('user', JSON.stringify(user))
    })
    .catch(error => console.log(error))
}

const SetUserInfo = (props) => {
  const { authService } = useOktaAuth();
  let history = useHistory();
  useEffect(() => {
    SetUserData(authService)
  }, [])
  console.log('============================== '+localStorage.getItem('BrandCode'))
  setTimeout(() => {
    history.push("/");
  }, 3000);

  
  return (
    <div>
      <h1>Setting user preferences..</h1>
    </div>
  )
}


export { SetUserInfo }
import * as d3 from 'd3';
import './linechart.scss'
const lineChart = props => {

  const
      { monthly, weekly, line1 } = props,
      xaxis = line1[0].xaxis.split('|'),
      monthlyData = line1,
      { avgrating } = line1[0],
      margins = { top: 20, right: 20, bottom: 20, left: 50 }
  ;
  const chart = selection => {

    selection.each(data => {
      var margin = 40;
      var duration = 250;
      const nodeDimensions = selection.node().getBoundingClientRect();
      const parentWidth = 550;
      const width = nodeDimensions.width - margins.left - margins.right;
      const height = nodeDimensions.height - margins.top - margins.bottom;
      monthly.forEach(function(d) {
        d.name = d.name;
        d.value = d.value;
      });
           /* Scale */
      
      var xScale = d3
        .scalePoint()
        .domain(xaxis.map(d=> d.substring(0,3)))
        // .domain(monthlyData.map(d => d.period))
        .range([0, width ])
        .padding(1);

        var parseTime = d3.timeParse("%b");
        console.log(xaxis.map(d=> parseTime(d.substring(0,3))))   

      var xScale2 = d3
      .scalePoint()
      .domain(monthlyData.map(d => (d.period)))
      .range([0, width ])
      .padding(1);

      var xScale1 = d3
        .scalePoint()
        .domain(monthlyData.map(d => d.period))
        .range([0, width -30]).padding(0.1);

      var yScale = d3
        .scaleLinear()
        .domain([0, 5])
        .range([height - margin, 0]);

      /* Add SVG */
      var svg = selection
        .append("svg")
        .attr("width", width + margin + "px")
        .attr("height", height + margin + "px")
        .append("g")
        .attr("transform", `translate(${margins.left}, ${margins.top})`);

      /* Add line into SVG */

      var line = d3
        .line()
        // .defined(d => !isNaN(d.overallstarrating))
        .x(d => xScale2(d.period))
        .y(d => yScale(d.overallstarrating));
      //.curve(curveLinear);

      var line1 = d3
        .line()
        .defined(d => !isNaN(d.avgrating))
        .x(d => xScale1(d.period))
        .y(d => yScale(d.avgrating));

      const initialData = monthly.map(d => ({
        name: d.name,
        value: 0
      }));
      const t = d3.transition().duration(1000);
      let lines = svg
        .append("path").attr("class", "lines").datum(monthlyData)
        //.transition(t).on()
        // .attr("transform", `translate(-15, 0)`)
        .attr('id', 'line')
        .attr('stroke', 'red')
        .attr('stroke-width', 2)
        .attr('fill', 'none')
        .attr('d', line);

      let lines1 = svg.append("path").attr("class", "lines").datum(monthlyData)
        .attr('id', 'line')
        .attr("stroke-miterlimit", 1)
        .style("stroke-dasharray", ("3, 3"))
        .attr('stroke', 'gray')
        .attr('stroke-width', 1.5)
        .attr('fill', 'none')
        .attr('d', line1) ;

      //svg.append('text').attr("dy", ".2em").text("Close");

      var xAxis = d3.axisBottom(xScale).ticks(5).tickSize(0,0,0);
      var yAxis = d3.axisLeft(yScale).ticks(5).tickSize(-width, 0 ,0).tickFormat(function(ytickvalues) {
        if(ytickvalues > 1){
          return  ytickvalues + ' stars';
        } else if (ytickvalues === 1) {
          return  ytickvalues + ' star';
        } else {
          return  ytickvalues;
        };
      });

      svg
        .append("g")
        .attr("class", "xaxis")
        .attr("transform", `translate(0, ${height - margin})`)
        .call(xAxis);





      svg
        .append("g")
        .attr("class", "yaxis")
        .call(yAxis).call(g => g.selectAll(".tick:not(:first-of-type) line")
        .attr("class", "axis_tick")
        .attr("stroke", "rgb(235, 233, 233)"))
        .select(".domain").remove()
        .append("text")
        .attr("y", 15)
        .attr("transform", "rotate(-90)")
        .attr("fill", "#000")

        if(avgrating !== "") {
        svg.append("text")
        .attr("transform", "translate(" + (width-100) + ","+ yScale(monthlyData[0].avgrating)+")")
        .attr("class", "tooltip-line")
        .attr("dy", ".35em")
        .attr("text-anchor", "start")
        .style("fill", "#484848")
        .text(
          'Avg. '+ avgrating 
          );
        }


      let states, tipBox;
      // const tooltip = d3.select('#tooltip');
        const tooltip = d3.select("body").append("div")
        .attr("class", "tooltip")
         .style("left", "50%")
         .style("top", "100%")
         .style("margin-left","-5px")
         .style("position", "absolute");
      const tooltipLine = svg.append('line');
      tipBox = svg.append('rect')
        .attr('width', width)
        .attr('height', height)
        .attr('opacity', 0)
        .on('mousemove', drawTooltip)
        .on('mouseout', removeTooltip);

      function removeTooltip() {
        if (tooltip) tooltip.style('display', 'none');
        if (tooltipLine) tooltipLine.attr('stroke', 'none');
      }

      xScale.invert = (function(){
        var domain = xScale.domain();
        var range = xScale.range();
        var scale = d3.scaleQuantize().domain(range).range(domain);

        return function(x){
          return scale(x)
        }
      })()
      ;

      function drawTooltip(event, d) {
        var x0 = xScale.invert(d3.pointer(event)[0]);
        const arr2 = monthlyData.filter(d => (d.period).substring(0,3) === x0);
        console.log(arr2.length)

        tooltipLine.attr('stroke', 'black')
          .attr('x1', xScale(x0))
          .attr('x2', xScale(x0))
          .attr('y1', 0)
          .attr('y2', height-40);

        if (arr2.length > 0 ) {
          tooltip.html(arr2[0].tooltip)
            .style('display', 'block')
            .style('left', event.pageX + 20 + "px")
            .style('top', event.pageY -20 + "px")
            .selectAll()
            .data(monthlyData).enter()
            .append('div')
          .style('color', d => d.color)
        }
        //.html(d => d.name + ': ' + d.value);
      }
    })
  }
  ;
  return chart;
};

export default lineChart;

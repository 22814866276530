import  Welcome  from "./Welcome"
import React from 'react';

// import { shallow, configure, mount } from 'enzyme';


const wrapper = shallow(<Welcome  />);
  
it('renders proper page', () => {
    
  

    // Let's check what wrong in our instance
   
    // Expect the wrapper object to be defined
    expect(wrapper.find('.extended-text-container')).toBeDefined();
    // expect(wrapper.find('.item')).toHaveLength(items.length);
  });
  it('renders dashboard text properly', () => {
    
   

    // Let's check what wrong in our instance

    // Expect the wrapper object to be defined
    expect(wrapper.find('#dashboard').exists()).toEqual(true);
});
it('renders timeline text properly', () => {
    
   

    // Let's check what wrong in our instance

    // Expect the wrapper object to be defined
    expect(wrapper.find('#timeline').exists()).toEqual(true);
});
import * as d3 from 'd3'
import React, { useEffect, useRef } from 'react';
import './rowChart.scss'
// {w,linedata ,bardata,w1,keyT,BSB, id}
const RowChart = ({id}) => { 
    const d3Container = useRef(null);
    useEffect(() => { 
        var data = {
            labels: [
              'Mon', 'Tue', 'Wed' , 'Thu' , 'Fri' , 'Sat' , 'Sun'
            ],
            rightLabels: [
                240, 280, 200 , 180 , 120 , 200 , 220
              ],
            series: [
              {
                label: 'HTC',
                values: [14, 8, 100,35,40,20,30]
              },
              {
                label: 'Registry',
                values: [12, 43, 22 , 30 ,35 , 15 ,25]
              }]
          };
         
          var chartWidth   = 300,
          barHeight        = 30,
          groupHeight      = barHeight * data.series.length,
          gapBetweenGroups = 10,
          spaceForLabels   = 150,
          spaceForLegend   = 150;
      
      // Zip the series data together (first values, second values, etc.)
      var zippedData = [];
      for (var i=0; i<data.labels.length; i++) {
        for (var j=0; j<data.series.length; j++) {
          zippedData.push(data.series[j].values[i]);
        }
      }
      
      // Color scale
      var color = d3.scaleOrdinal()
      .range(["#408441", "#444444", "#E67B66"]);
      var chartHeight = barHeight * zippedData.length + gapBetweenGroups * data.labels.length;
      
      var x = d3.scaleLinear()
          .domain([0, d3.max(zippedData)])
          .range([0, chartWidth]);
        //   .padding(0.1);
      
      var y = d3.scaleBand()
          .range([chartHeight + gapBetweenGroups, 0])
        ;
      

          var yAxis = d3.axisLeft(y).ticks(5).tickSize(0, 0 ,0).tickFormat('');


    //   var yAxis = d3.axis()
    //       .scale(y)
    //       .tickFormat('')
    //       .tickSize(0)
    //       .orient("left");
      
      // Specify the chart area and dimensions
      var chart = d3.select("svg#" + id)
          .attr("width", spaceForLabels + chartWidth + spaceForLegend)
          .attr("height", chartHeight);
      
      // Create bars
      var bar = chart.selectAll("g")
          .data(zippedData)
          .enter().append("g")
          .attr("transform", function(d, i) {
            if (i % 2 === 0) {
            return "translate(" + spaceForLabels + "," + (i * barHeight + gapBetweenGroups * (0.5 + Math.floor(i/data.series.length))) + ")";
            } else {
              return "translate(" + spaceForLabels + "," + (i * barHeight + gapBetweenGroups * (1 + Math.floor(i/data.series.length))) + ")";
            }

          });
      
          bar.append("rect")
          .attr("fill", "#F2F2F2")
          .attr("class", "bar")
          .attr("width", 380)
        .attr("height", function(d, i) {
            var K = [32,0,32,0,32,0,32,0,32,0,32,0,32,0]
                return ( K[i]);
            })
      // Create rectangles of the correct width
      bar.append("rect")
          .attr("fill", function(d,i) { return color(i % data.series.length); })
          .attr("class", "bar")
          .attr("width", x)
        .attr("height", function(d, i) {
            var K = [32,8,32,8,32,8,32,8,32,8,32,8,32,8]
                return ( K[i]);
            })


      
      // Add text label in bar
      bar.append("text")
          .attr("x", function(d) { return x(d) - 25; })
          .attr("y", barHeight / 2)
          .attr("fill", "white")
          .attr("dy", ".30em")
          .text(function(d , i) {    
            var k;
            if (i % 2 === 0) {
                 k = zippedData[i];
                // console.log(zippedData[i] + '-' + i)
              } 
            return k;
           });

           bar.append("text")
           .attr("class", "label")
           .attr("x", function(d) { 
             console.log(x(d))  
             return -20; })
           .attr("y", groupHeight / 6)
           .attr("dy", ".35em")
           .text(function(d,i) {
             if (i % data.series.length === 0)
               return data.labels[Math.floor(i/data.series.length)];
             else
               return ""});

            
            bar.append("text")
            .attr("class", "rightLabel")
            .attr("x", function(d) { 
                // console.log(x(d))  
                return 405; })
            .attr("y", groupHeight / 6)
            .attr("dy", ".35em")
            .text(function(d,i) {
                if (i % data.series.length === 0)
                return data.rightLabels[Math.floor(i/data.series.length)];
                else
                return ""});   

            //    bar.append("text")
            //    .attr("class", "axis-title")
            //    .attr("transform", "rotate(-90)")
            //    .attr("y", 16)
            //    .text("Height / Centimeters");
        
    }, [])         

    return (
        <svg
            className="chart"
            id = {id}
            // width={w}
            // height = {400}
           
            ref={d3Container}
        />)
};
export default RowChart;
import React, { createRef, RefObject, useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
//import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {  Button, Checkbox, FormControl, FormControlLabel, InputLabel, Select, Typography } from "@material-ui/core";
import { Stars } from '../Charts/Elements/Stars';
import Delta from '../Charts/Elements/Delta';
import AlertMessage from '../AlertMessage/AlertMessage';
// import "./index.css";
//import axios from "axios";
import { ExpandLess } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { data } from "./tableresponse.json"
//import { history } from '../../helpers/history';
import { CSVLink } from "react-csv"
import Workbook from "react-excel-workbook"
import  ModalDisplay from '../Modal/ModalDisplay';
import { connect } from "react-redux";
//import cssFile from "../../utils/conditionalcss";
//require(`${cssFile('index')}`)  ;

const useStyles = makeStyles((theme: Theme) =>({
  table: {
    width: "99%",
    height: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
export interface TableData{
  granularity: string,
  fzcode: number,
  fzname: string,
  period: string,
  countrycode: string,
  gmname: string,
  arlname: string,
  doname: string,
  restadress: string,
  direction?:string,
  acr: string,
  sos: string,
  avgtrainingrate: string,
  turnoverrate:string,
  brandstandards: string,
  overallstarrating: string,
  prevacr: string,
  prevsos: string,
  prevavgtrainingrate: string,
  prevturnoverrate: string,
  prevbrandstandards: string,
  prevoverallstarrating: string,
  restcnt: string,
  AlertData?: Array<{ alertMessage: string, alertType: string }>
}

interface TableProps{
  rows:TableData[],
  userDispatch ? : { brandCode: string, franOrgRoles: string},
  dispatch: any,
  viewRestaurants ? : boolean
}
export const row=[
  {
    granularity: "",
    fzcode: 0,
    fzname: "",
    period: "",
    countrycode: "",
    gmname: "",
    arlname: "",
    doname: "",
    restadress: "",
    direction:"",
    acr: "",
    sos: "",
    avgtrainingrate: "",
    turnoverrate:"",
    brandstandards: "",
    overallstarrating: "",
    prevacr: "",
    prevsos: "",
    prevavgtrainingrate: "",
    prevturnoverrate: "",
    prevbrandstandards: "",
    prevoverallstarrating: "",
    restcnt: "",
    AlertData: []
}];

const RestaurantTable = ( props: TableProps ) => {
  require(`${cssFile('index')}`)
  
  const
    { franOrgRoles } = props.userDispatch,
    classes = useStyles(),
    [ isVisible, setIsVisible ] = useState(""),
    [ rows, setRows ] = useState<TableData[]>(row),
    [ filteredRows, setFilteredRows ] = useState<TableData[]>(row),
    [ arlValue, setArlValue ] = useState(""),
    [ dmaValue, setDmaValue ] = useState(""),
    [ sortValue, setSortValue ] = useState(""),
    [ filterValues, setFilterValues ] = useState([""]),
    [ cancelClicked, setCancelClicked ] = useState(true),
    [ exportClicked, setExportClicked ] = useState(true),
    [ utilClicked, setUtilClicked ] = useState(""),
    [ brandName, setBrandName ] = useState("BK"),
    [ modalOpen, setmodalopen ] = useState(false),
    modalClose =() => setmodalopen(false),
    handleCheck = (event: any) => {
      event.target.checked && filterValues.push(event.target.name)
      if(!event.target.checked){
        const index=filterValues.indexOf(event.target.name)
        index>-1 && filterValues.splice(index,1)
      }
    },
    handleSort = (order: string) => {
      setSortValue(order);
      if(order==="low") setFilteredRows(filteredRows.sort((a,b)=>Number(a.overallstarrating)-Number(b.overallstarrating)))
      else if(order==="high") setFilteredRows(filteredRows.sort((a,b)=>Number(b.overallstarrating)-Number(a.overallstarrating)))
      setCancelClicked(true)
    },
    handleOpenScoreCard = row => {
    console.log(row)
      const { fzcode, rest_no } = row;
      props.dispatch.restaurant.onChange({fzCode: fzcode, restNo: rest_no })
      setmodalopen(true);
    },
    handleFilter=()=>{
      // const filterDir=rows.filter((row)=>row.direction===direction)
      // setFilteredRows(filterDir)
      // setCancelClicked(true)

      var filtered = [];

      for (var arr in rows) {
        for (var filter in filterValues) {
          if (rows[arr].fzname === filterValues[filter]) {
            filtered.push(rows[arr]);
          }
        }
      }
      
      filtered.length > 0 ? setFilteredRows(filtered) : setFilteredRows(rows);

      setCancelClicked(true);
    
    },
    handleOutsideClick=(event)=>{
      if (filterBox && !filterBox?.current?.contains(event.target)) {
        //alert('you just clicked outside of box!');
        // setCancelClicked(true)
      }
    },
    filterBox:RefObject<HTMLDivElement>=createRef()
  ;
  useEffect(()=>{
    document.addEventListener('click', handleOutsideClick);
  
    
  },[filterBox]);
  useEffect( () => {
    if(props.rows) {setRows(props?.rows as TableData[]);setFilteredRows(props?.rows as TableData[]);}
    if(localStorage.getItem('BrandCode')==="BKC") setBrandName("BK");
    else setBrandName("PLK");
  },[props.rows])
  ;
  return (
    <div>
    <ModalDisplay modalIsOpen={ modalOpen } closeModal = { modalClose }  />
    <div className="restaurantTable">
      <div className="tableUtils">
        <div className="filtered filter-by-container">
          <div className="filterHeadings" onClick={()=>{setCancelClicked(!cancelClicked);setUtilClicked("filter")}}><Typography>Filter By</Typography>
            {!cancelClicked&&utilClicked==="filter"?
            <ExpandLess onClick={()=>setCancelClicked(true)}/>:
            <ExpandMoreIcon id="filter-button" onClick={()=>{setCancelClicked(false);setUtilClicked("filter")}}/>}
          </div>
          <div id="filter-div" ref={filterBox} className={!cancelClicked&&utilClicked==="filter"?"filteredHovered":"filteredHoveredCancel"}>
              <FormControl variant="outlined" className={classes.formControl}>
                {/* <Button className="filterButtons" >DMA:(Designated Market Area)</Button>
                <Button className="filterButtons" onClick={()=>handleFilter("north")} >North</Button>
                <Button className="filterButtons" onClick={()=>handleFilter("south")}>South</Button>
                <Button className="filterButtons" onClick={()=>handleFilter("east")}>East</Button>
                <Button className="filterButtons" onClick={()=>handleFilter("west")}>West</Button> */}
                
                {
                  rows.map(filteredRow => {
                    return <FormControlLabel
                      control={
                        <Checkbox onChange={ handleCheck }
                                  className="checkBox"
                                  name={ filteredRow.fzname  }
                        ></Checkbox>}
                      label={ filteredRow.fzname  }
                    />
                  })
                }
                <div className="filterButtons">
                  <Button variant='text' onClick={() => setCancelClicked(true)}>Cancel</Button>
                  <Button variant="text" className="applyButton" onClick={handleFilter}>Apply</Button>
                </div>
          
              </FormControl>
          </div>
        </div>
        <div className="filtered sort-by-container">
            <div className='filter-box-layout'>
                <div className="filterHeadings" onClick={()=>{setCancelClicked(!cancelClicked);setUtilClicked("sort")}}><Typography>Sort By</Typography>
              {!cancelClicked&&utilClicked==="sort"?<ExpandLess onClick={()=>setCancelClicked(true)}/>:<ExpandMoreIcon id="sort-button" onClick={()=>{setCancelClicked(false);setUtilClicked("sort")}}/>}
                </div>
                <div id="sort-div" className={!cancelClicked&&utilClicked==="sort"?"filteredHoveredNested":"filteredHoveredCancel"}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Button onClick={()=>handleSort("low")} className="sort-buttons"> Star Low to High</Button>
                      <Button onClick={()=>handleSort("high")} className="sort-buttons">Star High to Low</Button>
                    </FormControl>
                </div>
              </div>
        </div>
        <div className='export-box-layout'>
          <img src="/assets/export excel.svg" alt="" className='exportIcon' onClick={()=>setExportClicked(!exportClicked)}></img>
          <div id='export-div' className={!exportClicked?"filteredHovered":"filteredHoveredCancel"}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Button><CSVLink data={rows} filename="Restaurant Data.csv" className="csv-link">Export to CSV</CSVLink></Button>
              <Workbook filename="Restaurant Data.xlsx" element={<Button className="xls-link">Export to XLS</Button>}>
                <Workbook.Sheet data={rows} name="Sheet A">
                  <Workbook.Column label="Granularity" value="granularity"/>
                  <Workbook.Column label="FZ Code" value="fzcode"/>
                  <Workbook.Column label="FZ Name" value="fzname"/>
                  <Workbook.Column label="Period" value="period"/>
                  <Workbook.Column label="GM Name" value="gmname"/>
                  <Workbook.Column label="ARL Name" value="arlname"/>
                  <Workbook.Column label="DO Name" value="doname"/>
                  <Workbook.Column label="Address" value="restadress"/>
                  <Workbook.Column label="Star Rating" value="overallstarrating"/>
                  <Workbook.Column label="ACR" value="acr"/>
                  <Workbook.Column label="SOS" value="sos"/>
                  <Workbook.Column label="Turnover" value="turnoverrate"/>
                  <Workbook.Column label="Brand Standards" value="brandstandards"/>
                  <Workbook.Column label="Average Training rate" value="avgtrainingrate"/>
                </Workbook.Sheet>
              </Workbook>
            </FormControl>
          </div>
        </div>
      </div>
      <TableContainer component={Paper} className='root-table-container'>
        <div className='tabular-div-container-root' aria-label="simple table">
          <TableHead className='table-head-container'>
            <TableRow className='table-row-header'>
              <TableCell className={brandName==="BK"?"header restaurantBK":"header restaurant"}>
              </TableCell>
              <TableCell className={brandName==="BK"?"header guestHeadBK":"header guestHead"}>
               Guest Satisfaction (ACR)
              </TableCell>
              <TableCell className={brandName==="BK"?"header guestHeadBK":"header guestHead"}>
                Window Time (SOS)
              </TableCell>
              <TableCell className={brandName==="BK"?"header teamHeadBK":"header teamHead"}>
                Avg. Training Rate
              </TableCell>
              <TableCell className={brandName==="BK"?"header teamHeadBK":"header teamHead"}>
                 Turnover Rate
              </TableCell>
              <TableCell className={brandName==="BK"?"header standardHeadBK":"header standardHead"}>
                 Brand Standards
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='table-body-container'>
            {filteredRows?.map((row,i) => {
              const
                array = row.AlertData ? [...row.AlertData, 0] : [],
                gridTemplateRows = array.length >= 1 ? array.map(o => (100 / array.length) + '%')
                  .join(' ') : '100%';
              return <div
                  key={ `row.fzname-${ i }`}
                  className="tablerow"
                  style={{ gridTemplateRows: gridTemplateRows }}
                  onClick={()=>handleOpenScoreCard(row)}
              >
                <TableCell component="th" scope="row" className='first-column-restaurant'>
                {props.viewRestaurants&&<p className="serial-no">{i+1}.</p>}<div>
                  <Typography className="restaurantName">{row.fzname}</Typography>
                  <Typography className="restaurantPlace">
                    {row.restadress}
                  </Typography>
                  <div className="starsDiv">
                    <div>
                      <Stars stars={ row.overallstarrating } width={ 200 } height={ 100 } element='table'/>
                    </div>
                    <Typography className="starsRating">
                      {row.overallstarrating}/5 stars
                    </Typography>
                  </div>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <Typography className="currentValue">
                    {row.acr+" "}
                    <Delta fill='#c23934'/>
                  </Typography>
                  <Typography className="previousValue">
                    Previous: {row.prevacr}%
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className="currentValue">
                    {row.sos+"s "} 
                    <Delta fill='#198737'/>
                  </Typography>
                  <Typography className="previousValue">
                    Previous: {row.prevsos}s
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className="currentValue">
                    {row.avgtrainingrate+"% "}
                    <Delta fill='#198737'/>
                  </Typography>
                  <Typography className="previousValue">
                    Previous: {row.prevavgtrainingrate}%
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className="currentValue">
                    {row.turnoverrate+"% "}
                    <Delta fill='#c23934'/>
                  </Typography>
                  <Typography className="previousValue">
                    Previous: {row.prevturnoverrate}%
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className="currentValue">
                    {row.brandstandards+"% "}
                    <Delta fill='#198737'/>
                  </Typography>
                  <Typography className="previousValue">
                    Previous: {row.prevbrandstandards}%
                  </Typography>
                </TableCell>
                {
                  franOrgRoles === 'Multi-Unit Manager' ?
                    row.AlertData?.map(o => (
                      <AlertMessage
                        message = { o.alertMessage }
                        type = { o.alertType }
                        style={{ gridColumn: '1/8' }}
                        className='arl-dashboard'
                      />
                    )): null
                }
              </div>
            })}
          </TableBody>
        </div>
      </TableContainer>
    </div>
    </div>
  );
};
const mapStateToProps = (
  dispatch => ({
    userDispatch: dispatch.user,
    restaurantDispatch: dispatch.restaurant
  })
);

const cssFile=(name)=>{
  const brand = localStorage.getItem('BrandCode')
  console.log(brand)
  return `./${name}BK.scss`
  //return brand==="PLK" ? `./${name}PLK.scss` : `./${name}BK.scss`
}

export default connect(mapStateToProps)(RestaurantTable);
